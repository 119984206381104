<template>
  <div class="grid">
    <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <ExchangeFilter :open="sidebarOpen" />
    </div>
    <div class="col col-remaining">
      <PositionsTable class="mb-4" :title="'Positions (Derivatives)'" :entity-type="ENTITY_TYPE.DERIVATIVES" />
      <PositionsTable class="mb-4" :title="'Recent Fills (Spot - Past Week)'" :entity-type="ENTITY_TYPE.SPOT" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSidebarStore } from '@/stores/sidebar';
import PositionsTable from '@/components/exchanges/positions/PositionsTable.vue';
import ExchangeFilter from '@/components/exchanges/sidebar/ExchangeFilter.vue';
import { ENTITY_TYPE } from '@/types/exchange';

// Stores
const sidebarStore = useSidebarStore();

// Computed
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
</script>

<style lang="scss">

</style>
