<template>
  <router-view />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useArbitrageStore } from '@/stores/exchanges/arbitrage';
import { useFundingAnalysisStore } from '@/stores/exchanges/fundingAnalysis';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useLiquidationsStore } from '@/stores/exchanges/liquidations';
import { useMarketTradesStore } from '@/stores/exchanges/marketTrades';
import { useMonitoringStore, MonitoringServerResp } from '@/stores/exchanges/monitoring';
import { useOrderbooksStore } from '@/stores/exchanges/orderbooks';
import { usePricesStore } from '@/stores/exchanges/prices';
import { useOrdersStore } from '@/stores/exchanges/orders';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { usePositionsStore } from '@/stores/exchanges/positions';
import { usePricedataStore } from '@/stores/exchanges/pricedata';
import { useTradingviewStore } from '@/stores/exchanges/tradingview';
import { useWalletsStore } from '@/stores/exchanges/wallets';
import { useServerLogsStore } from '@/stores/user/serverLogs';
import { useNotificationsStore } from '@/stores/user/notifications';
import { useUserStore } from '@/stores/user/user';
import { useWebSocketStore } from '@/stores/user/ws';
import { useRequestStore } from '@/stores/request';

import {
  EntityServerResp,
  SubEntityServerResp,
  AccountServerResp,
  ValidEntityNamesServerResp,
} from '@/types/account';
import { SequencesServerResponse } from '@/types/arbitrage';
import { FundingCyclesServerResp } from '@/types/fundingAnalysis';
import { InstrumentServerResp, InstrumentsServerResp } from '@/types/instruments';
import { OrderbookLiquidityServerResp, OrderbookServerResp, OrderbookChangesServerResp } from '@/types/orderbooks';
import { OrderServerResp, OrdersServerResp } from '@/types/orders';
import {
  OrderCreationStrategiesServerResp,
  OrderCreationStrategyServerResp,
  OrderManagementStrategiesServerResp,
  OrderManagementStrategyServerResp,
} from '@/types/order_strategies';
import {
  PriceDataTestResultsServerResp, PriceDataTestResultsDebuggerServerResp, PriceDataServerResp, PriceDataMetaServerResp,
} from '@/types/pricedata';
import { PricesServerResp } from '@/types/prices';
import { PositionServerResp, PositionsServerResp } from '@/types/positions';
import {
  TradingviewSymbolsServerResp, TradingviewSymbolDataServerResp, TradingviewSymbolDataFatFingerServerResp,
} from '@/types/tradingview';
import { RequestStatusServerResp } from '@/types/general';
import { MarketTradesServerResp } from '@/types/marketTrades';
import { LiquidationsServerResp } from '@/types/liquidations';
import {
  PasswordsTsServerResp, NOTIFICATION_TYPE, NotificationType, WS_STATUS, WS_AUTH, LogFileNamesServerResp,
  LogFileServerResp, AccountTypeServerResp,
} from '@/types/user';
import { WalletServerResp, WalletsServerResp } from '@/types/wallets';
import Config from '@/config';

// Stores
const accountManagementStore = useAccountManagementStore();
const arbitrageStore = useArbitrageStore();
const fundingAnalysisStore = useFundingAnalysisStore();
const instrumentsStore = useInstrumentsStore();
const liquidationsStore = useLiquidationsStore();
const marketTradesStore = useMarketTradesStore();
const monitoringStore = useMonitoringStore();
const orderbooksStore = useOrderbooksStore();
const tradingviewStore = useTradingviewStore();
const ordersStore = useOrdersStore();
const orderStrategiesStore = useOrderStrategiesStore();
const positionsStore = usePositionsStore();
const pricesStore = usePricesStore();
const pricedataStore = usePricedataStore();
const walletsStore = useWalletsStore();
const serverLogsStore = useServerLogsStore();
const notificationsStore = useNotificationsStore();
const wsStore = useWebSocketStore();
const requestStore = useRequestStore();
const userStore = useUserStore();

// Computed properties
const token = computed(() => userStore.token);
const ws = computed(() => wsStore.ws);
const loggedIn = computed(() => userStore.isLoggedIn);

// Watchers
watch(loggedIn, (newLoggedIn) => {
  if (newLoggedIn) {
    try {
      if (import.meta.env.VITE_APP_OFFLINE) {
        // The app needs to wait for initialisation first before attempting WS connection
        setTimeout(() => connectOverWs(), 500);
      } else {
        connectOverWs();
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    // Close websocket??
  }
}, { immediate: true });

// Methods
const connectOverWs = () => {
  if (ws.value.status === WS_STATUS.CONNECTING || ws.value.status === WS_STATUS.CONNECTED) {
    return;
  }

  wsStore.setStatus(WS_STATUS.CONNECTING);
  const conn = new WebSocket(`${Config.serverAppWsPrefix()}://${Config.serverAppAddress()}/api/ws`);

  conn.onmessage = (event: MessageEvent) => {
    try {
      const resp = JSON.parse(event.data as string) as unknown;
      if (Array.isArray(resp)) {
        for (const r of resp) {
          handleResponse(r);
        }
      } else {
        handleResponse(resp);
      }
    } catch (error) {
      console.error('Unable to parse JSON string for event.data', error, event.data);
    }
  };

  conn.onerror = (event) => {
    wsStore.setStatus(WS_STATUS.CONNECTION_ERROR);
    wsStore.setConn(null);
    console.error('Websocket connection error', event);
  };

  conn.onopen = () => {
    wsStore.setStatus(WS_STATUS.CONNECTED);
    wsStore.setConn(conn);
    try {
      authenticateWebsocket();
    } catch (error) {
      console.error(error);
    }
  };

  conn.onclose = () => {
    wsStore.setStatus(WS_STATUS.DISCONNECTED);
    wsStore.setAuthorised(WS_AUTH.NO);
    wsStore.setConn(null);
  };
};

const authenticateWebsocket = () => {
  if (
    !loggedIn.value || ws.value.authorised === WS_AUTH.AUTHORSING ||
    ws.value.authorised === WS_AUTH.YES || ws.value.status !== WS_STATUS.CONNECTED
  ) {
    return;
  }

  wsStore.setAuthorised(WS_AUTH.AUTHORSING);
  ws.value.conn.send(JSON.stringify({
    category: 'auth',
    body: token.value,
  }));
};

const handleResponse = (resp: unknown) => {
  const respCategory = resp as { category: string };
  switch (respCategory.category) {
  case 'update_request_status':
    requestStore.updateRequestStatus(resp as RequestStatusServerResp);
    break;
  case 'status': {
    const typedResp = resp as { category: string, message: string, success: boolean, type: string };
    const notification = new NotificationType(
      typedResp.success ? NOTIFICATION_TYPE.SUCCESS : NOTIFICATION_TYPE.ERROR,
      typedResp.message,
    );
    notification.category = typedResp.type || '';
    notificationsStore.addNotification(notification);
    break;
  }
  case 'alerts': {
    const typedResp = resp as { alerts: {
      entityName: string, accountId: string, success: boolean, timestamp: number, message: string,
    }[] };

    typedResp.alerts.forEach(alert => {
      const notification = new NotificationType(
        alert.success ? NOTIFICATION_TYPE.SUCCESS : NOTIFICATION_TYPE.ERROR,
        alert.message,
      );
      notification.entityName = alert.entityName;
      notification.accountId = alert.accountId;
      notification.timestamp = alert.timestamp;
      notificationsStore.addNotification(notification);
    });
    break;
  }
  case 'auth': {
    const typedResp = resp as {
      serverEncrypted: boolean, statusCode: number, body: string, offlineMode: boolean, tradingSessionExpiresAt: number,
    };
    const offlineModeMismatchError = 'Mismatch between server offline mode and client offline mode';

    wsStore.setServerEncrypted(typedResp.serverEncrypted);

    if (typedResp.statusCode === 200) {
      wsStore.setAuthorised(WS_AUTH.YES);
      wsStore.setOfflineMode(typedResp.offlineMode);

      if (typedResp.offlineMode !== !!import.meta.env.VITE_APP_OFFLINE) {
        useNotificationsStore().addNotification(
          new NotificationType(NOTIFICATION_TYPE.ERROR, offlineModeMismatchError),
        );
      } else {
        useNotificationsStore().addNotification(
          new NotificationType(
            NOTIFICATION_TYPE.SUCCESS,
            `Successfully authenticated user (server still encrypted = ${String(typedResp.serverEncrypted)})`,
          ),
        );
      }

      if (typedResp.tradingSessionExpiresAt !== 0) {
        userStore.tradingSessionExpiry = typedResp.tradingSessionExpiresAt;
      }
    } else {
      if (import.meta.env.VITE_APP_OFFLINE) {
        useNotificationsStore().addNotification(
          new NotificationType(NOTIFICATION_TYPE.ERROR, offlineModeMismatchError),
        );
      } else {
        useNotificationsStore().addNotification(
          new NotificationType(NOTIFICATION_TYPE.ERROR, `Websocket authorisation failed. Reason: ${typedResp.body}`),
        );
      }

      wsStore.setAuthorised(WS_AUTH.FAILED);
    }
    break;
  }
  case 'decrypt': {
    const typedResp = resp as { statusCode: number, body: string };

    if (typedResp.statusCode === 200) {
      wsStore.setServerEncrypted(false);
    } else {
      console.error(`Server decryption failed: ${typedResp.body}`);
    }
    break;
  }
  case 'valid_entities':
    accountManagementStore.setValidEntityNames(resp as ValidEntityNamesServerResp);
    break;
  case 'user_entity':
    accountManagementStore.setEntity(resp as EntityServerResp);
    break;
  case 'user_sub_entity':
    accountManagementStore.addSubEntity(resp as SubEntityServerResp);
    break;
  case 'user_account':
    accountManagementStore.setAccount(resp as AccountServerResp);
    break;
  case 'account_type':
    userStore.setAccountType(resp as AccountTypeServerResp);
    break;
  case 'passwords_ts':
    userStore.setPasswordsTs(resp as PasswordsTsServerResp);
    break;
  case 'instruments':
    instrumentsStore.addInstruments(resp as InstrumentsServerResp);
    break;
  case 'new_instrument':
    instrumentsStore.addInstrument(resp as InstrumentServerResp);
    break;
  case 'update_instrument':
    instrumentsStore.updateInstrument(resp as InstrumentServerResp);
    break;
  case 'prices':
    pricesStore.setAllPrices(resp as PricesServerResp);
    break;
  case 'price_changes':
    pricesStore.setPrices(resp as PricesServerResp);
    break;
  case 'snapshot_order_management_strategies':
    orderStrategiesStore.addOrderManagementStrategies(resp as OrderManagementStrategiesServerResp);
    break;
  case 'new_order_management_strategy':
  case 'update_order_management_strategy':
    orderStrategiesStore.addOrderManagementStrategy(resp as OrderManagementStrategyServerResp);
    break;
  case 'delete_order_management_strategy':
    orderStrategiesStore.deleteOrderManagementStrategy(resp as OrderManagementStrategyServerResp);
    break;
  case 'snapshot_order_creation_strategies':
    orderStrategiesStore.addOrderCreationStrategies(resp as OrderCreationStrategiesServerResp);
    break;
  case 'new_order_creation_strategy':
  case 'update_order_creation_strategy':
    orderStrategiesStore.addOrderCreationStrategy(resp as OrderCreationStrategyServerResp);
    break;
  case 'delete_order_creation_strategy':
    orderStrategiesStore.deleteOrderCreationStrategy(resp as OrderCreationStrategyServerResp);
    break;
  case 'positions':
    positionsStore.addPositions(resp as PositionsServerResp);
    break;
  case 'new_position':
    positionsStore.setPosition(resp as PositionServerResp);
    break;
  case 'update_position':
    positionsStore.setPosition(resp as PositionServerResp);
    break;
  case 'delete_position':
    positionsStore.closePosition(resp as PositionServerResp);
    break;
  case 'wallets':
    walletsStore.addWallets(resp as WalletsServerResp);
    break;
  case 'new_wallet':
    walletsStore.addWallet(resp as WalletServerResp);
    break;
  case 'update_wallet':
    walletsStore.updateWallet(resp as WalletServerResp);
    break;
  case 'delete_wallet':
    walletsStore.deleteWallet(resp as WalletServerResp);
    break;
  case 'arbitrage_sequences':
    arbitrageStore.setSequences(resp as SequencesServerResponse);
    break;
  case 'orderbook_snapshot':
    orderbooksStore.setOrderbook(resp as OrderbookServerResp);
    break;
  case 'realtime_orderbook_snapshot':
    orderbooksStore.setOrderbook(resp as OrderbookServerResp);
    break;
  case 'realtime_orderbook_changes':
    orderbooksStore.changes(resp as OrderbookChangesServerResp);
    break;
  case 'realtime_orderbook_clear':
    orderbooksStore.deleteOrderbook(resp as OrderbookServerResp);
    break;
  case 'price_data':
    pricedataStore.setPriceData(resp as PriceDataServerResp);
    break;
  case 'price_data_addition':
    pricedataStore.amendPriceData(resp as PriceDataServerResp);
    break;
  case 'price_data_remove_last':
    pricedataStore.removeLastCandle(resp as { body: string });
    break;
  case 'price_data_meta':
    pricedataStore.setPriceDataMeta(resp as PriceDataMetaServerResp);
    break;
  case 'price_data_error': {
    const typedResp = resp as { body: string };
    notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.ERROR, typedResp.body));
    break;
  }
  case 'price_data_test_results':
    pricedataStore.setPriceDataTestResults(resp as PriceDataTestResultsServerResp);
    break;
  case 'price_data_test_results_debugger':
    pricedataStore.setPriceDataTestResultsDebugger(resp as PriceDataTestResultsDebuggerServerResp);
    break;
  case 'orderbook_liquidity':
    orderbooksStore.setOrderbookLiquidity(resp as OrderbookLiquidityServerResp);
    break;
  case 'monitoring':
    monitoringStore.setMonitoring(resp as MonitoringServerResp);
    break;
  case 'tradingview_symbols':
    tradingviewStore.setSymbols(resp as TradingviewSymbolsServerResp);
    break;
  case 'tradingview_symbol_data':
    tradingviewStore.setSymbolData(resp as TradingviewSymbolDataServerResp);
    break;
  case 'tradingview_symbol_data_fat_finger':
    tradingviewStore.setSymbolDataFatFinger(resp as TradingviewSymbolDataFatFingerServerResp);
    break;
  case 'heartbeat':
    // A heartbeat is sent from the server app if nothing has been sent for a brief
    // while (e.g. 5 seconds). This shows the connection is still alive.
    break;
  case 'realtime_market_trades':
    marketTradesStore.setMarketTrades(resp as MarketTradesServerResp);
    break;
  case 'realtime_liquidations':
    liquidationsStore.setLiquidations(resp as LiquidationsServerResp);
    break;
  case 'logs_file_names':
    serverLogsStore.setLogsFileNames(resp as LogFileNamesServerResp);
    break;
  case 'log_file':
    serverLogsStore.setLogFile(resp as LogFileServerResp);
    break;
  case 'symbol_funding':
    fundingAnalysisStore.setFundingCycles(resp as FundingCyclesServerResp);
    break;
  case 'orders_snapshot':
    ordersStore.addOrders(resp as OrdersServerResp);
    break;
  case 'new_order':
    ordersStore.setOrder(resp as OrderServerResp);
    break;
  case 'update_order':
    // The API uses intent-based programming, and so any updates to an order should send back through the whole order,
    // where setOrder() should be used. This is a much better way to do things, since it means deep watchers are no
    // longer needed.
    ordersStore.setOrder(resp as OrderServerResp);
    break;
  case 'delete_order':
    ordersStore.deleteOrder(resp as OrderServerResp);
    break;
  default:
    console.warn(`Unknown response category '${respCategory.category}'. Message: ${JSON.stringify(resp)}`);
  }
};
</script>

<style>
</style>
