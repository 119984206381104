<template>
  <div v-if="props.open" class="panel panel--match-height panel--margin-right">
    <div class="panel__header">
      Filter
      <button class="panel__header__close-btn" title="Close Filter" @click="toggleSidebarOpen()">
        X
      </button>
    </div>

    <section class="sidebar__setting">
      <label for="entityNameFilter" class="sidebar__setting__label">Entity</label>
      <select
        id="entityNameFilter" v-model="entityNameFilter"
        class="sidebar__setting__input" :disabled="props.requestProcessing"
      >
        <option value="">ALL</option>
        <option :value="ENTITY_NAME.BITMEX">{{ ENTITY_NAME.BITMEX }}</option>
      </select>
    </section>

    <section class="sidebar__setting">
      <div class="flex flex-row">
        <label class="sidebar__setting__label">Start date:</label>
        <input
          v-model="startDateFilter" class="sidebar__setting__input" type="date" :disabled="props.requestProcessing"
        />
      </div>
    </section>

    <section class="sidebar__setting">
      <div class="flex flex-row">
        <label class="sidebar__setting__label">End date:</label>
        <input
          v-model="endDateFilter" class="sidebar__setting__input" type="date" :disabled="props.requestProcessing"
        />
      </div>
    </section>

    <section class="sidebar__setting">
      <label class="sidebar__setting__label">Symbol:</label>
      <select v-model="symbolFilter" class="sidebar__setting__input" :disabled="props.requestProcessing">
        <option value="" disabled>
          Select Symbol
        </option>
        <option v-for="instrument in filteredInstruments" :key="instrument.symbol" :value="instrument.symbol">
          {{ instrument.consistentSymbol }}
        </option>
      </select>
    </section>

    <div v-if="props.requestProcessing" class="text-center">
      Fetching Results ...
      <img class="spin-animation" :src="spinningCircle" />
    </div>
  </div>
  <button v-else class="panel open-button" @click="toggleSidebarOpen()">
    <i class="ti-filter icon" /><p>Filter</p>
  </button>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { toggleSidebarOpen } from '@/stores/shared';
import { ENTITY_NAME, ENTITY_TYPE } from '@/types/exchange';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { InstrumentType } from '@/types/instruments';
import spinningCircle from '@/assets/spinning-circle.svg';

// Stores
const instrumentsStore = useInstrumentsStore();
const emit = defineEmits(['setFilter']);

const props = defineProps({
  open: Boolean,
  requestProcessing: Boolean,
});

const instruments = computed(() => instrumentsStore.instruments);
const filteredInstruments = computed<InstrumentType[]>(() => {
  if (String(entityNameFilter.value) === '') return [];
  const entityInstruments = instruments.value?.[entityNameFilter.value]?.[ENTITY_TYPE.DERIVATIVES] || {};
  return Object.values(entityInstruments);
});

const entityNameFilter = ref(ENTITY_NAME.BITMEX);
const symbolFilter = ref('');
const startDateFilter = ref('');
const endDateFilter = ref('');

watch(entityNameFilter, (val) => {
  emit('setFilter', 'entityName', val);
}, { immediate: true });

watch(symbolFilter, (val) => {
  emit('setFilter', 'symbol', val);
});

watch(startDateFilter, (val) => {
  emit('setFilter', 'startDate', val);
}, { immediate: true });

watch(endDateFilter, (val) => {
  emit('setFilter', 'endDate', val);
}, { immediate: true });

onMounted(() => {
  const startDate = new Date(), endDate = new Date();
  startDate.setFullYear(endDate.getFullYear() - 1);
  startDateFilter.value = startDate.toISOString().split('T')[0];
  endDateFilter.value = endDate.toISOString().split('T')[0];
});
</script>

<style lang="scss">

</style>

