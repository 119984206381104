<template>
  <div class="panel">
    <div class="panel__header panel__header--active">
      <h3 class="panel__header__title">Order Creation Strategies</h3>
    </div>
    <div class="panel__body">
      <div class="vtable-header" style="padding-left: 4px;">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--20" name="name">
          Name
        </div>
        <div class="vtable-header__item vtable__size--m-25 vtable__size--50" name="actions" />
      </div>
      <DynamicScroller
        :items="Object.values(strategies)" :min-item-size="36"
        class="panel__body__table vtable  panel__body__table--large"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.id]" :data-index="index">
            <div class="vtable__row">
              <div class="vtable__row__item vtable__size--m-25 vtable__size--20">
                {{ item.name }}
              </div>
              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--4 vtable__size--m-8">
                <button class="vtable__row__button" @click="emit('updateOrderCreationStrategy', item)">
                  <i class="ti-pencil" />
                </button>
                <button class="vtable__row__button" @click="deleteStrategy(item)">
                  <i class="ti-trash" />
                </button>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { OrderCreationStrategyType } from '@/types/order_strategies';

const emit = defineEmits(['updateOrderCreationStrategy']);

const orderStrategiesStore = useOrderStrategiesStore();

const strategies = computed((): Record<string, OrderCreationStrategyType> => {
  return orderStrategiesStore.getCreationStrategies();
});

const deleteStrategy = (strategy: OrderCreationStrategyType) => {
  const confirmation = window.confirm('Are you sure you want to delete this strategy?');

  if (!confirmation) {
    return;
  }

  void orderStrategiesStore.deleteOrderCreationStrategyHttp(strategy);
};
</script>

<style lang="scss" scoped>
</style>
