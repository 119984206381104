import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePricesStore } from '@/stores/exchanges/prices';
import { ENTITY_NAME, ENTITY_TYPE } from '@/types/exchange';
import {
  CacheType, PriceLookupCacheType, priceLookupCacheNotFoundType, ChosenAssetsType,
  ChosenSymbol, UserSettingsStore, MinWalletDisplayAmount, AssetPriceType,
} from '@/types/settings';
import { PriceType } from '@/types/prices';

const stablecoins = [
  'USDT', 'USDC', 'BUSD', 'USDP', 'TUSD', 'DAI', 'SUSD', 'EURT', 'PAX',
  'USDD', 'GUSD', 'USDG', 'GBPT', 'CEUR', 'CUSD', 'USDJ', 'OUSD', 'EUROC',
  'MIM',
];
const fiats = ['USD', 'GBP', 'EUR', 'JPY', 'CAD', 'TRY', 'RUB', 'AUD', 'CHF', 'BRL'];
export const forexAssets = stablecoins.concat(fiats);
export const indexAssets = [ // Bitfinex derivative indices only for now
  'HK50', 'DE40', 'ASX200', 'SX5E', 'IBEX35', 'FTSE', 'NIKKEI', 'CAC40', 'UKOIL',
];
const dollarAssets = ['USD', 'USDT', 'USDC', 'BUSD', 'USDP', 'PAX', 'TUSD', 'DAI', 'GUSD'];
const gbpAssets = ['GBP'];
const euroAssets = ['EUR', 'EURT', 'EUROC'];

export const chosenAssets: ChosenAssetsType = {
  'USD': {
    'symbol': '$', // symbol to show for this asset
    'assetList': dollarAssets, // equivalent assets list (to skip conversions for)
    'assetValue': null,
    'symbolPrefixed': true, // whether to place the symbol before or after the amount
    'symbolSpace': false, // whether to add a space between the symbol and amount
    'dp': 2, // rounding DP for displaying values
    'minWalletDisplayAmounts': {
      'minBalance': 10,
      'minAvailableBalance': 10,
    },
  },
  'GBP': {
    'symbol': '£',
    'assetList': gbpAssets,
    'assetValue': null,
    'symbolPrefixed': true,
    'symbolSpace': false,
    'dp': 2,
    'minWalletDisplayAmounts': {
      'minBalance': 10,
      'minAvailableBalance': 10,
    },
  },
  'EUR': {
    'symbol': '€',
    'assetList': euroAssets,
    'assetValue': null,
    'symbolPrefixed': true,
    'symbolSpace': false,
    'dp': 2,
    'minWalletDisplayAmounts': {
      'minBalance': 10,
      'minAvailableBalance': 10,
    },
  },
  'BTC': {
    'symbol': '₿',
    'assetList': ['BTC'],
    'assetValue': null,
    'symbolPrefixed': false,
    'symbolSpace': true,
    'dp': 3,
    'minWalletDisplayAmounts': {
      'minBalance': 0.001,
      'minAvailableBalance': 0.001,
    },
  },
  'ETH': {
    'symbol': 'ETH',
    'assetList': ['ETH'],
    'assetValue': null,
    'symbolPrefixed': false,
    'symbolSpace': true,
    'dp': 2,
    'minWalletDisplayAmounts': {
      'minBalance': 0.01,
      'minAvailableBalance': 0.01,
    },
  },
  'Beer': {
    'symbol': '🍺',
    'assetList': [],
    'assetValue': ['GBP', 5],
    'symbolPrefixed': false,
    'symbolSpace': true,
    'dp': 0,
    'minWalletDisplayAmounts': {
      'minBalance': 1,
      'minAvailableBalance': 1,
    },
  },
  'House': {
    'symbol': '🏠',
    'assetList': [],
    'assetValue': ['GBP', 286000], // Average UK house price, as of June 2022
    'symbolPrefixed': false,
    'symbolSpace': true,
    'dp': 3,
    'minWalletDisplayAmounts': {
      'minBalance': 0.01,
      'minAvailableBalance': 0.01,
    },
  },
  'Percentage': {
    'symbol': '%',
    'assetList': [],
    'assetValue': ['USD', 1],
    'symbolPrefixed': false,
    'symbolSpace': false,
    'dp': 2,
    'minWalletDisplayAmounts': {
      'minBalance': 0.01,
      'minAvailableBalance': 0.01,
    },
  },
};
// { fromAsset => { toAsset => { [entityName, entityType, toAssetPair, inverted] } }
export const priceLookupCache: PriceLookupCacheType = {};
// { asset => { chosenAsset => assetName } }
export const priceLookupCacheNotFound = ref<priceLookupCacheNotFoundType>({});
let entitiesLoadedCount = 0; // To reset priceLookupCacheNotFound when a new entity is added (price data may now exist)

export const useUserSettingsStore = defineStore('userSettings', {
  state: (): UserSettingsStore => ({
    theme: 'theme-dark',
    chosenAsset: 'USD',
    chosenAssetTotal: 1,
    minWalletDisplayAmounts: {}, // Initialize from server ideally
    maxMarketTradesToDisplay: 30,
    hardcodedSymbolPrices: {
      // TEST entity settings (hard-coded for now) to convert { XRP, SOL, DOGE } to $ (leaving PEPE not found)
      // 'GBP-USD': new PriceType('GBP-USD', '1.3', '1.3', '1.3', '0', '', '', ENTITY_TYPE.SPOT),
      // 'XRP-GBP': new PriceType('XRP-GBP', '1.1', '1.1', '1.1', '0', '', '', ENTITY_TYPE.SPOT),
      // 'DOGE-BTC': new PriceType('DOGE-BTC', '0.0000042', '0.0000042', '0.0000042', '0', '', '', ENTITY_TYPE.SPOT),
      // 'SOL-USD': new PriceType('SOL-USD', '150', '150', '150', '0', '', '', ENTITY_TYPE.SPOT),
    },
    chart: {
      timeframe: 1 * 60 * 15, // 15 minute timeframe
      inverted: false,
      showOrders: true,
      showPositions: true,
      showVolume: true,
      showLiquidations: false,
      showOrderflow: false,
      showOrderbook: false,
    },
    tradingSession: {
      defaultSessionLength: 15, // in minutes
    },
    portfolio: {
      defaultSelectedCategory: 'Crypto',
    },
  }),
  actions: {
    setTheme(theme: string) {
      this.theme = theme;
    },
    setMinBalance(minBalance: number) {
      const assetKey = this.chosenAsset;

      if (!(assetKey in this.minWalletDisplayAmounts)) {
        this.minWalletDisplayAmounts[assetKey] = new MinWalletDisplayAmount(
          0,
          chosenAssets[assetKey].minWalletDisplayAmounts.minAvailableBalance,
        );
      }

      this.minWalletDisplayAmounts[assetKey].minBalance = minBalance;
    },
    setMinAvailableBalance(minAvailableBalance: number) {
      const assetKey = this.chosenAsset;

      if (!(assetKey in this.minWalletDisplayAmounts)) {
        this.minWalletDisplayAmounts[assetKey] = new MinWalletDisplayAmount(
          chosenAssets[assetKey].minWalletDisplayAmounts.minBalance,
          0,
        );
      }

      this.minWalletDisplayAmounts[assetKey].minAvailableBalance = minAvailableBalance;
    },
    updateChosenAsset(chosenAsset: string) {
      this.chosenAsset = chosenAsset;
    },
    setChosenAssetTotal(chosenAssetTotal: number) {
      this.chosenAssetTotal = chosenAssetTotal;
    },
    setChartTimeframe(timeframe: number) {
      this.chart.timeframe = timeframe;
    },
    setChartInverted(inverted: boolean) {
      this.chart.inverted = inverted;
    },
    setChartShowOrders(showOrders: boolean) {
      this.chart.showOrders = showOrders;
    },
    setChartShowPositions(showPositions: boolean) {
      this.chart.showPositions = showPositions;
    },
    setChartShowVolume(showVolume: boolean) {
      this.chart.showVolume = showVolume;
    },
    setChartShowLiquidations(showLiquidations: boolean) {
      this.chart.showLiquidations = showLiquidations;
    },
    setChartShowOrderflow(showOrderflow: boolean) {
      this.chart.showOrderflow = showOrderflow;
    },
    setChartShowOrderbook(showOrderbook: boolean) {
      this.chart.showOrderbook = showOrderbook;
    },
  },
  getters: {
    getTheme: (state) => state.theme,
    getMaxMarketTradesToDisplay: (state): number => state.maxMarketTradesToDisplay,
    getChosenAssetList: () => Object.keys(chosenAssets),
    getCurrentChosenAsset: (state) => state.chosenAsset,
    getMinWalletDisplayAmounts: (state) => {
      if (state.minWalletDisplayAmounts[state.chosenAsset] !== undefined) {
        return state.minWalletDisplayAmounts[state.chosenAsset];
      }

      return chosenAssets[state.chosenAsset]['minWalletDisplayAmounts'];
    },
    getChosenAssetSymbol: (state) => chosenAssets[state.chosenAsset].symbol,
    getChosenAssetSymbolWithAmount: (state) => (amount: string) => {
      if (chosenAssets[state.chosenAsset]['symbolPrefixed']) {
        let negativeSign = '';

        if (amount[0] === '-') {
          negativeSign = '-';
          amount = amount.slice(1);
        }

        return `${negativeSign}${chosenAssets[state.chosenAsset]['symbol']}${amount}`;
      }

      let value = amount;

      if (chosenAssets[state.chosenAsset]['symbolSpace']) {
        value = value + ' ';
      }

      return value + chosenAssets[state.chosenAsset]['symbol'];
    },
    getChosenAssetValue(state) {
      return (entityName: string, asset: string, quantity: number, overridden: boolean) => {
        let price = this.convertToChosenAssetPrice(entityName, asset);

        if (price == 0) {
          price = this.convertToChosenAssetPrice(ENTITY_NAME.NONE, asset, true);
        }

        let total = quantity * price;

        // Overridden is used to not always show things in terms of account
        // balance percentages. E.g. Showing orderbook liquidity would be
        // useless to show in terms of account percentages.
        if (state.chosenAsset === 'Percentage' && !overridden) {
          total = 100 / state.chosenAssetTotal * total;
        }

        return this.roundValueForChosenAsset(total);
      };
    },
    convertSymbolQuantityToChosenAsset() {
      return (quantity: number, price: number, symbol: ChosenSymbol) => {
        const instrumentsStore = useInstrumentsStore();
        const instruments = instrumentsStore.getAll();
        const instrument = instruments?.[symbol.entityName]?.[symbol.entityType]?.[symbol.symbolName];

        if (!instrument) {
          return quantity;
        }

        let isInverted = false;

        if (symbol.entityType === ENTITY_TYPE.DERIVATIVES) {
          if (dollarAssets.includes(instrument.quantityAsset)) {
            isInverted = dollarAssets.includes(instrument.quoteAsset);
          } else if (euroAssets.includes(instrument.quantityAsset)) {
            isInverted = euroAssets.includes(instrument.quoteAsset);
          } else {
            isInverted = (instrument.quantityAsset === instrument.quoteAsset);
          }
        }

        if (!isInverted) {
          quantity *= price;
        }

        return this.getChosenAssetValue(
          symbol.entityName, instrument.quoteAsset, quantity, true,
        );
      };
    },
    convertToChosenAssetPrice(state) {
      return (entityName: string, asset: string, markOnMissing = false) => {
        let assetList = chosenAssets[state.chosenAsset]['assetList'];
        let price = 1;
        const dynamicAsset = (assetList.length === 0); // For assets based in other currencies
        let chosenAsset = state.chosenAsset;

        if (assetList.includes(asset)) { // 1:1 conversion
          return price;
        }

        if (dynamicAsset) {
          const assetValue = chosenAssets[chosenAsset]['assetValue'];

          chosenAsset = assetValue[0];
          assetList = chosenAssets[chosenAsset]['assetList'];
        }

        const prices = this.getPrices();

        // Always at least wait for the price data from the current entity
        if (prices[entityName] === undefined) {
          const entity = useAccountManagementStore().getEntities[entityName];

          // Only non-custom entities have price data
          if (entity && !entity.custom) {
            // Ignore IG and Capital, since they do not have price data by default (unless some orders exist)
            if (![ENTITY_NAME.IG, ENTITY_NAME.CAPITAL].includes(entity.name as ENTITY_NAME)) {
              return 0;
            }
          }
        }

        const currentEntitiesLoadedCount = Object.keys(prices).length;

        if (currentEntitiesLoadedCount > entitiesLoadedCount) {
          entitiesLoadedCount = currentEntitiesLoadedCount;
          // Reset the cache when a new entity has been added to trigger another search for unconvertable assets.
          priceLookupCacheNotFound.value = {};
        }

        for (const entityType of [ENTITY_TYPE.SPOT, ENTITY_TYPE.DERIVATIVES]) {
          if (assetList.includes(asset)) {
            break;
          }

          if (priceLookupCache[asset]?.[chosenAsset] !== undefined) {
            price = this.calculatePriceFromCache(asset, chosenAsset);
            break;
          }

          if (priceLookupCacheNotFound.value[asset]?.[chosenAsset]) {
            price = 0;
            break;
          }

          let result = this.convertToAssetPriceAbstract(entityName, entityType, asset, assetList);

          if (result.price != 0) {
            this.setPriceLookupCache(asset, chosenAsset, [result.cache]);
            price = this.calculatePriceFromCache(asset, chosenAsset);
            break;
          }

          // Two-way lookup on the same entity (expensive)

          const assetMapping = this.getAssetMapping();
          const hopAssets = assetMapping?.[entityName]?.[entityType]?.[asset] || {};
          let results = this.convertToAssetPriceTwoWayLookup(
            entityName, entityType, asset, hopAssets, assetList,
          );

          if (results.length === 2) {
            this.setPriceLookupCache(asset, chosenAsset, [results[0].cache, results[1].cache]);
            price = this.calculatePriceFromCache(asset, chosenAsset);
            break;
          }

          // One-way lookup across entities (spot prices only)

          result = {
            price: 0,
            cache: ['', '', '', false],
          };

          for (const otherEntityName in prices) {
            if (entityName === otherEntityName) {
              continue;
            }

            result = this.convertToAssetPriceAbstract(otherEntityName, ENTITY_TYPE.SPOT, asset, assetList);

            if (result.price != 0) {
              this.setPriceLookupCache(asset, chosenAsset, [result.cache]);
              price = this.calculatePriceFromCache(asset, chosenAsset);
              break;
            }
          }

          if (result.price != 0) {
            break;
          }

          // One-way inverse lookup across entities (spot prices only)

          let found = false;

          for (const otherEntityName in prices) {
            if (entityName === otherEntityName) {
              continue;
            }

            for (const hopAsset in hopAssets) {
              result = this.convertToAssetPriceAbstract(otherEntityName, ENTITY_TYPE.SPOT, hopAsset, assetList);

              if (result.price != 0) {
                // Get the first hop (which will simply be inverted)
                const result2 = this.convertToAssetPrice(entityName, entityType, asset, [hopAsset]);

                if (result2.price == 0) {
                  // Asset exists, but its price is 0 for some reason
                  result = {
                    price: 0,
                    cache: ['', '', '', false],
                  };

                  continue;
                }

                this.setPriceLookupCache(asset, chosenAsset, [result2.cache, result.cache]);
                price = this.calculatePriceFromCache(asset, chosenAsset);
                found = true;
                break;
              }
            }

            if (found) {
              break;
            }
          }

          if (found) {
            break;
          }

          // Two-way lookup across entities (spot prices only)

          results = [];

          for (const otherEntityName in prices) {
            if (entityName === otherEntityName) {
              continue;
            }

            results = this.convertToAssetPriceTwoWayLookup(
              otherEntityName, ENTITY_TYPE.SPOT, asset, hopAssets, assetList);

            if (results.length === 2) {
              break;
            }
          }

          if (results.length === 2) {
            this.setPriceLookupCache(asset, chosenAsset, [results[0].cache, results[1].cache]);
            price = this.calculatePriceFromCache(asset, chosenAsset);
            break;
          }

          // Give up!!!

          // console.log(
          //   `Could not convert asset '${asset}' to ${chosenAsset} on ` +
          //   `${entityName} ${entityType} (${assetKey})`
          // );
          price = 0;
        }

        if (price == 0 && markOnMissing) {
          if (!(asset in priceLookupCacheNotFound.value)) {
            priceLookupCacheNotFound.value[asset] = {};
          }

          priceLookupCacheNotFound.value[asset][chosenAsset] = true;
        }

        if (dynamicAsset) {
          const assetValue = chosenAssets[state.chosenAsset]['assetValue'];
          const chosenAssetValue = assetValue[1];

          price /= chosenAssetValue;
        }

        return price;
      };
    },
    calculatePriceFromCache() {
      return (asset: string, chosenAsset: string) => {
        const prices = this.getPrices();
        const cache = priceLookupCache[asset][chosenAsset];
        let price = 1;

        for (const [entityName, entityType, symbol, inverted] of cache) {
          let lastPrice = parseFloat(prices[entityName][entityType][symbol].lastPrice);

          if (inverted) {
            lastPrice = 1 / lastPrice;
          }

          price *= lastPrice;
        }

        return price;
      };
    },
    convertToAssetPriceTwoWayLookup() {
      return (
        entityName: string, entityType: string, asset: string,
        hopAssets: Record<string, boolean>, assetList: string[],
      ) => {
        const results = [];

        for (const hopAsset in hopAssets) {
          // Should always have a result
          const result1 = this.convertToAssetPriceAbstract(entityName, entityType, asset, [hopAsset]);

          if (result1.price == 0) {
            continue;
          }

          const result2 = this.convertToAssetPriceAbstract(entityName, entityType, hopAsset, assetList);

          if (result2.price != 0) {
            results.push(result1);
            results.push(result2);
            break;
          }
        }

        return results;
      };
    },
    convertToAssetPriceAbstract() {
      return (entityName: string, entityType: string, asset: string, assets: string[]) => {
        let result = this.convertToAssetPrice(entityName, entityType, asset, assets);

        // Fall back to spot if derivatives cannot map
        if (result.price == 0 && entityType !== ENTITY_TYPE.SPOT) {
          result = this.convertToAssetPrice(entityName, ENTITY_TYPE.SPOT, asset, assets);
        }

        return result;
      };
    },
    convertToAssetPrice() {
      return (entityName: string, entityType: string, asset: string, assets: string[]): AssetPriceType => {
        let price = 0;
        let cache: CacheType = ['', '', '', false];

        for (const toAsset of assets) {
          let result = this.findPrice(entityName, entityType, `${asset}-${toAsset}`);

          if (result.price != 0) {
            price = result.price;
            cache = [entityName, entityType, result.pair, false];
            break;
          }

          result = this.findPrice(entityName, entityType, `${toAsset}-${asset}`);

          if (result.price != 0) {
            price = 1 / result.price;
            cache = [entityName, entityType, result.pair, true];
            break;
          }
        }

        return new AssetPriceType(price, cache);
      };
    },
    findPrice() {
      return (entityName: string, entityType: string, consistentSymbol: string) => {
        const instrumentsStore = useInstrumentsStore();
        const actualNames = instrumentsStore.mapName(entityName, entityType, consistentSymbol);
        const prices = this.getPrices();
        let pair = '';
        let price = 0;

        for (const actualName of actualNames) {
          if (prices?.[entityName]?.[entityType]?.[actualName]) {
            const lastPrice = parseFloat(prices[entityName][entityType][actualName].lastPrice);

            // For (at least) Binance DAIUSDT and DAIBUSD (that exist but are unused):
            // USDTDAI and BUSDDAI are the used ones
            if (lastPrice != 0) {
              pair = actualName;
              price = lastPrice;
              break;
            }
          }
        }

        return { price, pair };
      };
    },
    roundValueForChosenAsset(state) {
      return (value: number) => {
        return this.roundValueForAsset(state.chosenAsset, value);
      };
    },
    roundValueForAsset: () => (asset: string, value: number): number => {
      let dp = 0;

      if (chosenAssets[asset] !== undefined) {
        dp = chosenAssets[asset]['dp'];
      } else {
        if (forexAssets.includes(asset)) {
          dp = 0;
        } else if (asset === 'BTC') {
          dp = 4;
        } else {
          dp = 2;
        }
      }

      // Step 1: Convert to number and fix to 20 decimal places
      const fixedValue = Number(value).toFixed(20);
      // Step 2: Shift the decimal point to the right by `dp` places
      const shiftedValue = +`${fixedValue}e+${dp}`;
      // Step 3: Round the shifted number to the nearest integer
      const roundedValue = Math.round(shiftedValue);
      // Step 4: Shift the decimal point back to the left by `dp` places
      const finalShiftedValue = `${roundedValue}e-${dp}`;
      // Step 5: Convert to number
      const result = Number(finalShiftedValue);

      return result; // number rounding
    },
    getPrices: (state) => (): Record<string, Record<string, Record<string, PriceType>>> => {
      return {
        ...usePricesStore().getPrices(),
        [ENTITY_NAME.NONE]: {
          [ENTITY_TYPE.SPOT]: state.hardcodedSymbolPrices,
        },
      };
    },
    getAssetMapping: (state) => (): Record<string, Record<string, Record<string, Record<string, boolean>>>> => {
      const hardcodedMappings: Record<string, Record<string, boolean>> = {};

      for (const symbol in state.hardcodedSymbolPrices) {
        const [baseAsset, quoteAsset] = symbol.split('-');

        if (!hardcodedMappings[baseAsset]) {
          hardcodedMappings[baseAsset] = {};
        }

        hardcodedMappings[baseAsset][quoteAsset] = true;

        if (!hardcodedMappings[quoteAsset]) {
          hardcodedMappings[quoteAsset] = {};
        }

        hardcodedMappings[quoteAsset][baseAsset] = true;
      }

      return {
        ...useInstrumentsStore().getAssetMapping(),
        [ENTITY_NAME.NONE]: {
          [ENTITY_TYPE.SPOT]: hardcodedMappings,
        },
      };
    },
    setPriceLookupCache: () => (asset: string, chosenAsset: string, path: CacheType[]) => {
      if (!(asset in priceLookupCache)) {
        priceLookupCache[asset] = {};
      }

      priceLookupCache[asset][chosenAsset] = path;
    },
  },
});

