<template>
  <div class="grid">
    <div class="col col-12">
      <TabsWrapper main-title="Orderflow Analysis">
        <TabItem title="1: Selection">
          <div class="grid grid--flat-sides">
            <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
              <ExchangeFilter :open="sidebarOpen" />
            </div>
            <div class="col col-remaining">
              <InstrumentsTable
                :symbol-link="false" @subscribe-to-symbol="subscribeToSymbol"
                @unsubscribe-to-symbol="unsubscribeToSymbol"
              />
            </div>
          </div>
        </TabItem>
        <TabItem title="2: Chart">
          <nav class="buttons">
            <section v-for="(symbol, i) in symbols" :key="i" class="buttons__button">
              <input :id="'symbol'+i" type="radio" :checked="i === chosenSymbolIndex" @click="chosenSymbolIndex = i" />
              <label :for="'symbol'+i">
                {{ symbol.entityName }}/{{ symbol.entityType }}/{{ symbol.symbolName }}
              </label>
            </section>
          </nav>
          <ChartView
            v-if="symbols.length" ref="chart" :price-data="chosenPriceData" :customisable="false" :symbol="instrument"
            :price-info="priceInfo" :symbols="chosenSymbols" :market-trades="aggregatedMarketTrades"
            :orderbook="aggregatedOrderbook" :liquidations="liquidations"
            :disable-t-t-c-c="false" @set-timeframe="setTimeframe" @set-ticker-name="setTickerName"
          />
        </TabItem>
      </TabsWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, defineAsyncComponent } from 'vue';
import InstrumentsTable from '@/components/exchanges/instruments/InstrumentsTable.vue';
import TabsWrapper from '@/layout/tabs/TabsWrapper.vue';
import TabItem from '@/layout/tabs/TabItem.vue';
import ExchangeFilter from '@/components/exchanges/sidebar/ExchangeFilter.vue';
import { getDistance } from '@/utilities';
import { ENTITY_NAME } from '@/types/exchange';
import { MarketTradeType } from '@/types/marketTrades';
import { ChosenSymbol } from '@/types/settings';
import { useMarketTradesStore } from '@/stores/exchanges/marketTrades';
import { usePricedataStore } from '@/stores/exchanges/pricedata';
import { PriceData } from '@/types/pricedata';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { InstrumentType, PriceInfo } from '@/types/instruments';
import { useOrderbooksStore } from '@/stores/exchanges/orderbooks';
import { OrderbookType } from '@/types/orderbooks';
import { useWebSocketStore } from '@/stores/user/ws';
import { useSidebarStore } from '@/stores/sidebar';
import { InstrumentView } from '@/types/instruments';
import { useClientLogsStore } from '@/stores/user/clientLogs';

const ChartView = defineAsyncComponent(() =>
  import('@/components/exchanges/symbol/ChartView.vue'),
);

class MarketTradesInfo {
  public index = 0;
  public dist = 0; // distance to offset market trade data from chosen symbol
}

// Store
const orderbooksStore = useOrderbooksStore();
const marketTradesStore = useMarketTradesStore();
const pricedataStore = usePricedataStore();
const instrumentsStore = useInstrumentsStore();
const sidebarStore = useSidebarStore();
const wsStore = useWebSocketStore();
const clientLogsStore = useClientLogsStore();

// Computed
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
const orderbooks = computed(() => orderbooksStore.orderbooks);
const marketTrades = computed(() => marketTradesStore.trades);
const chosenPriceData = computed<PriceData> (() => {
  if (chosenSymbolIndex.value === -1) {
    return null;
  }

  const chosenSymbol = symbols.value[chosenSymbolIndex.value];

  return pricedataStore.data[chosenSymbol.entityName + chosenSymbol.entityType + chosenSymbol.symbolName];
});
const instrument = computed<InstrumentType> (() => {
  if (chosenSymbolIndex.value === -1) {
    return null;
  }

  const chosenSymbol = symbols.value[chosenSymbolIndex.value];

  return instrumentsStore.instruments?.
    [chosenSymbol.entityName]?.[chosenSymbol.entityType]?.[chosenSymbol.symbolName];
});

// Variables
const symbols = ref<ChosenSymbol[]>([]);
const chosenSymbolIndex = ref(-1);
const aggregatedOrderbook = ref<OrderbookType>(null);
const aggregatedMarketTrades = ref<MarketTradeType[]>([]);
const timeframe = ref(0);
const priceInfo = ref<PriceInfo>(null);
const marketTradesInfo = ref<Map<string, MarketTradesInfo>>(new Map());
const orderbookAggregator = ref(0);
const liquidations = ref([]);
const chosenSymbols = ref<Record<string, InstrumentType>> ({}); // Inject combined instrument from selected instruments

// Watchers
watch(instrument, () => {
  if (instrument.value) {
    priceInfo.value = new PriceInfo(instrument.value.tickPrecision, parseFloat(instrument.value.tickSize));
  }
}, { deep: true, immediate: true });

watch(marketTrades, () => {
  marketTradesWatcher();
}, { deep: true });

// Vue Lifecycle Functions
onMounted(() => {
  orderbookAggregator.value = window.setInterval(() => aggregateOrderbook(), 2000);
});

onUnmounted(() => {
  clearInterval(orderbookAggregator.value);
});

// Functions
const marketTradesWatcher = () => {
  if (chosenSymbolIndex.value === -1) {
    return;
  }

  for (let i = 0; i < symbols.value.length; ++i) {
    const symbol = symbols.value[i];
    const trades = marketTrades.value?.[symbol.entityName]?.[symbol.entityType]?.[symbol.symbolName];

    if (!trades) {
      continue;
    }

    calculateSymbolPriceDistance(symbol, i);

    const info = marketTradesInfo.value.get(symbol.key);

    if (info.dist === 0 || info.index === trades.length) {
      continue;
    }

    const newTrades = trades.slice(info.index);

    info.index = trades.length;

    if (info.dist != 1) {
      for (const trade of newTrades) {
        // TODO: round correctly? Probably doesn't matter though for chart representation...
        trade.price = String(parseFloat(trade.price) * info.dist);
      }
    }

    aggregatedMarketTrades.value.push(...newTrades);
  }
};

const calculateSymbolPriceDistance = (symbol: ChosenSymbol, index: number): boolean => {
  if (!marketTradesInfo.value.has(symbol.key)) {
    marketTradesInfo.value.set(symbol.key, new MarketTradesInfo());
  }

  const info = marketTradesInfo.value.get(symbol.key);

  if (index === chosenSymbolIndex.value) {
    if (info.dist === 0) {
      info.dist = 1;
      return true;
    }

    return false;
  }

  // TODO: In future, there should be a better way to calculate the dist. Simply using best bid
  // in the orderbooks is too unstable. Instead, perhaps use priceData and look at mean/median
  // of past X candles OHLC.
  const chosenSymbol = symbols.value[chosenSymbolIndex.value];
  const chosenOrderbook = orderbooks.value[chosenSymbol.entityName]?.[chosenSymbol.entityType]?.
    [chosenSymbol.symbolName];
  const orderbook = orderbooks.value[symbol.entityName]?.[symbol.entityType]?.[symbol.symbolName];

  if (!chosenOrderbook || !orderbook) {
    return false;
  }

  let maxChosenBuy = 0;
  let maxBuy = 0;

  for (const price in chosenOrderbook.buys) {
    maxChosenBuy = Math.max(maxChosenBuy, parseFloat(price));
  }

  for (const price in orderbook.buys) {
    maxBuy = Math.max(maxBuy, parseFloat(price));
  }

  // 27000/26000 = ~1.03...
  // 26000 * ~1.03... = 27000
  info.dist = maxChosenBuy/maxBuy;

  return true;
};

const setTimeframe = (timeframeNew: number) => {
  timeframe.value = timeframeNew;

  if (chosenSymbolIndex.value === -1) {
    return;
  }

  const chosenSymbol = symbols.value[chosenSymbolIndex.value];
  let symbol = chosenSymbol.symbolName;

  // Temporary hack to fix Bitfinex price data fetching... Will not work for funding symbols
  if (chosenSymbol.entityName === ENTITY_NAME.BITFINEX) {
    symbol = 't' + symbol;
  }

  wsStore.send({
    category: 'fetch_symbol_price_data',
    body: JSON.stringify({
      entityName: chosenSymbol.entityName,
      entityType: chosenSymbol.entityType,
      symbol: symbol,
      timeframe: timeframe,
    }),
  });

  // TODO: updateTimeframe is called within ChartView.vue, which does its own price data fetch.
  // This is incorrect. It should be deferred to the calling component
};

const subscribeToSymbol = (symbol: InstrumentView) => {
  const selectedSymbol = new ChosenSymbol(symbol.entityName, symbol.entityType, symbol.symbol);

  for (const symbol of symbols.value) {
    if (symbol.entityName === selectedSymbol.entityName &&
        symbol.entityType === selectedSymbol.entityType &&
        symbol.symbolName === selectedSymbol.symbolName) {
      return;
    }
  }

  symbols.value.push(selectedSymbol);

  if (chosenSymbolIndex.value === -1) {
    chosenSymbolIndex.value = 0;
  }

  let symbolName = selectedSymbol.symbolName;

  // Temporary hack to fix Bitfinex orderbook fetching... Will not work for funding orderbook
  if (symbol.entityName === ENTITY_NAME.BITFINEX) {
    symbolName = 't' + symbol.symbol;
  }

  wsStore.send({
    category: 'subscribe_to_symbol',
    body: JSON.stringify({
      entityName: symbol.entityName,
      entityType: symbol.entityType,
      symbol: symbolName,
      timeframe: timeframe.value,
    }),
  });
};

const setTickerName = (tickerName: string, consistentSymbolName: string = tickerName) => {
  // TODO: this is for changing the selected ticker on the chart ticker name options dropdown
  clientLogsStore.errorLog(
    `[*] TODO setTickerName inputs: ${tickerName}, ${consistentSymbolName}`,
  );
};

const unsubscribeToSymbol = (symbol: InstrumentView) => {
  const index = symbols.value.findIndex(
    elm =>
      elm.symbolName === symbol.symbol &&
      elm.entityType === symbol.entityType &&
      elm.entityName === symbol.entityName,
  );
  removeSymbol(index);
};

const removeSymbol = (i: number) => {
  wsStore.send({
    category: 'unsubscribe_from_symbol',
    body: JSON.stringify({
      entityName: symbols.value[i].entityName,
      entityType: symbols.value[i].entityType,
      symbol: symbols.value[i].symbolName,
    }),
  });

  symbols.value.splice(i, 1);

  if (i === chosenSymbolIndex.value) {
    if (symbols.value.length === 0) {
      chosenSymbolIndex.value = -1;
    } else {
      chosenSymbolIndex.value = 0;
    }
  } else if (i < chosenSymbolIndex.value) {
    --chosenSymbolIndex.value;
  }
};

const aggregateOrderbook = () => {
  if (chosenSymbolIndex.value === -1) {
    return;
  }

  const chosenSymbol = symbols.value[chosenSymbolIndex.value];
  const chosenOrderbook = orderbooks.value[chosenSymbol.entityName]?.
    [chosenSymbol.entityType]?.
    [chosenSymbol.symbolName];

  if (!chosenOrderbook) {
    return;
  }

  const aggregatedOrderbookNew = JSON.parse(JSON.stringify(chosenOrderbook)) as OrderbookType;

  aggregatedOrderbookNew.entityName = '';
  aggregatedOrderbookNew.entityType = '';
  aggregatedOrderbookNew.symbol = '';
  aggregatedOrderbookNew.recentChanges = new Map();
  // new OrderbookType('', '', '', false, {}, {}, false, false);

  let maxBuy = 0;

  for (const price in aggregatedOrderbookNew.buys) {
    maxBuy = Math.max(maxBuy, parseFloat(price));
  }

  const minSell = maxBuy + parseFloat(instrument.value.tickSize);

  for (let i = 0; i < symbols.value.length; ++i) {
    if (i === chosenSymbolIndex.value) {
      continue;
    }

    const symbol = symbols.value[i];
    const orderbook = orderbooks.value[symbol.entityName]?.[symbol.entityType]?.[symbol.symbolName];

    if (!orderbook) {
      continue;
    }

    if (calculateSymbolPriceDistance(symbol, i)) {
      marketTradesWatcher();
    }

    let maxLocalBuy = 0;

    for (const price in orderbook.buys) {
      maxLocalBuy = Math.max(maxLocalBuy, parseFloat(price));
    }

    // maxBuy and maxLocalBuy are aligned to calculate the % delta from current price
    for (let price in orderbook.buys) {
      // Bitstamp has a weird orderbook (BTC-USDT) that has values at 0. This causes
      // problems with this algorithm...
      if (price === '0') {
        price = instrument.value.tickSize;
      }

      const dist = getDistance(price, String(maxLocalBuy), 10);
      const newPrice = maxBuy - dist / 100 * maxBuy;

      // Example: newPrice = 1.13, tickSize = 0.05, tickPrecision = 2, newFixedPrice = 1.1
      const newFixedPrice = (
        newPrice - parseFloat(
          (newPrice % parseFloat(instrument.value.tickSize)).toFixed(instrument.value.tickPrecision),
        )
      ).toFixed(instrument.value.tickPrecision);

      if (!aggregatedOrderbookNew.buys[newFixedPrice]) {
        aggregatedOrderbookNew.buys[newFixedPrice] = {
          quantity: '0', // Not used
          chosenAssetQuantity: '0',
        };
      }

      aggregatedOrderbookNew.buys[newFixedPrice].quantity = String(
        parseFloat(aggregatedOrderbookNew.buys[newFixedPrice].quantity) + parseFloat(orderbook.buys[price].quantity));
      aggregatedOrderbookNew.buys[newFixedPrice].chosenAssetQuantity = (
        parseFloat(aggregatedOrderbookNew.buys[newFixedPrice].chosenAssetQuantity) +
        parseFloat(orderbook.buys[price].chosenAssetQuantity)
      ).toFixed(instrument.value.quantityPrecision);
    }

    for (const price in orderbook.sells) {
      const dist = getDistance(price, String(maxLocalBuy), 10);
      const newPrice = Math.max(maxBuy + dist / 100 * maxBuy, minSell);

      // Example: newPrice = 1.13, tickSize = 0.05, tickPrecision = 2, newFixedPrice = 1.1
      const newFixedPrice = (
        newPrice - parseFloat(
          (newPrice % parseFloat(instrument.value.tickSize)).toFixed(instrument.value.tickPrecision),
        )
      ).toFixed(instrument.value.tickPrecision);

      if (!aggregatedOrderbookNew.sells[newFixedPrice]) {
        aggregatedOrderbookNew.sells[newFixedPrice] = {
          quantity: '0', // Not used
          chosenAssetQuantity: '0',
        };
      }

      aggregatedOrderbookNew.sells[newFixedPrice].quantity = String(
        parseFloat(aggregatedOrderbookNew.sells[newFixedPrice].quantity) + parseFloat(orderbook.sells[price].quantity));
      aggregatedOrderbookNew.sells[newFixedPrice].chosenAssetQuantity = (
        parseFloat(aggregatedOrderbookNew.sells[newFixedPrice].chosenAssetQuantity) +
        parseFloat(orderbook.sells[price].chosenAssetQuantity)
      ).toFixed(instrument.value.quantityPrecision);
    }
  }

  aggregatedOrderbook.value = aggregatedOrderbookNew;
};
</script>

<style lang="scss">
.buttons {
  display: flex;
  flex-wrap: wrap;

  &__button {
    padding: 3px;
    font-size: 14px;
    padding: 7px;

    input {
      margin: 2px;
    }
    label {
      margin: 0 2px;
    }
  }
}
</style>
