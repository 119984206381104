import { defineStore } from 'pinia';
import { SIDE } from '@/types/account';
import { ORDER_CATEGORY, ORDER_TYPE, OrderType } from '@/types/orders';
import Config from '@/config';
import {
  OrderStrategiesState,
  OrderManagementStrategyTypeServer,
  OrderManagementStrategyType,
  OrderManagementStrategiesServerResp,
  OrderManagementStrategyServerResp,
  ORDER_MANAGEMENT_STRATEGY_TYPE,
  ORDER_MOVE_BY,
  OrderCreationStrategyType,
  OrderCreationStrategiesServerResp,
  OrderCreationStrategyServerResp,
  OrderCreationStrategyTypeServer,
} from '@/types/order_strategies';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';
import { useUserStore } from '@/stores/user/user';
import { createRequestData } from '@/utilities';
import { createNotification } from '@/stores/user/notifications';
import { useClientLogsStore } from '@/stores/user/clientLogs';


function performHttpRequest(endpoint: string, requestInfo: RequestInit, updateType: string): Promise<void> {
  return fetch(endpoint, requestInfo).then(response => {
    // Only resolve the outer promise once the inner promise has resolved
    return response.json().then(jsonResp => {
      if (!response.ok) {
        const serverErrorResp = jsonResp as RespError;

        createNotification(
          `Failed to ${updateType} order strategy. Error: ${serverErrorResp.error}`, NOTIFICATION_TYPE.ERROR);
        return Promise.reject();
      }

      createNotification(`Order strategy successfully ${updateType}d`, NOTIFICATION_TYPE.SUCCESS);
      return Promise.resolve();
    }).catch(() => {
      return Promise.reject();
    });
  }).catch(() => {
    return Promise.reject();
  });
}

function convertOrderCreationStrategyTypeServer(
  strategyServer: OrderCreationStrategyTypeServer,
): OrderCreationStrategyType {
  const strategy = new OrderCreationStrategyType();

  strategy.id = strategyServer.id;
  strategy.name = strategyServer.name;

  for (const orderServer of strategyServer.orders) {
    // Explicitly convert the order here, rather than using the pre-existing func (due to missing fields).
    const order = new OrderType();

    if (!(Object.values(ORDER_TYPE).includes(orderServer.type as ORDER_TYPE))) {
      useClientLogsStore().errorLog(
        `[User][*] Order type '${order.type}' invalid`);
      continue;
    }

    if (!(Object.values(ORDER_CATEGORY).includes(orderServer.category as ORDER_CATEGORY))) {
      useClientLogsStore().errorLog(
        `[User][*] Order category '${order.category}' invalid`);
      continue;
    }

    order.type = orderServer.type as ORDER_TYPE;
    order.category = orderServer.category as ORDER_CATEGORY;
    order.quantity = orderServer.quantity;
    order.stopLossPoints = orderServer.stopLossPoints;
    order.profitTargetPoints = orderServer.profitTargetPoints;

    strategy.orders.push(order);
  }

  return strategy;
}

function convertOrderManagementStrategyTypeServer(
  strategyServer: OrderManagementStrategyTypeServer,
): OrderManagementStrategyType {
  // Validate strategyServer
  if (
    !(Object.values(ORDER_MANAGEMENT_STRATEGY_TYPE).includes(strategyServer.type as ORDER_MANAGEMENT_STRATEGY_TYPE))
  ) {
    useClientLogsStore().errorLog(`[*] Order strategy type '${strategyServer.type}' invalid`);
    return null;
  }

  if (!(Object.values(SIDE).includes(strategyServer.side as SIDE))) {
    useClientLogsStore().errorLog(`[*] Order strategy side '${strategyServer.side}' invalid`);
    return null;
  }

  if (!(Object.values(ORDER_MOVE_BY).includes(strategyServer.moveBy as ORDER_MOVE_BY))) {
    useClientLogsStore().errorLog(`[*] Order strategy moveBy '${strategyServer.moveBy}' invalid`);
    return null;
  }

  // Set client strategy
  const strategy = new OrderManagementStrategyType();

  strategy.id = strategyServer.id;
  strategy.name = strategyServer.name;
  strategy.type = strategyServer.type as ORDER_MANAGEMENT_STRATEGY_TYPE;
  strategy.side = strategyServer.side as SIDE;
  strategy.moveBy = strategyServer.moveBy as ORDER_MOVE_BY;
  strategy.pushAwayPercent = strategyServer.pushAwayPercent;
  strategy.pushAwayDelta = strategyServer.pushAwayDelta;
  strategy.frontrunLowBound = strategyServer.frontrunLowBound;
  strategy.frontrunHighBound = strategyServer.frontrunHighBound;
  strategy.minFrontrunAmount = strategyServer.minFrontrunAmount;
  strategy.maxFrontrunAmount = strategyServer.maxFrontrunAmount;
  strategy.makeupPercentage = strategyServer.makeupPercentage;

  return strategy;
}

export const useOrderStrategiesStore = defineStore('orderStrategies', {
  state: (): OrderStrategiesState => ({
    managementStrategies: {},
    orderManagementStrategiesLookup: {},
    creationStrategies: {},
  }),
  actions: {
    setOrderManagementStrategy(strategyServer: OrderManagementStrategyTypeServer) {
      const newStrategy = convertOrderManagementStrategyTypeServer(strategyServer);

      if (newStrategy == null) return;

      this.managementStrategies[newStrategy.id] = newStrategy;

      if (!this.orderManagementStrategiesLookup[newStrategy.id]) {
        this.orderManagementStrategiesLookup[newStrategy.id] = {};
      }
    },
    setOrderCreationStrategy(strategyServer: OrderCreationStrategyTypeServer) {
      const newStrategy = convertOrderCreationStrategyTypeServer(strategyServer);

      if (newStrategy == null) return;

      this.creationStrategies[newStrategy.id] = newStrategy;
    },
    addOrderToManagementStrategiesLookup(strategyId: string, order: OrderType) {
      if (!this.orderManagementStrategiesLookup[strategyId]) {
        this.orderManagementStrategiesLookup[strategyId] = {};
      }

      this.orderManagementStrategiesLookup[strategyId][order.id] = order;
    },
    deleteOrderFromManagementStrategiesLookup(strategyId: string, order: OrderType) {
      if (!this.orderManagementStrategiesLookup[strategyId]) {
        return;
      }

      delete this.orderManagementStrategiesLookup[strategyId][order.id];
    },
    addOrderManagementStrategies(resp: OrderManagementStrategiesServerResp) {
      for (const id in resp.orderStrategies) {
        this.setOrderManagementStrategy(resp.orderStrategies[id]);
      }
    },
    addOrderManagementStrategy(resp: OrderManagementStrategyServerResp) {
      this.setOrderManagementStrategy(resp.orderStrategy);
    },
    deleteOrderManagementStrategy(resp: OrderManagementStrategyServerResp) {
      delete this.managementStrategies[resp.orderStrategy.id];
    },
    createOrderManagementStrategyHttp(body: OrderManagementStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/management`,
        createRequestData('POST', useUserStore().token, body),
        'create',
      );
    },
    updateOrderManagementStrategyHttp(body: OrderManagementStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/management/${body.id}`,
        createRequestData('PATCH', useUserStore().token, body),
        'update',
      );
    },
    deleteOrderManagementStrategyHttp(body: OrderManagementStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/management/${body.id}`,
        createRequestData('DELETE', useUserStore().token, body),
        'delete',
      );
    },
    addOrderCreationStrategies(resp: OrderCreationStrategiesServerResp) {
      for (const id in resp.orderStrategies) {
        this.setOrderCreationStrategy(resp.orderStrategies[id]);
      }
    },
    addOrderCreationStrategy(resp: OrderCreationStrategyServerResp) {
      this.setOrderCreationStrategy(resp.orderStrategy);
    },
    deleteOrderCreationStrategy(resp: OrderCreationStrategyServerResp) {
      delete this.creationStrategies[resp.orderStrategy.id];
    },
    createOrderCreationStrategyHttp(strategy: OrderCreationStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/creation`,
        createRequestData('POST', useUserStore().token, strategy),
        'create',
      );
    },
    updateOrderCreationStrategyHttp(strategy: OrderCreationStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/creation/${strategy.id}`,
        createRequestData('PATCH', useUserStore().token, strategy),
        'update',
      );
    },
    deleteOrderCreationStrategyHttp(strategy: OrderCreationStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/creation/${strategy.id}`,
        createRequestData('DELETE', useUserStore().token, strategy),
        'delete',
      );
    },
  },
  getters: {
    getManagementStrategies: (state) => () => {
      return state.managementStrategies;
    },
    getCreationStrategies: (state) => () => {
      return state.creationStrategies;
    },
  },
});
