import { defineStore } from 'pinia';
import { useUserSettingsStore } from '@/stores/user/settings';
import { ChosenSymbol } from '@/types/settings';
import {
  MarketTradesState, MarkertTradeTypeServer, MarketTradeType, MarketTradesServerResp,
} from '@/types/marketTrades';

function convertMarkertTradeTypeServer(
  marketTrade: MarkertTradeTypeServer, entityName: string, entityType: string, symbol: string,
): MarketTradeType {
  const chosenAssetQuantity = String(useUserSettingsStore().convertSymbolQuantityToChosenAsset(
    parseFloat(marketTrade.quantity),
    parseFloat(marketTrade.price),
    new ChosenSymbol(entityName, entityType, symbol),
  ));

  const newMarketTrade = new MarketTradeType(
    marketTrade.time,
    marketTrade.price,
    marketTrade.quantity,
    chosenAssetQuantity,
    marketTrade.side,
  );

  return newMarketTrade;
}

export const useMarketTradesStore = defineStore('marketTrades', {
  state: (): MarketTradesState => ({
    trades: {},
  }),
  actions: {
    setMarketTrades(body: MarketTradesServerResp) {
      const entityName = body.entityName,
        entityType = body.entityType,
        symbol = body.symbol;

      for (const trade of body.trades) {
        if (!(entityName in this.trades)) {
          this.trades[entityName] = {};
        }

        if (!(entityType in this.trades[entityName])) {
          this.trades[entityName][entityType] = {};
        }

        if (!(symbol in this.trades[entityName][entityType])) {
          this.trades[entityName][entityType][symbol] = [];
        }

        const newMarketTrade = convertMarkertTradeTypeServer(trade, entityName, entityType, symbol);
        this.trades[entityName][entityType][symbol].push(newMarketTrade);
      }
    },
  },
});
