<template>
  <div class="flex flex-row">
    <div class="col col-4 text-center panel">
      <ManagementStrategyForm :current-strategy="orderManagementStrategyType" />
    </div>

    <div class="col col-8 text-center panel">
      <ManagementStrategiesTable @update-order-management-strategy="updateOrderManagementStrategy" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import ManagementStrategyForm from '@/components/exchanges/strategies/ManagementStrategyForm.vue';
import ManagementStrategiesTable from '@/components/exchanges/strategies/ManagementStrategiesTable.vue';
import { OrderManagementStrategyType } from '@/types/order_strategies';

const orderManagementStrategyType = ref(new OrderManagementStrategyType());

onMounted(() => {
  window.addEventListener('keyup', keyUpEventListener);
});

onUnmounted(() => {
  window.removeEventListener('keyup', keyUpEventListener);
});

const keyUpEventListener = (e: KeyboardEvent): void => {
  if (e.key === 'Escape') {
    orderManagementStrategyType.value = new OrderManagementStrategyType();
  }
};

const updateOrderManagementStrategy = (strategy: OrderManagementStrategyType) => {
  orderManagementStrategyType.value = strategy;
};
</script>

<style lang="scss" scoped>

</style>
