<template>
  <div class="flex flex-row">
    <div class="col col-4 text-center panel">
      <CreationStrategyForm :current-strategy="orderCreationStrategyType" />
    </div>

    <div class="col col-8 text-center panel">
      <CreationStrategiesTable @update-order-creation-strategy="updateOrderCreationStrategy" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import CreationStrategyForm from '@/components/exchanges/strategies/CreationStrategyForm.vue';
import CreationStrategiesTable from '@/components/exchanges/strategies/CreationStrategiesTable.vue';
import { OrderCreationStrategyType } from '@/types/order_strategies';

const orderCreationStrategyType = ref(new OrderCreationStrategyType());

onMounted(() => {
  window.addEventListener('keyup', keyUpEventListener);
});

onUnmounted(() => {
  window.removeEventListener('keyup', keyUpEventListener);
});

const keyUpEventListener = (e: KeyboardEvent): void => {
  if (e.key === 'Escape') {
    orderCreationStrategyType.value = new OrderCreationStrategyType();
  }
};

const updateOrderCreationStrategy = (strategy: OrderCreationStrategyType) => {
  orderCreationStrategyType.value = strategy;
};
</script>

<style lang="scss" scoped>

</style>
