<template>
  <div>
    <h3 class="order-form-label text-lg">
      Order Management Strategy Form
    </h3>

    <div>
      <label class="order-form-label">Name</label>
      <input
        v-model="newStrategy.name" type="text" :class="orderFormClass"
        :disabled="requestProcessingAction !== ''" placeholder="e.g front run percent close" class="w-11/12"
      />
    </div>

    <div>
      <h3 class="order-form-label">
        Order Strategy Type
      </h3>
      <select
        v-model="newStrategy.type" :class="orderFormClass" :disabled="requestProcessingAction !== ''" class="w-11/12"
      >
        <option :value="ORDER_MANAGEMENT_STRATEGY_TYPE.DEFAULT" default disabled>
          Select Strategy Type
        </option>
        <option :value="ORDER_MANAGEMENT_STRATEGY_TYPE.NONE">
          None
        </option>
        <option :value="ORDER_MANAGEMENT_STRATEGY_TYPE.PUSH_AWAY">
          Push Away
        </option>
        <option :value="ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PRICE">
          Frontrun Price
        </option>
        <option :value="ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PERCENT">
          Frontrun Percent
        </option>
      </select>
    </div>

    <div v-if="newStrategy.type == ORDER_MANAGEMENT_STRATEGY_TYPE.PUSH_AWAY">
      <div>
        <label class="order-form-label">Percent Away</label>
        <input
          v-model="newStrategy.pushAwayPercent" type="text" :class="orderFormClass"
          placeholder="e.g 80%" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Percent Delta</label>
        <input
          v-model="newStrategy.pushAwayDelta" type="text" :class="orderFormClass"
          placeholder="e.g 5% (percent away 80% move at 75%)" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>
    </div>
    <div
      v-if="newStrategy.type == ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PRICE
        || newStrategy.type == ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PERCENT"
    >
      <div>
        <label class="order-form-label">Low Bound</label>
        <input
          v-model="newStrategy.frontrunLowBound" type="text" :class="orderFormClass"
          placeholder="e.g 20% or 0.5 (nearest price)" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Upper Bound</label>
        <input
          v-model="newStrategy.frontrunHighBound" type="text" :class="orderFormClass"
          placeholder="e.g 80% or 0.1 (furthest price)" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Orderbook Makeup % to Frontrun</label>
        <input
          v-model="newStrategy.makeupPercentage" type="text" :class="orderFormClass"
          placeholder="e.g 10%" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Override: Min $ Amount to Frontrun</label>
        <input
          v-model="newStrategy.minFrontrunAmount" type="text" :class="orderFormClass"
          placeholder="e.g. 10000 (ignore smaller amounts)" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Override: Max $ Amount to Frontrun</label>
        <input
          v-model="newStrategy.maxFrontrunAmount" type="text" :class="orderFormClass"
          placeholder="e.g. 50000 (frontrun larger amounts)" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>
    </div>

    <div
      v-if="newStrategy.type != ORDER_MANAGEMENT_STRATEGY_TYPE.NONE
        && newStrategy.type != ORDER_MANAGEMENT_STRATEGY_TYPE.DEFAULT"
    >
      <div>
        <label class="order-form-label">Side</label>
        <select
          v-model="newStrategy.side" :class="orderFormClass"
          :disabled="requestProcessingAction !== ''" class="w-11/12"
        >
          <option :value="SIDE.UNKNOWN" default disabled>
            Select Side
          </option>
          <option :value="SIDE.BUY">
            Buy
          </option>
          <option :value="SIDE.SELL">
            Sell
          </option>
        </select>
      </div>

      <div>
        <label class="order-form-label">Move By Type</label>
        <select
          v-model="newStrategy.moveBy" :class="orderFormClass"
          :disabled="requestProcessingAction !== ''" class="w-11/12"
        >
          <option :value="ORDER_MOVE_BY.DEFAULT" default disabled>
            Select Move By Type
          </option>
          <option :value="ORDER_MOVE_BY.MARGIN">
            Margin
          </option>
          <option :value="ORDER_MOVE_BY.QUANTITY">
            Quantity
          </option>
        </select>
      </div>
    </div>

    <div class="w-full text-center">
      <button
        v-if="newStrategy.id === ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="createStrategy()"
      >
        Create
      </button>

      <button
        v-if="newStrategy.id !== ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="updateStrategy()"
      >
        Update
      </button>

      <button
        v-if="newStrategy.id !== ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="deleteStrategy()"
      >
        Delete
      </button>
    </div>

    <div v-if="requestProcessingAction !== ''" class="mx-auto text-center my-2">
      <img class="spin-animation" :src="spinningCircle" />
      {{ requestProcessingAction }}...
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import {
  OrderManagementStrategyType, ORDER_MANAGEMENT_STRATEGY_TYPE, ORDER_MOVE_BY,
} from '@/types/order_strategies';
import { SIDE } from '@/types/account';
import spinningCircle from '@/assets/spinning-circle.svg';

const orderStrategiesStore = useOrderStrategiesStore();
const requestProcessingAction = ref('');

// Props
const props = defineProps({
  currentStrategy: {
    type: OrderManagementStrategyType,
    default: () => new OrderManagementStrategyType(),
  },
});

// Computed
const orderFormClass = computed(() => {
  return requestProcessingAction.value ? 'order-form-input-disabled' : 'order-form-input';
});

// Reactive
const newStrategy = ref<OrderManagementStrategyType>(new OrderManagementStrategyType());

watch(() => props.currentStrategy, () => {
  init();
});

// Lifecycle
onMounted(() => {
  init();
});

const init = (): void => {
  // Always clone (in particular, for updating a strategy)
  newStrategy.value = props.currentStrategy.clone();
};

const resetForm = (): void => {
  newStrategy.value = new OrderManagementStrategyType();
};

const createStrategy = () => {
  requestProcessingAction.value = 'Creating';

  orderStrategiesStore.createOrderManagementStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};

const updateStrategy = () => {
  requestProcessingAction.value = 'Updating';

  orderStrategiesStore.updateOrderManagementStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};

const deleteStrategy = () => {
  const confirmation = window.confirm('Are you sure you want to delete this strategy?');

  if (!confirmation) {
    return;
  }

  requestProcessingAction.value = 'Deleting';

  orderStrategiesStore.deleteOrderManagementStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};
</script>

<style lang="scss" scoped>

</style>
