import { defineStore } from 'pinia';
import { SIDE } from '@/types/account';
import { SubEntitySettings, EntitySettings, EntitiesSettingsState } from '@/types/settings';
import { ENTITY_NAME, ENTITY_TYPE } from '@/types/exchange';
import { InstrumentType } from '@/types/instruments';
import { useClientLogsStore } from '@/stores/user/clientLogs';

const defaultEntitySettings: EntitySettings = {
};

const defaultSpotSubEntitySettings: SubEntitySettings = {
  dynamicInstrumentFetching: false,
  pnlCalc: pnlCalcSpot,
  showChartVolumeDefault: true,
  fetchChartHistory: true,
  hasHiddenOrders: true,
  hasReduceOnlyOrders: false,
};

const defaultDerivativesSubEntitySettings: SubEntitySettings = {
  dynamicInstrumentFetching: false,
  pnlCalc: pnlCalcDerivatives,
  showChartVolumeDefault: true,
  fetchChartHistory: true,
  hasHiddenOrders: true,
  hasReduceOnlyOrders: true,
};

function pnlCalcSpot(
  fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType,
): string {
  if (instrument.entityType !== ENTITY_TYPE.SPOT) {
    useClientLogsStore().errorLog(
      `Misconfigured PnL calculation function (expected spot, but got ${instrument.entityType as string}`,
    );
    return 'NaN';
  }

  const pnl = Math.abs((+fromPrice - +toPrice) * +quantity);

  if (side === SIDE.BUY && +fromPrice > +toPrice) {
    return String(-pnl);
  }

  if (side === SIDE.SELL && +fromPrice < +toPrice) {
    return String(-pnl);
  }

  return String(pnl);
}

function pnlCalcDerivatives(
  fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType,
): string {
  if (instrument.entityType !== ENTITY_TYPE.DERIVATIVES) {
    useClientLogsStore().errorLog(
      `Misconfigured PnL calculation function (expected derivatives, but got ${instrument.entityType as string}`,
    );
    return 'NaN';
  }

  const valueMultiplier = +instrument.valueMultiplier;
  let pnl = 0;

  if (instrument.isInverse) {
    pnl = Math.abs(
      (valueMultiplier / +fromPrice - valueMultiplier / +toPrice) * +quantity,
    );
  } else if (instrument.isQuanto) {
    pnl = Math.abs(((+fromPrice - +toPrice) * valueMultiplier) * +quantity);
  } else {
    pnl = Math.abs(((+toPrice - +fromPrice) * +quantity));
  }

  if (side === SIDE.BUY && +fromPrice > +toPrice) {
    return String(-pnl);
  }

  if (side === SIDE.SELL && +fromPrice < +toPrice) {
    return String(-pnl);
  }

  return String(pnl);
}

// const defaultSubEntitySettings = Object.assign(
//   new EntitySettings(), {
//     spot: Object.assign(
//       new SubEntitySettings(),
//       {
//         //,
//       },
//     ),
//     derivatives: Object.assign(
//       new SubEntitySettings(),
//       {
//         //,
//       },
//     ),
//   },
// );

export const useEntitiesSettingsStore = defineStore('entitiesSettings', {
  state: (): EntitiesSettingsState => ({
    entitiesSettings: {},
  }),
  actions: {
    createEntitySettings(entityName: string): void {
      const entitySettings = this.getDefaultEntitySettings();

      // Apply overrides for certain entities
      switch (entityName) {
      case ENTITY_NAME.BINANCE:
        this.overrideBinanceSettings(entitySettings);
        break;
      case ENTITY_NAME.BITSTAMP:
        this.overrideBitstampSettings(entitySettings);
        break;
      case ENTITY_NAME.CAPITAL:
        this.overrideCapitalSettings(entitySettings);
        break;
      case ENTITY_NAME.IG:
        this.overrideIgSettings(entitySettings);
        break;
      case ENTITY_NAME.TEST:
        this.overrideTestSettings(entitySettings);
        break;
      }

      this.entitiesSettings[entityName] = entitySettings;
    },
    getEntitySettings(entityName: string, logStackOffset = 1): EntitySettings | null {
      if (!this.entitiesSettings[entityName]) {
        if (!(Object.values(ENTITY_NAME).includes(entityName as ENTITY_NAME))) {
          useClientLogsStore().errorLog(`[${entityName}] Invalid entity name specified`, logStackOffset);
          return null;
        }

        this.createEntitySettings(entityName);
      }

      return this.entitiesSettings[entityName];
    },
    getSubEntitySettings(entityName: string, entityType: ENTITY_TYPE): SubEntitySettings | null {
      const entitySettings = this.getEntitySettings(entityName, 2);

      if (entitySettings === null) {
        return null;
      }

      switch (entityType) {
      case ENTITY_TYPE.SPOT:
        return entitySettings.spot;
      case ENTITY_TYPE.DERIVATIVES:
        return entitySettings.derivatives;
      default:
        useClientLogsStore().errorLog(`[${entityName}][${entityType as string}] Invalid entity type specified`, 1);
      }

      return null;
    },
    overrideBinanceSettings(entitySettings: EntitySettings): void {
      entitySettings.derivatives = null;
    },
    overrideBitstampSettings(entitySettings: EntitySettings): void {
      entitySettings.spot.hasHiddenOrders = false;

      entitySettings.derivatives = null;
    },
    overrideCapitalSettings(entitySettings: EntitySettings): void {
      entitySettings.spot = null; // Explicitly nullify to highlight bugs (should never access this for Capital)

      entitySettings.derivatives.dynamicInstrumentFetching = true;
      entitySettings.derivatives.showChartVolumeDefault = false; // No way to get volume (especially realtime)
      entitySettings.derivatives.fetchChartHistory = true;
      entitySettings.derivatives.hasHiddenOrders = false;
      entitySettings.derivatives.hasReduceOnlyOrders = false;
    },
    overrideIgSettings(entitySettings: EntitySettings): void {
      entitySettings.spot = null; // Explicitly nullify to highlight bugs (should never access this for IG)

      entitySettings.derivatives.dynamicInstrumentFetching = true;
      entitySettings.derivatives.showChartVolumeDefault = false; // No way to get volume (especially realtime)
      entitySettings.derivatives.fetchChartHistory = false; // Must be false for IG due to very low API limits
      entitySettings.derivatives.hasHiddenOrders = false;
      entitySettings.derivatives.hasReduceOnlyOrders = false;
    },
    overrideTestSettings(entitySettings: EntitySettings): void {
      entitySettings.spot.fetchChartHistory = false; // Needs implementing still

      entitySettings.derivatives.fetchChartHistory = false; // Needs implementing still
    },
  },
  getters: {
    getDefaultEntitySettings: () => (): EntitySettings => {
      const entitySettings = Object.assign(new EntitySettings(), defaultEntitySettings);

      entitySettings.spot = Object.assign(new SubEntitySettings(), defaultSpotSubEntitySettings);
      entitySettings.derivatives = Object.assign(new SubEntitySettings(), defaultDerivativesSubEntitySettings);

      return entitySettings;
    },
  },
});
