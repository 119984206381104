import { defineStore } from 'pinia';
import Config from '@/config';
import { createRequestData, performHttpRequest } from '@/utilities';
import { useUserStore } from '@/stores/user/user';
import { LogFileServerResp, LogFileNamesServerResp } from '@/types/user';
import { LogRow, LogSearch, LogSearchResults, LogSearchResultsServer, ServerLogsState } from '@/types/serverLogs';

export function convertLogSearchResultsServer(logSearchResultServer: LogSearchResultsServer): LogSearchResults {
  const logRows: LogRow[] = [];

  if (logSearchResultServer.results !== null) {
    for (const logRowServer of logSearchResultServer.results) {
      logRows.push(new LogRow(logRowServer.time, logRowServer.logLevel, logRowServer.message));
    }
  }

  return new LogSearchResults(logSearchResultServer.count, logRows);
}

export const useServerLogsStore = defineStore('serverLogs', {
  state: (): ServerLogsState => ({
    fileNames: [],
    file: [],
  }),
  actions: {
    setLogFile(resp: LogFileServerResp) {
      this.file = resp.logFile;
    },
    setLogsFileNames(resp: LogFileNamesServerResp) {
      this.fileNames = resp.logsFileNames;
    },
    search(logSearch: LogSearch) {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/logs?searchTerm=${encodeURIComponent(logSearch.searchTerm)}&countOnly=` +
        `${String(logSearch.countOnly)}&offset=${String(logSearch.offset)}&limit=${String(logSearch.limit)}`,
        requestInfo,
        'receive',
        'logs',
      );
    },
    delete(logSearch: LogSearch) {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('DELETE', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/logs?searchTerm=${encodeURIComponent(logSearch.searchTerm)}`,
        requestInfo,
        'delete',
        'logs',
      );
    },
  },
});
