import { SIDE } from '@/types/account';
import { PriceType } from '@/types/prices';
import { InstrumentType } from './instruments';

export type CacheType = [string, string, string, boolean];
export type PriceLookupCacheType = Record<string, Record<string, CacheType[]>>;
export type priceLookupCacheNotFoundType = Record<string, Record<string, boolean>>;
export type ChosenAssetsType = Record<string, ChosenAssetType>;

export interface ChosenAssetType {
  symbol: string;
  assetList: string[];
  assetValue: [string, number] | null;
  symbolPrefixed: boolean;
  symbolSpace: boolean;
  dp: number;
  minWalletDisplayAmounts: MinWalletDisplayAmount,
}

export class ChosenSymbol {
  public entityName = '';
  public entityType = '';
  public symbolName = '';
  public key = '';

  constructor(entityName: string, entityType: string, symbolName: string) {
    this.entityName = entityName;
    this.entityType = entityType;
    this.symbolName = symbolName;
    this.key = `${entityName}/${entityType}/${symbolName}`;
  }
}

export interface UserSettingsStore {
  theme: string;
  chosenAsset: string;
  chosenAssetTotal: number;
  minWalletDisplayAmounts: Record<string, MinWalletDisplayAmount>;
  maxMarketTradesToDisplay: number;
  hardcodedSymbolPrices: Record<string, PriceType>; // Symbol name must be in consistent form (e.g. BTC-USD)
  chart: ChartSettings;
  tradingSession: TradingSessionSettings;
  portfolio: PortfolioSettings;
}

export interface ChartSettings {
  timeframe: number;
  inverted: boolean;
  showOrders: boolean;
  showPositions: boolean;
  showVolume: boolean;
  showLiquidations: boolean;
  showOrderflow: boolean;
  showOrderbook: boolean;
}

export interface TradingSessionSettings {
  defaultSessionLength: number;
}

export interface PortfolioSettings {
  defaultSelectedCategory: string;
}

export class MinWalletDisplayAmount {
  minBalance: number;
  minAvailableBalance: number;

  constructor(minBalance: number, minAvailableBalance: number) {
    this.minBalance = minBalance;
    this.minAvailableBalance = minAvailableBalance;
  }
}

export class AssetPriceType {
  price: number;
  cache: CacheType;

  constructor(price: number, cache: CacheType) {
    this.price = price;
    this.cache = cache;
  }
}

export class EntitySettings {
  public spot?: SubEntitySettings = null;
  public derivatives?: SubEntitySettings = null;
}

export class SubEntitySettings {
  public pnlCalc: (
    (fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType) => string
  );
  public dynamicInstrumentFetching: boolean;

  // Chart settings
  public showChartVolumeDefault: boolean;
  public fetchChartHistory: boolean;

  // Orders form field showing/hiding
  public hasHiddenOrders: boolean;
  public hasReduceOnlyOrders: boolean;
}

export class EntitiesSettingsState {
  public entitiesSettings: Record<string, EntitySettings>;
}
