<template>
  <div>
    <h3 class="order-form-label text-lg">
      Order Creation Strategy Form
    </h3>

    <div>
      <label class="order-form-label">Name</label>
      <input
        v-model="newStrategy.name" type="text" :class="orderFormClass"
        :disabled="requestProcessingAction !== ''" placeholder="e.g 1 minute NQ scalping" class="w-11/12"
      />
    </div>

    <div v-for="(order, i) in newStrategy.orders" :key="i" class="form__collapse">
      Order {{ i + 1 }}):
      <button class="order-form-btn" @click="removeOrder(i)">
        Remove Order
      </button>

      <div class="form__group">
        <label for="orderType">Type</label>
        <select
          id="orderType" v-model="order.type" :disabled="requestProcessingAction !== ''"
          class="w-11/12 order-form-input"
        >
          <option :value="ORDER_TYPE.LIMIT">Limit</option>
          <option :value="ORDER_TYPE.MARKET">Market</option>
          <option :value="ORDER_TYPE.STOP_MARKET">Stop Market</option>
        </select>
      </div>

      <div class="form__group">
        <label for="category" class="">Category</label>
        <select
          id="category" v-model="order.category" :disabled="requestProcessingAction !== ''"
          class="w-11/12 order-form-input"
        >
          <option :value="ORDER_CATEGORY.PLAIN">{{ ORDER_CATEGORY.PLAIN }}</option>
          <option :value="ORDER_CATEGORY.LIQUIDITY">{{ ORDER_CATEGORY.LIQUIDITY }}</option>
          <option :value="ORDER_CATEGORY.TRADING">{{ ORDER_CATEGORY.TRADING }}</option>
          <option :value="ORDER_CATEGORY.INVESTMENT">{{ ORDER_CATEGORY.INVESTMENT }}</option>
        </select>
      </div>

      <div class="form__group">
        <label class="order-form-label">Quantity</label>
        <input
          v-model="order.quantity" type="text" :class="orderFormClass"
          placeholder="e.g 1" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div class="form__group">
        <label class="order-form-label">Stop loss (points)</label>
        <input
          v-model="order.stopLossPoints" type="text" :class="orderFormClass"
          placeholder="e.g 1" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>

      <div class="form__group">
        <label class="order-form-label">Profit target (points)</label>
        <input
          v-model="order.profitTargetPoints" type="text" :class="orderFormClass"
          placeholder="e.g 1" :disabled="requestProcessingAction !== ''" class="w-11/12"
        />
      </div>
    </div>

    <div class="w-full text-center">
      <button :disabled="requestProcessingAction !== ''" class="order-form-btn" @click="addOrder()">
        Add New Order
      </button>
    </div>

    <div class="w-full text-center">
      <button
        v-if="newStrategy.id === ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="createStrategy()"
      >
        Create
      </button>

      <button
        v-if="newStrategy.id !== ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="updateStrategy()"
      >
        Update
      </button>

      <button
        v-if="newStrategy.id !== ''" :disabled="requestProcessingAction !== ''"
        class="order-form-btn" @click="deleteStrategy()"
      >
        Delete
      </button>
    </div>

    <div v-if="requestProcessingAction !== ''" class="mx-auto text-center my-2">
      <img class="spin-animation" :src="spinningCircle" />
      {{ requestProcessingAction }}...
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { OrderCreationStrategyType } from '@/types/order_strategies';
import { ORDER_CATEGORY, ORDER_TYPE, OrderType } from '@/types/orders';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import spinningCircle from '@/assets/spinning-circle.svg';

const orderStrategiesStore = useOrderStrategiesStore();
const requestProcessingAction = ref('');

// Props
const props = defineProps({
  currentStrategy: {
    type: OrderCreationStrategyType,
    default: () => new OrderCreationStrategyType(),
  },
});

// Computed
const orderFormClass = computed(() => {
  return requestProcessingAction.value ? 'order-form-input-disabled' : 'order-form-input';
});

// Reactive
const newStrategy = ref<OrderCreationStrategyType>(new OrderCreationStrategyType());

watch(() => props.currentStrategy, () => {
  init();
});

// Lifecycle
onMounted(() => {
  init();
});

const init = (): void => {
  // Always clone (in particular, for updating a strategy)
  newStrategy.value = props.currentStrategy.clone();

  if (props.currentStrategy.id === '') {
    addOrder();
  }
};

const resetForm = (): void => {
  newStrategy.value = new OrderCreationStrategyType();
  addOrder();
};

const addOrder = () => {
  const newOrder = new OrderType();

  newOrder.type = ORDER_TYPE.MARKET;
  newOrder.category = ORDER_CATEGORY.TRADING;

  newStrategy.value.orders.push(newOrder);
};

const removeOrder = (i: number) => {
  newStrategy.value.orders.splice(i, 1);

  if (newStrategy.value.orders.length === 0) {
    addOrder();
  }
};

const createStrategy = () => {
  requestProcessingAction.value = 'Creating';

  orderStrategiesStore.createOrderCreationStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};

const updateStrategy = () => {
  requestProcessingAction.value = 'Updating';

  orderStrategiesStore.updateOrderCreationStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};

const deleteStrategy = () => {
  const confirmation = window.confirm('Are you sure you want to delete this strategy?');

  if (!confirmation) {
    return;
  }

  requestProcessingAction.value = 'Deleting';

  orderStrategiesStore.deleteOrderCreationStrategyHttp(newStrategy.value).then(
    () => {
      requestProcessingAction.value = '';
      resetForm();
    },
  ).catch(
    () => {
      requestProcessingAction.value = '';
    },
  );
};
</script>

<style lang="scss" scoped>

</style>
