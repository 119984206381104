import { defineStore } from 'pinia';

import Config from '@/config';
import { createRequestData, performHttpRequest } from '@/utilities';

import { SIDE } from '@/types/account';
import {
  PositionType, FillType, PositionsState, PositionServerResp, PositionsServerResp,
  PositionTypeServer, POSITION_STATUS,
} from '@/types/positions';
import { AccountMeta, ORDER_TYPE, ActiveAction } from '@/types/orders';

import { useEntitiesSettingsStore } from '@/stores/exchanges/settings';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useUserStore } from '@/stores/user/user';
import { ENTITY_TYPE } from '@/types/exchange';


function convertFillTypesServer(entityName: string, position: PositionTypeServer): FillType[] {
  const fills = position.fills;
  const fillsRet: FillType[] = [];

  if (!fills) return fillsRet;

  for (const fill of fills) {
    if (!(Object.values(ORDER_TYPE).includes(fill.type as ORDER_TYPE))) {
      useClientLogsStore().errorLog(
        `[${entityName}] Invalid position fill type '${fill.type}' ` +
        `(valid: ${Object.values(ORDER_TYPE).join(', ')}). Fill: ${JSON.stringify(fill)}. ` +
        `Position: ${JSON.stringify(position)}`,
      );
      continue;
    }

    if (!(Object.values(SIDE).includes(fill.side as SIDE))) {
      useClientLogsStore().errorLog(
        `[${entityName}] Invalid position fill side '${fill.side}' ` +
        `(valid: ${Object.values(SIDE).join(', ')}). Fill: ${JSON.stringify(fill)}. ` +
        `Position: ${JSON.stringify(position)}`,
      );
      continue;
    }

    const newFill = new FillType();

    newFill.quantity = fill.quantity;
    newFill.created = fill.created;
    newFill.price = fill.price;
    newFill.type = fill.type as ORDER_TYPE;
    newFill.fee = fill.fee;
    newFill.pnl = '0';
    newFill.side = fill.side as SIDE;

    fillsRet.push(newFill);
  }

  return fillsRet;
}

export function convertPositionsTypeServer(
  positions: Record<string, PositionTypeServer>, entityName: string, accountId: string,
): Record<string, PositionType> {
  const newPositions: Record<string, PositionType> = {};

  for (const positionId in positions) {
    const newPosition = convertPositionTypeServer(positions[positionId], entityName, accountId);

    newPositions[newPosition.id] = newPosition;
  }

  return newPositions;
}

export function convertPositionTypeServer(
  position: PositionTypeServer, entityName: string, accountId: string,
): PositionType {
  if (!position) {
    return null;
  }

  if (!(Object.values(SIDE).includes(position.side as SIDE))) {
    useClientLogsStore().errorLog(
      `[${entityName}] Invalid position side '${position.side}' ` +
      `(valid: ${Object.values(SIDE).join(', ')}). Position: ${JSON.stringify(position)}`,
    );
    return null;
  }

  if (!(Object.values(POSITION_STATUS).includes(position.status as POSITION_STATUS))) {
    useClientLogsStore().errorLog(
      `[${entityName}] Invalid position status '${position.status}' ` +
      `(valid: ${Object.values(POSITION_STATUS).join(', ')}). Position: ${JSON.stringify(position)}`,
    );
    return null;
  }

  const newPosition = new PositionType(position.id);
  const fills = convertFillTypesServer(entityName, position);
  const subEntitySettings = useEntitiesSettingsStore().getSubEntitySettings(
    entityName,
    position.entityType as ENTITY_TYPE,
  );

  newPosition.symbol = position.symbol;
  newPosition.created = position.created;
  newPosition.updated = position.updated;
  newPosition.setPrice(position.price);
  newPosition.setStopLossPrice(position.stopLossPrice);
  newPosition.setStopLossPoints(position.stopLossPoints);
  newPosition.setProfitTargetPrice(position.profitTargetPrice);
  newPosition.setProfitTargetPoints(position.profitTargetPoints);
  newPosition.setQuantity(position.quantity);
  newPosition.margin = position.margin;
  newPosition.marginAsset = position.marginAsset;
  newPosition.side = position.side as SIDE;
  newPosition.status = position.status as POSITION_STATUS;
  newPosition.leverage = position.leverage;
  newPosition.liquidation = position.liquidation;
  newPosition.accountId = accountId;
  newPosition.calculatePnLInternal = subEntitySettings.pnlCalc;
  newPosition.activeAction = new ActiveAction();
  newPosition.activeAction.lockTime = position.activeAction.lockTime;
  newPosition.activeAction.operation = position.activeAction.operation;

  for (const fill of fills) {
    newPosition.addFill(fill);
  }

  newPosition.entityName = entityName;
  newPosition.entityType = position.entityType;

  return newPosition;
}

export const usePositionsStore = defineStore('positions', {
  state: (): PositionsState => ({
    entities: {},
    positions: new Map<string, PositionType>(),
  }),
  actions: {
    setPosition({ entityName: entityName, accountId, position }: PositionServerResp) {
      const newPosition = convertPositionTypeServer(position, entityName, accountId);

      if (newPosition == null) return;

      if (!(entityName in this.entities)) {
        this.entities[entityName] = {
          accounts: {},
        };
      }

      if (!(accountId in this.entities[entityName].accounts)) {
        this.entities[entityName].accounts[accountId] = {
          positions: {},
        };
      }

      this.entities[entityName].accounts[accountId].positions[position.id] = newPosition;
      this.positions.set(position.id, newPosition);
    },
    addPositions(body: PositionsServerResp) {
      for (const positionId in body.positions) {
        this.setPosition({
          entityName: body.entityName,
          accountId: body.accountId,
          position: body.positions[positionId],
        });
      }
    },
    closePosition(body: PositionServerResp) {
      delete this.entities[body.entityName]?.accounts[body.accountId]?.positions[body.position.id];
      this.positions.delete(body.position.id);
    },
    createPositionHttp(body: PositionType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('POST', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.entityName}/accounts/`
        + `${accountMeta.accountId}/positions`,
        requestInfo,
        'create',
        'position',
      );
    },
    updatePositionHttp(body: PositionType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('PATCH', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.entityName}/accounts/`
        + `${accountMeta.accountId}/positions/${body.id}`,
        requestInfo,
        'update',
        'position',
      );
    },
    deletePositionHttp(body: PositionType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('DELETE', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.entityName}/accounts/`
        + `${accountMeta.accountId}/positions/${body.id}`,
        requestInfo,
        'delete',
        'position',
      );
    },
  },
  getters: {
    getPositions: (state) => (entityName: string, entityType: string, accountId: string) => {
      // Why is this called so much on load?
      const positions: Record<string, PositionType> = {};

      if (!entityName) {
        // Get all positions
        for (const entityName in state.entities) {
          const accounts = state.entities[entityName].accounts;
          for (const accountId in accounts) {
            const account = accounts[accountId];

            for (const positionId in account.positions) {
              const position = account.positions[positionId];

              if (entityType === '' || position.entityType === entityType) {
                positions[positionId] = position;
              }
            }
          }
        }

        return positions;
      }

      if (!(entityName in state.entities)) {
        return positions;
      }

      const accounts = state.entities[entityName].accounts;

      if (!accountId) {
        // Get all positions for entity
        for (const accountId in accounts) {
          const account = accounts[accountId];

          for (const positionId in account.positions) {
            const position = account.positions[positionId];

            if (position.entityType === entityType) {
              positions[positionId] = position;
            }
          }
        }

        return positions;
      }

      const account = accounts[accountId] || { positions: {} };

      // Get all positions for account
      for (const positionId in account.positions) {
        const position = account.positions[positionId];

        if (position.entityType === entityType) {
          positions[positionId] = position;
        }
      }

      return positions;
    },
    getPosition: (state) => (positionId: string): PositionType | null => {
      for (const entityName in state.entities) {
        const accounts = state.entities[entityName].accounts;

        for (const accountId in accounts) {
          const account = accounts[accountId];

          if (account.positions[positionId]) {
            return account.positions[positionId];
          }
        }
      }

      return null;
    },
  },
});
