import { defineStore } from 'pinia';
import Config from '@/config';
import { createRequestData, performHttpRequest } from '@/utilities';
import { createNotification } from '@/stores/user/notifications';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';
import {
  EntityServerResp, AccountServerResp, AccountType, ApiKeyType,
  ApiKeyTypeWrapper, AccountManagementState, ValidEntityNamesServerResp, AccountClientReq, EntityNameTypes,
  SubEntityServerResp, AccountActivity, AccountActivityItem, AccountActivityServerResp,
  ACCOUNT_ACTIVITY,
} from '@/types/account';
import { ENTITY_CATEGORY, EntityType, SubEntityType } from '@/types/exchange';
import { AccountMeta } from '@/types/orders';
import { WalletType } from '@/types/wallets';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useUserStore } from '@/stores/user/user';
import { convertAssetBalanceTypeServer } from '@/stores/exchanges/wallets';
import { ConvertOrderTypeServer } from './orders';
import { convertPositionTypeServer } from './positions';


export function convertAccountActivityTypeServer(
  accountActivityServer: AccountActivityServerResp, entityName: string, accountId: string,
): AccountActivity {
  const accountActivity = new AccountActivity(
    accountActivityServer.fromUnixTimestamp,
    accountActivityServer.toUnixTimestamp,
  );

  for (const serverItem of accountActivityServer.items) {
    if (!(Object.values(ACCOUNT_ACTIVITY).includes(serverItem.category as ACCOUNT_ACTIVITY))) {
      useClientLogsStore().errorLog(
        `[${entityName}][${accountId}] Account activity item category '${serverItem.category}' invalid`);
    }

    const item = new AccountActivityItem(
      serverItem.category as ACCOUNT_ACTIVITY, serverItem.summary, serverItem.timestamp,
    );

    if (serverItem.fromOrder) {
      item.fromOrder = ConvertOrderTypeServer(serverItem.fromOrder, entityName);
    }

    if (serverItem.toOrder) {
      item.toOrder = ConvertOrderTypeServer(serverItem.toOrder, entityName);
    }

    if (serverItem.fromPosition) {
      item.fromPosition = convertPositionTypeServer(serverItem.fromPosition, entityName, accountId);
    }

    if (serverItem.toPosition) {
      item.toPosition = convertPositionTypeServer(serverItem.toPosition, entityName, accountId);
    }

    item.asset = serverItem.asset ? serverItem.asset : '';

    if (item.assetBalanceDelta) {
      item.assetBalanceDelta = convertAssetBalanceTypeServer(item.assetBalanceDelta);
    }

    accountActivity.items.push(item);
  }

  return accountActivity;
}

export const useAccountManagementStore = defineStore('accountManagement', {
  state: (): AccountManagementState => ({
    entities: {},
    validEntityNames: {},
  }),
  actions: {
    setApiKey({ entityName: entityName, accountId, apiKey }: ApiKeyTypeWrapper) {
      const newApiKey = new ApiKeyType(apiKey.key, apiKey.secret, apiKey.name, apiKey.passphrase, apiKey.createdTs);
      this.entities[entityName].accounts[accountId].apiKey = newApiKey;
    },
    setEntity(body: EntityServerResp) {
      if (body.entity.name in this.entities) {
        return;
      }

      if (!(Object.values(ENTITY_CATEGORY).includes(body.entity.category as ENTITY_CATEGORY))) {
        return;
      }

      this.entities[body.entity.name] = new EntityType(
        body.entity.name,
        body.entity.category as ENTITY_CATEGORY,
        body.entity.custom,
      );
    },
    addSubEntity(body: SubEntityServerResp) {
      const entityName = body.entityName;
      const entityType = body.subEntity.entityType;

      if (!(entityName in this.entities)) {
        useClientLogsStore().errorLog(
          `[${entityName}][${entityType}] Failed to add sub entity (entity was not set)`,
        );
        return;
      }

      const entity = this.entities[entityName];

      if (entityType in entity.subEntities) {
        useClientLogsStore().errorLog(
          `[${entityName}][${entityType}] Failed to add sub entity (sub entity was already set)`,
        );
        return;
      }

      entity.subEntities[entityType] = new SubEntityType(entityType);
    },
    setAccount(body: AccountServerResp) {
      const newAccount = new AccountType(body.account.id, body.account.name, body.account.type);
      this.entities[body.entityName].accounts[body.account.id] = newAccount;

      if (body.account.apiKey) {
        const apiKey = new ApiKeyType('', '', '', '', body.account.apiKey.createdTs);
        this.setApiKey({
          entityName: body.entityName,
          accountId: body.account.id,
          apiKey: apiKey,
        });
      }
    },
    setValidEntityNames({ data }: ValidEntityNamesServerResp) {
      this.validEntityNames = data;
    },
    addEntity(body: { userToken: string, entity: EntityType }): Promise<string> {
      const requestInfo = createRequestData('POST', body.userToken, body.entity);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges`,
        requestInfo,
        'create',
        'entity',
      );
    },
    addEntityWallet(
      userToken: string, entityName: string, accountId: string, wallet: WalletType,
    ): Promise<string> {
      const requestInfo = createRequestData('POST', userToken, wallet);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${entityName}/accounts/${accountId}/wallets`,
        requestInfo,
        'create',
        'wallet',
      );
    },
    updateEntityWallet(
      userToken: string, entityName: string, accountId: string, wallet: WalletType,
    ): Promise<string> {
      const requestInfo = createRequestData('PATCH', userToken, wallet);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${entityName}/accounts/${accountId}/wallets/${wallet.id}`,
        requestInfo,
        'update',
        'wallet',
      );
    },
    deleteEntityWallet(userToken: string, entityName: string, accountId: string, wallet: WalletType) {
      const requestInfo = createRequestData('DELETE', userToken, wallet);

      void performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${entityName}/accounts/${accountId}/wallets/${wallet.id}`,
        requestInfo,
        'delete',
        'wallet',
      );
    },
    // TODO deleting entities is currently removed because can't delete entity without affecting test and main net
    // async deleteEntity(body: any) {
    // const requestInfo = DELETE.createRequestData('DELETE', body.userToken, body.entity);
    //   const resp = await fetch(`${Config.apiEndpoint()}/exchanges`, requestInfo);
    //   const jsonResp = await resp.json();
    //   let type, msg;

    //   if (resp.status == 200) {
    // delete this.entities[body.entity.name];
    //     type = NOTIFICATION_TYPE.SUCCESS;
    //     msg = `Entity ${body.entity.name} successfully deleted`;
    //   } else {
    //     type = NOTIFICATION_TYPE.ERROR;
    //     msg = `Entity ${body.entity.name} failed to delete, error: ${jsonResp.error}`
    //   }

    // notificationsStore.addNotification({
    //     "type": type,
    //     "message": msg,
    // });
    //   return resp;
    // },
    async addAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('POST', body.userToken, body.account);

      try {
        const resp = await fetch(`${Config.apiEndpoint()}/exchanges/${body.entityName}/accounts`, requestInfo);
        const jsonResp = await resp.json() as RespError | AccountType;

        if (resp.status == 200) {
          this.setAccount({ entityName: body.entityName, account: jsonResp as AccountType });
          const msg = `Account ${body.entityName} ${body.account.name} successfully added`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const typedResp = jsonResp as RespError;
          const msg = `Account ${body.entityName} ${body.account.name} failed to create, error: ${typedResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async updateAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('PATCH', body.userToken, body.account);

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${body.entityName}/accounts/${body.accountId}`, requestInfo);
        const jsonResp = await resp.json() as RespError | AccountType;
        let msg;

        if (resp.status == 200) {
          const typedResp = jsonResp as AccountType;
          const entityName = body.entityName,
            accountId = typedResp.id,
            accountName = typedResp.name;

          this.entities[entityName].accounts[accountId].name = accountName;
          this.entities[entityName].accounts[accountId].type = body.account.type;

          msg = `Account ${body.entityName} ${body.account.name} successfully updated`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const typedResp = jsonResp as RespError;
          msg = `Account ${body.entityName} ${body.account.name} failed to update, error: ${typedResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async deleteAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('DELETE', body.userToken, body.account);

      try {
        const resp = await fetch(`${Config.apiEndpoint()}/exchanges/${body.entityName}/accounts`, requestInfo);
        const jsonResp = await resp.json() as RespError;

        if (resp.status == 200) {
          delete this.entities[body.entityName].accounts[body.account.id];
          const msg = `Account ${body.entityName} ${body.account.id} successfully deleted`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const msg = `Account ${body.entityName} ${body.account.id} failed to delete, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    fetchAccountActivity(accountMeta: AccountMeta, fromUnixTs: number, toUnixTs: number): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.entityName}/accounts/`
        + `${accountMeta.accountId}/activity?from=${fromUnixTs}&to=${toUnixTs}`,
        requestInfo,
        'receive',
        'account activity',
      );
    },
  },
  getters: {
    getValidEntityNames: (state): Record<string, EntityNameTypes> => state.validEntityNames,
    getOrderedEntityNames: (state) => (withCustomEntities = false): string[] => {
      return Object.keys(state.entities).filter(
        entityName => withCustomEntities || !state.entities[entityName].custom).sort();
    },
    getEntities: (state): Record<string, EntityType> => state.entities,
    getEntity: (state) => (entityName: string): EntityType => {
      return entityName in state.entities ? state.entities[entityName] : null;
    },
    getAccounts: (state) => (entityName: string): Record<string, AccountType> => {
      if (entityName === '') {
        let accounts: Record<string, AccountType> = {};

        for (entityName in state.entities) {
          accounts = {
            ...accounts,
            ...state.entities[entityName].accounts,
          };
        }

        return accounts;
      }

      if (entityName in state.entities) {
        return state.entities[entityName].accounts;
      }

      return null;
    },
    getAccount: (state) => (entityName: string, accountId: string): AccountType => {
      if (entityName in state.entities && accountId in state.entities[entityName].accounts) {
        return state.entities[entityName].accounts[accountId];
      }

      return null;
    },
  },
});
