import { SIDE } from '@/types/account';
import { OrderType, OrderTypeServer } from '@/types/orders';

export enum ORDER_MANAGEMENT_STRATEGY_TYPE {
  DEFAULT = '',
  NONE = 'None',
  PUSH_AWAY = 'PushAway',
  FRONTRUN_PRICE = 'FrontrunPrice',
  FRONTRUN_PERCENT = 'FrontrunPercent'
}

export enum ORDER_MOVE_BY {
  DEFAULT = '',
  MARGIN = 'margin',
  QUANTITY = 'quantity'
}

export class OrderManagementStrategyType {
  public id = ''; // Document Id
  public name = ''; // Alias e.g front run percent near or front run price btc-usdt
  public type: ORDER_MANAGEMENT_STRATEGY_TYPE;
  public side: SIDE;
  public moveBy: ORDER_MOVE_BY;
  // Push away strategy
  public pushAwayPercent = ''; // Probably 80% or 400%
  public pushAwayDelta = ''; // e.g 5% if order falls to 75% away move
  // Front run strategy
  public frontrunLowBound = ''; // Nearest to order e.g 20% away for percent order or $18000 for price order
  public frontrunHighBound = ''; // Furthest away from order e.g 80% away for pecent order or $3000 for price order
  // Amount of dollars to front run
  public minFrontrunAmount = ''; // Ignore if $ amount is less than this
  public maxFrontrunAmount = ''; // Always front run if $ amount is greater than or equal to this
  public makeupPercentage = ''; // if an order quantity makes up X% of the current orderbook (up to the order)

  constructor() {
    this.type = ORDER_MANAGEMENT_STRATEGY_TYPE.DEFAULT;
    this.side = SIDE.UNKNOWN;
    this.moveBy = ORDER_MOVE_BY.DEFAULT;
  }

  public getName(): string {
    if (this.name !== '') {
      return this.name;
    }

    let name = '';

    switch (this.type) {
    case ORDER_MANAGEMENT_STRATEGY_TYPE.PUSH_AWAY:
      name = `Push away ${this.pushAwayPercent}%`;

      if (this.pushAwayDelta !== '') {
        name += ` (±${this.pushAwayDelta}%)`;
      }
      break;
    case ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PRICE:
      name = `Frontrun ${this.frontrunLowBound}..${this.frontrunHighBound}`;
      break;
    case ORDER_MANAGEMENT_STRATEGY_TYPE.FRONTRUN_PERCENT:
      name = `Frontrun ${this.frontrunLowBound}%..${this.frontrunHighBound}%`;
      break;
    }

    name += `: ${this.side.toLocaleUpperCase()} (move by ${this.moveBy}, ` +
      `$${this.minFrontrunAmount}..$${this.maxFrontrunAmount}, ${this.makeupPercentage}%)`;

    return name;
  }

  public clone(): OrderManagementStrategyType {
    return Object.assign(new OrderManagementStrategyType(), this) as OrderManagementStrategyType;
  }
}

export class OrderCreationStrategyType {
  public id: string = '';
  public name: string = '';
  public orders: OrderType[] = [];

  public clone(): OrderCreationStrategyType {
    const cloned = new OrderCreationStrategyType();

    cloned.id = this.id;
    cloned.name = this.name;

    for (const order of this.orders) {
      cloned.orders.push(order.clone());
    }

    return cloned;
  }
}

export interface OrderManagementStrategyTypeServer {
  id: string;
  name: string;
  type: string;
  side: string;
  moveBy: string;
  pushAwayPercent: string;
  pushAwayDelta: string;
  frontrunLowBound: string;
  frontrunHighBound: string;
  minFrontrunAmount: string;
  maxFrontrunAmount: string;
  makeupPercentage: string;
}

export interface OrderManagementStrategiesServerResp {
  orderStrategies: Record<string, OrderManagementStrategyTypeServer>;
}

export interface OrderManagementStrategyServerResp {
  orderStrategy: OrderManagementStrategyTypeServer;
}

export interface OrderCreationStrategyTypeServer {
  id: string;
  name: string;
  orders: OrderTypeServer[];
}

export interface OrderCreationStrategiesServerResp {
  orderStrategies: Record<string, OrderCreationStrategyTypeServer>;
}

export interface OrderCreationStrategyServerResp {
  orderStrategy: OrderCreationStrategyTypeServer;
}

export interface OrderStrategiesState {
  managementStrategies: Record<string, OrderManagementStrategyType>;
  orderManagementStrategiesLookup: Record<string, Record<string, OrderType>>;
  creationStrategies: Record<string, OrderCreationStrategyType>;
}
