import { defineStore } from 'pinia';
import {
  LiquidationsServerResp, LiquidationType, LiquidationsState, LiquidationTypeServer,
} from '@/types/liquidations';

function convertLiquidationTypeServer(liquidation: LiquidationTypeServer): LiquidationType {
  return new LiquidationType(
    liquidation.time,
    liquidation.price,
    liquidation.quantity,
    liquidation.side,
  );
}

export const useLiquidationsStore = defineStore('liquidations', {
  state: (): LiquidationsState => ({
    liquidations: {},
  }),
  actions: {
    setLiquidations(body: LiquidationsServerResp) {
      const entityName = body.entityName,
        entityType = body.entityType,
        symbol = body.symbol;

      for (const liquidation of body.liquidations) {
        if (!(entityName in this.liquidations)) {
          this.liquidations[entityName] = {};
        }

        if (!(entityType in this.liquidations[entityName])) {
          this.liquidations[entityName][entityType] = {};
        }

        if (!(symbol in this.liquidations[entityName][entityType])) {
          this.liquidations[entityName][entityType][symbol] = [];
        }

        this.liquidations[entityName][entityType][symbol].push(convertLiquidationTypeServer(liquidation));
      }
    },
  },
});
