<script setup lang="ts">
import { useSlots, ref , provide } from 'vue';
const slots = useSlots();
const tabTitles = ref<string[]>(
  slots.default({}).map((tab: { props: { title: string } }) => tab.props.title) || [],
);
const selectedTitle = ref(tabTitles.value[0]);
provide('selectedTitle', selectedTitle);
const props = defineProps({
  mainTitle: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-if="props.mainTitle" class="tabs__header__title">
        {{ props.mainTitle }}
      </li>
      <li
        v-for="title in tabTitles" :key="title" class="tabs__header__item"
        :class="{ selected: selectedTitle === title}" @click="selectedTitle = title"
      >
        {{ title }}
      </li>
    </ul>

    <slot />
  </div>
</template>

<style lang="scss">
.tabs {
    &__header {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        border: 1px solid var(--panel-border);
        background: var(--panel-header-bg);
        padding: 6px ;

        &__title {
            padding: 5px;
            margin-right: 12px;
        }

        &__item {
            background-color: #000;
            color: var(--panel-header-color);
            border: 1px solid var(--panel-border);

            transition: .4s all ease-out;
            cursor: pointer;
            user-select: none;
            padding: 5px 10px;
            color: #fff;
            margin-right: 6px;
            font-size: 15px;

            &.selected {
                background-color: var(--brand-primary);
            }
            &:hover {
                background-color: var(--brand-primary-off);
                color: #000;
            }
        }
    }

    &__content {

        .panel {
            margin: 0;
        }
        .panel__header__icon {display: none;}
        .panel__header__button--last {
            margin-right: 0;
        }
    }
}
</style>
