<template>
  <div class="card">
    <span class="h1">Arbitrage Analysis</span>
    <p>Simple price difference checking.</p>
    <div>
      <button v-if="scannerStarted" class="scanner" @click="scannerStarted = false">
        Stop Scanner
      </button>
      <button v-else class="scanner" @click="scannerStarted = true">
        Start Scanner
      </button>
    </div>

    <div v-if="scannerStarted" class="scannerResults">
      Same entity, spot pairs
      <ul v-for="(s, entityName) in sequences.spotSameEntity" :key="entityName">
        {{ entityName }}
        <ul>
          <li v-for="(cycles, rootAsset) in s" :key="rootAsset">
            {{ rootAsset }}
            <ul>
              <li v-for="(cycleInfo, i) in cycles" :key="i">
                {{ cycleInfo.profitPercentage }}%
                ({{ cycleInfo.cycle[0].baseAsset }}-{{ cycleInfo.cycle[0].quoteAsset }}
                => {{ cycleInfo.cycle[1].baseAsset }}-{{ cycleInfo.cycle[1].quoteAsset }})
              </li>
            </ul>
            <br />
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { useArbitrageStore } from '@/stores/exchanges/arbitrage';
import { useWebSocketStore } from '@/stores/user/ws';

// Stores
const arbitrageStore = useArbitrageStore();
const wsStore = useWebSocketStore();

// Computed
const sequences = computed(() => arbitrageStore.sequences);

// Properties
const scannerStarted = ref(false);

// Watchers
watch(scannerStarted, () => {
  toggleScanner();
});

// Functions
const toggleScanner = () => {
  const command = scannerStarted.value ? 'start' : 'stop';
  wsStore.send({ category: 'arbitrage_scanner', body: command });
};
</script>

<style>
.scanner {
  background-color: #777;
  margin: 0.1em;
  padding: 0.1em;
}
.scannerResults ul {
  margin-left: 2em;
}
</style>
