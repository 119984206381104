import { generateClientId } from '@/utilities';

export enum LOG_SEVERITY {
  PINFO = 'PINFO',
  INFO = 'INFO',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}

export class LogsWrapper {
  statusId: string;
  categoryWs: string;
  fileName: string;
  maxLines = 10000;

  constructor(categoryWs: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
  }
}

export class LogSearch {
  public searchTerm: string;
  public countOnly: boolean;
  public offset: number;
  public limit: number;

  constructor(searchTerm: string, countOnly: boolean, offset = 0, limit = 10) {
    this.searchTerm = searchTerm;
    this.countOnly = countOnly;
    this.offset = offset;
    this.limit = limit;
  }
}

export interface LogSearchResultsServer {
  count: number;
  results: LogRowServer[];
}

// Same as DB structure
export interface LogRowServer {
  time: string;
  logLevel: string;
  message: string;
}

export class LogSearchResults {
  public count: number;
  public results: LogRow[];

  constructor(count: number, results: LogRow[]) {
    this.count = count;
    this.results = results;
  }
}

// Same as DB structure
export class LogRow {
  public time: string;
  public logLevel: string;
  public message: string;

  constructor(time: string, logLevel: string, message: string) {
    this.time = time;
    this.logLevel = logLevel;
    this.message = message;
  }
}

export interface ServerLogsState {
  fileNames: string[]
  file: string[]
}
