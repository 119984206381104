import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './index.scss';
import '@/assets/css/themify-icons.css';
import 'md-editor-v3/lib/style.css';
// @ts-expect-error: No ts support https://github.com/Akryum/vue-virtual-scroller/issues/486
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

const pinia = createPinia();
const app = createApp(App);

if (import.meta.env.MODE === 'development') {
  app.config.performance = true;
}

app.use(VueVirtualScroller);
app.use(pinia);
app.use(router).mount('#app');
