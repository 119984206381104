import { defineStore } from 'pinia';
import Config from '@/config';
import { NOTIFICATION_TYPE } from '@/types/user';
import { createNotification } from '@/stores/user/notifications';
import { useUserStore } from '@/stores/user/user';
import { RespError } from '@/types/general';
import { UpdateLeverageData } from '@/types/orders';
import { createRequestData } from '@/utilities';
import {
  InstrumentType, InstrumentState, InstrumentServerResp, InstrumentsServerResp, InstrumentTypeServer,
} from '@/types/instruments';
import { ENTITY_TYPE } from '@/types/exchange';
import { useClientLogsStore } from '@/stores/user/clientLogs';

function convertServerInstrumentToClientInstrument(
  entityName: string, instrument: InstrumentTypeServer,
): InstrumentType {
  if (!(Object.values(ENTITY_TYPE).includes(instrument.entityType as ENTITY_TYPE))) {
    useClientLogsStore().errorLog(
      `[${entityName}][${instrument.entityType}][${instrument.symbol}] Instrument type ` +
      `'${instrument.entityType}' invalid`,
    );
    return null;
  }

  return new InstrumentType(
    instrument.symbol,
    instrument.consistentSymbol,
    instrument.baseAsset,
    instrument.quoteAsset,
    instrument.minQuantity,
    instrument.maxQuantity,
    instrument.tickSize,
    instrument.tickPrecision,
    instrument.quantitySize,
    instrument.quantityPrecision,
    instrument.released,
    instrument.expiry,
    instrument.open,
    instrument.leverage,
    instrument.maxLeverage,
    instrument.crossMargin,
    instrument.takerFee,
    instrument.isInverse,
    instrument.isQuanto,
    instrument.valueMultiplier,
    instrument.lotMultiplier,
    instrument.marginAsset,
    instrument.quantityAsset,
    instrument.entityType as ENTITY_TYPE,
  );
}

export const useInstrumentsStore = defineStore('instruments', {
  state: (): InstrumentState => ({
    instruments: {},
    nameMappings: {}, // for consistent names => actual names
    assetMapping: {}, // e.g. BTC => {USDT => true, ...} (BTC-USDT)
    cachedInstruments: {},
    instrument: null,
  }),
  actions: {
    setInstrument({ entityName: entityName, entityType: entityType, symbol, instrument }: InstrumentServerResp) {
      if (!(entityName in this.instruments)) {
        this.instruments[entityName] = {};
        this.nameMappings[entityName] = {};
        this.assetMapping[entityName] = {};
      }

      if (!(entityType in this.instruments[entityName])) {
        this.instruments[entityName][entityType] = {};
        this.nameMappings[entityName][entityType] = {};
        this.assetMapping[entityName][entityType] = {};
      }

      const newInstrument = convertServerInstrumentToClientInstrument(entityName, instrument);

      if (!(symbol in this.instruments[entityName][entityType])) {
        const [baseAsset, quoteAsset] = instrument.consistentSymbol.split('-');

        if (!(instrument.consistentSymbol in this.nameMappings[entityName][entityType])) {
          this.nameMappings[entityName][entityType][instrument.consistentSymbol] = [];
        }

        this.nameMappings[entityName][entityType][instrument.consistentSymbol].push(symbol);

        if (!(baseAsset in this.assetMapping[entityName][entityType])) {
          this.assetMapping[entityName][entityType][baseAsset] = {};
        }

        this.assetMapping[entityName][entityType][baseAsset][quoteAsset] = true;

        if (!(quoteAsset in this.assetMapping[entityName][entityType])) {
          this.assetMapping[entityName][entityType][quoteAsset] = {};
        }

        this.assetMapping[entityName][entityType][quoteAsset][baseAsset] = true;
      }

      // TODO: Don't always do this - only do it for instrument adding or updating
      this.instruments[entityName][entityType][symbol] = newInstrument;
    },
    addInstruments(body: InstrumentsServerResp) {
      for (const symbol in body.instruments) {
        this.setInstrument({
          entityName: body.entityName,
          entityType: body.entityType,
          symbol,
          instrument: body.instruments[symbol],
        });
      }
    },
    addInstrument(body: InstrumentServerResp) {
      this.setInstrument({
        entityName: body.entityName,
        entityType: body.entityType,
        symbol: body.instrument.symbol,
        instrument: body.instrument,
      });
    },
    updateInstrument(body: InstrumentServerResp) {
      this.setInstrument({
        entityName: body.entityName,
        entityType: body.entityType,
        symbol: body.instrument.symbol,
        instrument: body.instrument,
      });
    },
    async updateInstrumentLeverage(body: UpdateLeverageData) {
      const leverageData = body.leverageData;
      const requestInfo = createRequestData('POST', body.userToken, leverageData);
      const accountMeta = leverageData.accountMeta;

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${accountMeta.entityName}/accounts/${accountMeta.accountId}/leverage`,
          requestInfo,
        );

        if (resp.status == 200) {
          const msg = `${leverageData.symbol} leverage successfully updated to  ${leverageData.leverage}`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `${leverageData.symbol} leverage failed to update, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async fetchInstrument(entityName: string, symbol: string) {
      const key = `${entityName}+${symbol}`;

      if (key in this.cachedInstruments) {
        this.instrument = this.cachedInstruments[key];
        return this.instrument;
      }

      const userStore = useUserStore();
      const requestInfo = createRequestData('GET', userStore.token, '');

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${entityName}/exchangeTypes/Derivatives/instruments?symbol=${symbol}`,
          requestInfo,
        );

        if (resp.status == 200) {
          const jsonResp = await resp.json() as { instrument: InstrumentTypeServer };
          this.cachedInstruments[key] = convertServerInstrumentToClientInstrument(entityName, jsonResp.instrument);
          this.instrument = this.cachedInstruments[key];
          const msg = `${symbol} successfully fetched for entity ${entityName}`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return this.instrument;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `Entity ${entityName} failed to create, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async searchInstruments(entityName: string, symbolSearch: string) {
      const userStore = useUserStore();
      const requestInfo = createRequestData('GET', userStore.token, '');

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${entityName}/exchangeTypes/Derivatives/searchInstruments`
          + `?symbol=${symbolSearch}`,
          requestInfo,
        );

        if (resp.status == 200) {
          const jsonResp = await resp.json() as { instruments: Record<string, InstrumentTypeServer> };
          const instruments = [];

          for (const name in jsonResp.instruments) {
            const instrument = jsonResp.instruments[name];

            instruments.push(
              convertServerInstrumentToClientInstrument(entityName, instrument),
            );
          }

          return instruments;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `Entity ${entityName} failed to search symbol ${symbolSearch}, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return [];
      }
    },
  },
  getters: {
    getAssetMapping: (state) => () => state.assetMapping,
    getAll: (state) => () => state.instruments,
    getInstrument: (state) => (entityName: string, entityType: ENTITY_TYPE, symbol: string): InstrumentType | null => {
      return state.instruments?.[entityName]?.[entityType]?.[symbol] || null;
    },
    mapName: (state) => (entityName: string, entityType: string, consistentSymbol: string): string[] => {
      return state.nameMappings?.[entityName]?.[entityType]?.[consistentSymbol] || [consistentSymbol];
    },
  },
});
