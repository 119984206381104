import { generateClientId } from '@/utilities';

export interface MarketTradesState {
  trades: Record<string, Record<string, Record<string, MarketTradeType[]>>>;
}

export interface MarkertTradeTypeServer {
  time: number;
  price: string;
  quantity: string;
  side: string;
}

export interface MarketTradesServerResp {
  category: string;
  type: string;
  entityName: string;
  entityType: string;
  symbol: string;
  trades: MarkertTradeTypeServer[];
}

export class MarketTradeRowDisplay {
  public marketTrade: MarketTradeType;
  public id: string;

  constructor(marketTrade: MarketTradeType) {
    this.marketTrade = marketTrade;
    this.id = generateClientId();
  }
}

export class MarketTradeType {
  public time: number;
  public price: string;
  public quantity: string;
  public chosenAssetQuantity: string;
  public side: string;

  // Auxilliary properties (for lightweight-charts)
  public buyVolume: number;
  public sellVolume: number;

  constructor(time: number, price: string, quantity: string, chosenAssetQuantity: string, side: string) {
    this.time = time;
    this.price = price;
    this.quantity = quantity;
    this.chosenAssetQuantity = chosenAssetQuantity;
    this.side = side;
  }
}
