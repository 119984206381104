import { tidyNumber } from '@/utilities';
import { useUserSettingsStore } from '@/stores/user/settings';
import { SIDE } from '@/types/account';
import { ChosenSymbol } from '@/types/settings';

export interface AbstractQuantity {
  quantity: string;
  chosenAssetQuantity: string;
}

export class OrderbookRowChange {
  buyQuantity: string = '';
  sellQuantity: string = '';
  side: string;
  price: string;
  quantity: string;
  chosenAssetQuantity: string;

  constructor(side: string, price: string, quantity: string, chosenAssetQuantity: string) {
    this.side = side;
    this.price = price;
    this.quantity = quantity;
    this.chosenAssetQuantity = chosenAssetQuantity;
  }
}

export class OrderbookType {
  public entityName: string;
  public entityType: string;
  public symbol: string;
  public buys: Record<string, AbstractQuantity> = {}; // price => AbstractQuantity
  public sells: Record<string, AbstractQuantity> = {}; // price => AbstractQuantity
  public incompleteBuys: boolean;
  public incompleteSells: boolean;
  public forOrderflow: boolean;
  public recentChanges: Map<string, OrderbookRowChange>; // for updating lightweight-charts

  constructor(
    entityName: string,
    entityType: string,
    symbol: string,
    forOrderflow: boolean,
    incompleteBuys: boolean,
    incompleteSells: boolean,
  ) {
    this.entityName = entityName;
    this.entityType = entityType;
    this.symbol = symbol;
    this.forOrderflow = forOrderflow;
    this.incompleteBuys = incompleteBuys;
    this.incompleteSells = incompleteSells;
    this.recentChanges = new Map();
  }

  public setBuy(price: string, quantity: string): OrderbookRowChange {
    const tidyPrice = tidyNumber(price);
    let abstractQuantity: AbstractQuantity = null;

    if (tidyNumber(quantity) === '0') {
      abstractQuantity = this.buys[tidyPrice] || { quantity: '0', chosenAssetQuantity: '0' };

      delete(this.buys[tidyPrice]);
    } else {
      this.buys[tidyPrice] = {
        quantity: quantity,
        chosenAssetQuantity: String(
          useUserSettingsStore().convertSymbolQuantityToChosenAsset(
            parseFloat(quantity),
            parseFloat(price),
            new ChosenSymbol(this.entityName, this.entityType, this.symbol),
          ),
        ),
      };
      abstractQuantity = this.buys[tidyPrice];
    }

    return new OrderbookRowChange(
      SIDE.BUY, tidyPrice, abstractQuantity.quantity, abstractQuantity.chosenAssetQuantity,
    );
  }

  public setSell(price: string, quantity: string): OrderbookRowChange {
    const tidyPrice = tidyNumber(price);
    let abstractQuantity: AbstractQuantity = null;

    if (tidyNumber(quantity) === '0') {
      abstractQuantity = this.sells[tidyPrice] || { quantity: '0', chosenAssetQuantity: '0' };

      delete(this.sells[tidyPrice]);
    } else {
      this.sells[tidyPrice] = {
        quantity: quantity,
        chosenAssetQuantity: String(
          useUserSettingsStore().convertSymbolQuantityToChosenAsset(
            parseFloat(quantity),
            parseFloat(price),
            new ChosenSymbol(this.entityName, this.entityType, this.symbol),
          ),
        ),
      };
      abstractQuantity = this.sells[tidyPrice];
    }

    return new OrderbookRowChange(
      SIDE.SELL, tidyPrice, abstractQuantity.quantity, abstractQuantity.chosenAssetQuantity,
    );
  }
}

export type OrderbookLiquidityKeys = keyof OrderbookLiquidity;

export class OrderbookLiquidity {
  public entityName: string;
  public entityType: string;
  public symbol: string;
  public consistentSymbol: string;
  public buysTotal: string;
  public sellsTotal: string;

  constructor(
    entityName: string,
    entityType: string,
    symbol: string,
    consistentSymbol: string,
    buysTotal: string,
    sellsTotal: string,
  ) {
    this.entityName = entityName;
    this.entityType = entityType;
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
    this.buysTotal = buysTotal;
    this.sellsTotal = sellsTotal;
  }
}

export interface OrdersbooksState {
  orderbooks: Record<string, Record<string, Record<string, OrderbookType>>>;
  orderbooksLiquidity: Record<string, OrderbookLiquidity>;
}

export interface OrderbookServerResp {
  entityName: string;
  entityType: string;
  symbol: string;
  orderbook: OrderbookTypeServer;
}

export interface OrderbookChangesServerResp {
  entityName: string;
  entityType: string;
  symbol: string;
  quantity: string;
  updates: OrderbookRowChangeServer[];
}

export interface OrderbookTypeServer {
  buys: Record<string, string>;
  sells: Record<string, string>;
  incompleteBuys: boolean;
  incompleteSells: boolean;
}

export interface OrderbookRowChangeServer {
  category: string;
  side: string;
  price: string;
  quantity: string;
}

export interface OrderbookLiquidityServerResp {
  category: string;
  type: string;
  entityName: string;
  entityType: string;
  symbol: string;
  consistentSymbol: string;
  buysTotal: string;
  sellsTotal: string;
}
