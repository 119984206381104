<template>
  <div class="card">
    <p>IG Trade Analysis</p>

    <section>
      <select v-model="selectedEntity" class="table-input-tw">
        <option value="">Select Entity</option>
        <option value="" disabled>--</option>
        <option v-for="entityName in orderedEntityNames" :key="entityName">{{ entityName }}</option>
      </select>
      <select v-if="selectedEntity" v-model="selectedAccountId" class="table-input-tw">
        <option value="">Select Account</option>
        <option value="" disabled>--</option>
        <option v-for="(account, accountId) in accounts" :key="accountId" :value="accountId">
          {{ account.name }} ({{ accountId }})
        </option>
      </select>
      <input
        v-model="fromDate" class="sidebar__setting__input" type="text" placeholder="{{ this.getDate() }}"
      />
      <input
        v-model="toDate" class="sidebar__setting__input" type="text" placeholder="{{ this.getDate() }}"
      />
      <section>
        <button class="btn-tw" @click="igTradeFetching()">
          Fetch IG Trades
        </button>

        Play speed (ms): <input v-model="speedInMilliseconds" class="btn-tw" type="number" placeholder="1000" />
        <select v-model="selectedMarket" name="selectedMarketFilter" class="chart__header__select">
          <option v-for="market in markets" :key="market[0]" :value="market[0]">
            {{ market[1] }}
          </option>
        </select>
        <button class="btn-tw" @click="playTrades(true)">Play</button>
        <button class="btn-tw" @click="pauseTrades()">Pause</button>
        <button class="btn-tw" @click="resetPlayTrades()">Reset</button>
        <button class="btn-tw" @click="reversePlayTrades()">Previous</button>
        <button class="btn-tw" @click="playTrades(false)">Next</button>
      </section>
    </section>

    <section class="panel priceAction-chart-wrapper">
      <section class="chart-sidebar">
        <section v-if="accountActivityByMarket.size">
          {{ fetchMsg }}
          <hr /><br />

          <span>
            {{ markets.get(selectedMarket) }} ({{ selectedMarket }})
            - {{ accountActivityByMarket.get(selectedMarket)?.items.length }} trade(s)
          </span>
          <section
            v-for="(activity, i) in accountActivityByMarket.get(selectedMarket).items" :key="i" class="panel hand"
            @click="selectActivity(i)"
          >
            <section :class="marketPlayStep - 1 === i ? 'greyBg2' : 'greyBg1'">
              <span :title="String(activity.timestamp)">
                {{ i + 1 }}) {{ activity.summary }}
              </span>
            </section>
          </section>
        </section>
        <section v-else>
          Fetch trades...
        </section>
      </section>

      <ChartView
        :price-data="priceData" :customisable="false" :disable-t-t-c-c="true" :use-order-position-source="true"
        :chart-bus="chartBus" :orders="Object.fromEntries(marketPlayState.ordersOpen)"
        :positions="Object.fromEntries(marketPlayState.positionsOpen)"
      />
    </section>

    <section>
      Session summary of positions (total PnL = {{ currencyFormatter(totalPnL, 2, '£') }}):
      <section v-for="[market, positions] in closedPositions" :key="market">
        Market: {{ markets.get(market) }}

        <table class="table-tw">
          <thead class="table-thead-tw">
            <tr>
              <th class="table-th-tw">Time</th>
              <th class="table-th-tw">Entry Price</th>
              <th class="table-th-tw">Entry Size</th>
              <th class="table-th-tw">Initial SL</th>
              <th class="table-th-tw">Initial risk</th>
              <th class="table-th-tw">Overall PnL</th>
              <th class="table-th-tw">R:R ratio</th>
            </tr>
          </thead>
          <tbody class="table-tbody-tw">
            <tr
              v-for="[id, position] in positions" :key="id"
              class="table-tr-tw hand" @click="showPosition(market, position as PositionType)"
            >
              <td class="table-td-tw">{{ formatDateTime(position.created * 1000) }}</td>
              <td class="table-td-tw">{{ position.initialPrice }}</td>
              <td class="table-td-tw">{{ position.initialQuantity }}</td>
              <td class="table-td-tw">{{ position.initialStopLossPrice }}</td>
              <td class="table-td-tw">
                {{
                  useUserSettingsStore().getChosenAssetSymbolWithAmount(
                    formatNumber(
                      position.calculatePnL(
                        position.initialPrice, position.initialStopLossPrice, position.initialQuantity,
                      ),
                    ),
                  )
                }}
              </td>
              <td class="table-td-tw">
                {{
                  useUserSettingsStore().getChosenAssetSymbolWithAmount(
                    formatNumber(
                      position.pnl,
                    ),
                  )
                }}
              </td>
              <td class="table-td-tw">
                <span v-if="+position.pnl >= 0">
                  1:{{
                    roundNumber(
                      Math.abs(+position.pnl) /
                        Math.abs((+position.initialPrice - +position.initialStopLossPrice) * +position.initialQuantity),
                      1,
                    ) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, defineAsyncComponent, onMounted } from 'vue';
import { useAccountManagementStore, convertAccountActivityTypeServer } from '@/stores/exchanges/accountManagement';
import { useIgStore } from '@/stores/exchanges/ig';
import { useQueryStringStore } from '@/stores/queryString';
import { useNotificationsStore } from '@/stores/user/notifications';
import { useRoute } from 'vue-router';
import { PriceData, PriceDataServer } from '@/types/pricedata';
import { AccountActivity, ACCOUNT_ACTIVITY, AccountActivityServerResp } from '@/types/account';
import { AccountMeta } from '@/types/orders';
import { PositionType } from '@/types/positions';
import { IgMarketState } from '@/types/ig';
import { NOTIFICATION_TYPE, NotificationType } from '@/types/user';
import { currencyFormatter, formatDateTime, formatNumber, roundNumber } from '@/utilities';
import mitt from 'mitt';
import { Event } from '@/types/general';
import { Emitter } from 'mitt';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useUserSettingsStore } from '@/stores/user/settings';
import { ENTITY_TYPE } from '@/types/exchange';

const ChartView = defineAsyncComponent(() =>
  import('@/components/exchanges/symbol/ChartView.vue'),
);

enum PERFORM_STEP {
  BY_ACTIVITY = 0,
  BY_CANDLE = 1,
  BY_BOTH = 2,
}

// Store
const igStore = useIgStore();
const queryStringStore = useQueryStringStore();
const notificationsStore = useNotificationsStore();
const clientLogsStore = useClientLogsStore();
const route = useRoute();
const accountManagementStore = useAccountManagementStore();

// Computed
const igPriceData = computed(() => igStore.priceData);
const orderedEntityNames = computed(() => accountManagementStore.getOrderedEntityNames());
const accounts = computed(() => accountManagementStore.getAccounts(selectedEntity.value));

// Variables
const fromDate = ref<string | null>(null);
const toDate = ref<string | null>(null);
const markets = ref(new Map<string, string>());
const marketPlayStep = ref(0);
const marketPlayTimer = ref(0);
const marketPlayStates = ref(new Map<string, IgMarketState>());
const marketPlayPnLs = ref(new Map<string, number>());
const closedPositions = ref(new Map<string, Map<string, PositionType>>());
const marketPlayState = ref(new IgMarketState());
const totalPnL = ref(0);
const fetchMsg = ref('');
const speedInMilliseconds = ref(1000);
const priceData = ref<PriceData | null>(null);
const igPriceDataIndex = ref(0);
const waitOnPriceDataForActivity = ref(-2); // Blocks until price data comes through
const waitOnPriceDataForPosition = ref<PositionType>(null); // Blocks until price data comes through
const selectedEntity = ref('');
const selectedAccountId = ref('');
const selectedMarket = ref('');
const automatedPlaying = ref(false);
const chartBus = ref<Emitter<Event>>(mitt<Event>());
const accountActivity = ref<AccountActivity | null>(null);
const accountActivityByMarket = ref(new Map<string, AccountActivity>());

// Watchers
watch(accountActivityByMarket, () => {
  if (!accountActivityByMarket.value.size) {
    fetchMsg.value = 'No trade data found';
    return;
  }

  let totalTrades = 0;

  for (const [market, accountActivity] of accountActivityByMarket.value) {
    totalTrades += accountActivity.items.length;

    if (!marketPlayPnLs.value.has(market)) {
      marketPlayPnLs.value.set(market, 0);
      markets.value.set(market, market); // TODO: get actual market name?
    }

    selectedMarket.value = market;

    while (performStep(true, false, PERFORM_STEP.BY_ACTIVITY));

    totalPnL.value += marketPlayPnLs.value.get(market);
    closedPositions.value.set(market, marketPlayStates.value.get(market).positionsClosed);

    resetPlayTrades();
  }

  // Deliberately don't set selectedMarket = '' here to autofill the selector with a value.

  if (totalTrades === 1) {
    fetchMsg.value = `${totalTrades} trade found across 1 market`;
  } else {
    fetchMsg.value = `${totalTrades} trades found across ${accountActivityByMarket.value.size} market`;

    if (accountActivityByMarket.value.size > 1) {
      fetchMsg.value += 's';
    }
  }
}, { deep: true });

watch(igPriceData, () => {
  if (waitOnPriceDataForActivity.value > -2) {
    setPriceData();
    startPlaying(waitOnPriceDataForActivity.value);
    waitOnPriceDataForActivity.value = -2;
  } else if (waitOnPriceDataForPosition.value !== null) {
    showPositionInternal(waitOnPriceDataForPosition.value);

    waitOnPriceDataForPosition.value = null;
  }
});

watch(fromDate, () => {
  if (fromDate.value !== route.query.fromDate as string) {
    queryStringStore.update({ 'fromDate': fromDate.value || null });
  }
});

watch(toDate, () => {
  if (toDate.value !== route.query.toDate as string) {
    queryStringStore.update({ 'toDate': toDate.value || null });
  }
});

watch(selectedEntity, () => {
  if (selectedEntity.value !== route.query.selectedEntity as string) {
    queryStringStore.update({ 'selectedEntity': selectedEntity.value || null });
  }

  selectedAccountId.value = '';
});

watch(selectedAccountId, () => {
  if (selectedAccountId.value !== route.query.selectedAccountId as string) {
    queryStringStore.update({ 'selectedAccountId': selectedAccountId.value || null });
  }
});

watch(accounts, () => {
  if (!selectedAccountId.value) {
    return;
  }

  if (accounts.value[selectedAccountId.value]) {
    return;
  }

  selectedAccountId.value = '';
});

// Vue Lifecycle Functions
onBeforeMount(() => {
  fromDate.value = route.query.fromDate as string || getDate();
  toDate.value = route.query.toDate as string || getDate();
  selectedEntity.value = route.query.selectedEntity as string || '';
});

onMounted(() => {
  // Vue bug (delay selecting account)
  selectedAccountId.value = route.query.selectedAccountId as string || '';
});

// Methods
const igTradeFetching = () => {
  waitOnPriceDataForActivity.value = -2;
  igStore.resetPriceData();
  resetPlayTrades();
  resetAccountActivity();

  accountManagementStore.fetchAccountActivity(
    new AccountMeta(selectedAccountId.value, selectedEntity.value, ENTITY_TYPE.DERIVATIVES),
    new Date(fromDate.value).getTime(),
    new Date(toDate.value).getTime() + 1000 * 60 * 60 * 24,
  ).then((jsonResp) => {
    accountActivity.value = convertAccountActivityTypeServer(
      JSON.parse(jsonResp) as AccountActivityServerResp,
      selectedEntity.value,
      selectedAccountId.value,
    );

    for (const activity of accountActivity.value.items) {
      if ([ACCOUNT_ACTIVITY.DEPOSIT, ACCOUNT_ACTIVITY.WITHDRAWAL].includes(activity.category)) {
        continue;
      }

      let market = '';

      if (activity.fromOrder) {
        market = activity.fromOrder.symbol;
      } else if (activity.toOrder) {
        market = activity.toOrder.symbol;
      } else if (activity.fromPosition) {
        market = activity.fromPosition.symbol;
      } else if (activity.toPosition) {
        market = activity.toPosition.symbol;
      } else {
        // Unknown state
        console.error('Unknown market', activity);
        continue;
      }

      if (!accountActivityByMarket.value.has(market)) {
        accountActivityByMarket.value.set(market, new AccountActivity(activity.timestamp, activity.timestamp));

        if (selectedMarket.value === '') {
          selectedMarket.value = market;
        }
      }

      const accountActivity = accountActivityByMarket.value.get(market);

      accountActivity.toUnixTimestamp = activity.timestamp;
      accountActivity.items.push(activity);
    }
  }).catch(() => {
    // Nothing more to do
  });
};

const getPriceData = (market: string) => {
  igStore.fetchIgPriceData(
    new AccountMeta(selectedAccountId.value, selectedEntity.value, ENTITY_TYPE.DERIVATIVES),
    new Date(fromDate.value).getTime(),
    new Date(toDate.value).getTime() + 1000 * 60 * 60 * 24,
    market,
  ).then((jsonResp) => {
    igStore.setPriceData(JSON.parse(jsonResp) as PriceDataServer);
  }).catch(() => {
    // Nothing more to do
  });
};

const showPosition = (market: string, position: PositionType) => {
  if (!igPriceData.value) {
    getPriceData(market);
    waitOnPriceDataForPosition.value = position;
    return;
  }

  showPositionInternal(position);
};

const showPositionInternal = (position: PositionType) => {
  resetPlayTrades();
  marketPlayState.value.positionsOpen.set(position.id, position);
  setPriceData();
  priceData.value.candleChanges = igPriceData.value.candles;
};

const playTrades = (automated: boolean) => {
  automatedPlaying.value = automated;

  if (marketPlayTimer.value !== 0) {
    return;
  }

  if (marketPlayStep.value === 0) {
    if (!igPriceData.value) {
      getPriceData(selectedMarket.value);
      waitOnPriceDataForActivity.value = -1;
      return;
    }

    setPriceData();
  }

  startPlaying();
};

const reversePlayTrades = (stepBy: PERFORM_STEP = PERFORM_STEP.BY_BOTH) => {
  if (marketPlayStep.value === 0 || !igPriceData.value) {
    return;
  }

  automatedPlaying.value = false;

  let jumpN = 0;

  switch (stepBy) {
  case PERFORM_STEP.BY_ACTIVITY:
    jumpN = marketPlayStep.value - 2;
    break;
  case PERFORM_STEP.BY_CANDLE: {
    const accountActivity = accountActivityByMarket.value.get(selectedMarket.value);
    const activities = accountActivity.items;
    const endTime = priceData.value.candles[igPriceDataIndex.value - 1].startTime;

    for (let i = marketPlayStep.value - 2; i >= 0; --i) {
      const activityTime = activities[i].timestamp;

      if (activityTime <= endTime) {
        jumpN = i;
        break;
      }
    }
    break;
  }
  case PERFORM_STEP.BY_BOTH: {
    const accountActivity = accountActivityByMarket.value.get(selectedMarket.value);
    const activities = accountActivity.items;
    const activityTime = activities[marketPlayStep.value - 1].timestamp;
    const endTime = Math.min(activityTime, priceData.value.candles[igPriceDataIndex.value - 1].startTime);

    for (let i = marketPlayStep.value - 2; i >= 0; --i) {
      const activityTime = activities[i].timestamp;

      if (activityTime <= endTime) {
        jumpN = i;
        break;
      }
    }
    break;
  }
  }

  selectActivity(jumpN);
};

const pauseTrades = () => {
  clearInterval(marketPlayTimer.value);
  marketPlayTimer.value = 0;
  automatedPlaying.value = false;
};

const resetPlayTrades = (resetPriceData = true) => {
  pauseTrades();
  marketPlayStep.value = 0;
  marketPlayState.value = new IgMarketState();
  marketPlayStates.value.set(selectedMarket.value, marketPlayState.value);
  automatedPlaying.value = false;

  if (resetPriceData) {
    igPriceDataIndex.value = 0;
    setPriceData();
  }
};

const resetAccountActivity = () => {
  accountActivityByMarket.value.clear();
  totalPnL.value = 0;
  marketPlayStep.value = 0;
  marketPlayPnLs.value.clear();
  markets.value.clear();
};

const selectActivity = (jumpN: number) => {
  if (jumpN === marketPlayStep.value - 1) {
    return;
  }

  if (!igPriceData.value) {
    getPriceData(selectedMarket.value);
    waitOnPriceDataForActivity.value = jumpN;
    return;
  }

  const reversing = (jumpN < marketPlayStep.value - 1);
  const accountActivity = accountActivityByMarket.value.get(selectedMarket.value);
  const activities = accountActivity.items;
  const activityTime = activities[jumpN].timestamp;
  let candlesToPop = 0;
  let newIgPriceDataIndex = 0;

  if (reversing) {
    for (; newIgPriceDataIndex < priceData.value.candles.length; ++newIgPriceDataIndex) {
      if (priceData.value.candles[newIgPriceDataIndex].startTime > activityTime) {
        break;
      }
    }

    candlesToPop = igPriceDataIndex.value - newIgPriceDataIndex;
    resetPlayTrades(false);
  }

  while (marketPlayStep.value <= jumpN) {
    performStep(false, false, PERFORM_STEP.BY_ACTIVITY);
  }

  if (reversing) {
    igPriceDataIndex.value = newIgPriceDataIndex;

    for (; candlesToPop > 0; --candlesToPop) {
      chartBus.value.emit('action', 'popCandle');
    }
  }
};

const startPlaying = (jumpN = -1) => {
  performStep(false, false, jumpN === -1 ? PERFORM_STEP.BY_BOTH : PERFORM_STEP.BY_ACTIVITY);

  if (automatedPlaying.value) {
    marketPlayTimer.value = window.setInterval(
      () => performStep(false, false, PERFORM_STEP.BY_BOTH),
      speedInMilliseconds.value,
    );
  }
};

const setPriceData = () => {
  chartBus.value.emit('action', 'resetChart');

  let tickerName = '';
  let timeframe = 0;

  if (igPriceData.value) {
    tickerName = igPriceData.value.ticker;
    timeframe = igPriceData.value.timeframe;
  }

  priceData.value = new PriceData(tickerName, timeframe, 0, 0, []);
};

const performStep = (buildState: boolean, notifications: boolean, stepBy: PERFORM_STEP): boolean => {
  if (buildState && stepBy !== PERFORM_STEP.BY_ACTIVITY) {
    clientLogsStore.errorLog(
      '[IG] When building state, steps must be performed by activity (price data may not yet be available)',
    );
    return false;
  }

  const activities = accountActivityByMarket.value.get(selectedMarket.value).items;

  if (marketPlayStep.value === activities.length) {
    if (!buildState) {
      // Finish by putting the rest of the candles on the chart
      for (; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value) {
        priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      }

      pauseTrades();
    }

    return false;
  }

  // Debug data
  // const igPriceDataIndexOld = igPriceDataIndex.value;
  // const candleChangesLength = priceData.value.candleChanges.length;
  // const stepOld = marketPlayStep.value;

  let activityTime: number;
  let stepEndTime: number;
  let marketPlayStateNew = new IgMarketState();

  switch (stepBy) {
  case PERFORM_STEP.BY_ACTIVITY:
    // Move forward 1 step in activities, including any relevant candles
    activityTime = activities[marketPlayStep.value].timestamp;
    stepEndTime = activityTime;

    if (!buildState) {
      for (; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value) {
        if (igPriceData.value.candles[igPriceDataIndex.value].startTime > activityTime) {
          break;
        }

        priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      }
    }
    break;
  case PERFORM_STEP.BY_CANDLE:
    // Move forward 1 step in candles, including any relevant activities
    priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
    stepEndTime = igPriceData.value.candles[igPriceDataIndex.value].endTime;
    ++igPriceDataIndex.value;
    break;
  case PERFORM_STEP.BY_BOTH:
    // Move forward at least 1 step in activities and at least one step in candles
    activityTime = activities[marketPlayStep.value].timestamp;
    stepEndTime = Math.max(activityTime, igPriceData.value.candles[igPriceDataIndex.value].endTime);
    priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);

    for (
      ++igPriceDataIndex.value; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value
    ) {
      if (igPriceData.value.candles[igPriceDataIndex.value].startTime > activityTime) {
        break;
      }

      priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      stepEndTime = igPriceData.value.candles[igPriceDataIndex.value].endTime;
    }

    break;
  }

  if (marketPlayStep.value === 0) {
    marketPlayStates.value.set(selectedMarket.value, marketPlayStateNew);
  } else {
    marketPlayStateNew = marketPlayStates.value.get(selectedMarket.value) as IgMarketState;
  }

  marketPlayState.value = marketPlayStateNew;

  // Perform step by applying it to the marketPlayState

  // TODO: may need to have a map of entityId to orderId, then index orderId into ordersOpen/Closed

  for (; marketPlayStep.value < activities.length; ++marketPlayStep.value) {
    const activity = activities[marketPlayStep.value];
    const activityTime = activity.timestamp;

    if (activityTime > stepEndTime) {
      break;
    }

    switch (activity.category) {
    case 'ACCOUNT_ACTIVITY_ORDER_NEW':
      marketPlayStateNew.ordersOpen.set(activity.toOrder.id, activity.toOrder);
      break;
    case 'ACCOUNT_ACTIVITY_ORDER_UPDATE':
      marketPlayStateNew.ordersOpen.set(activity.toOrder.id, activity.toOrder);
      break;
    case 'ACCOUNT_ACTIVITY_ORDER_DELETE':
      marketPlayStateNew.ordersClosed.set(activity.toOrder.id, activity.toOrder);
      marketPlayStateNew.ordersOpen.delete(activity.toOrder.id);
      break;
    case 'ACCOUNT_ACTIVITY_ORDER_PARTIALLY_FILLED':
      marketPlayStateNew.ordersOpen.set(activity.toOrder.id, activity.toOrder);
      break;
    case 'ACCOUNT_ACTIVITY_ORDER_FILLED':
      marketPlayStateNew.ordersClosed.set(activity.toOrder.id, activity.toOrder);
      marketPlayStateNew.ordersOpen.delete(activity.toOrder.id);
      break;
    case 'ACCOUNT_ACTIVITY_POSITION_NEW':
      marketPlayStateNew.positionsOpen.set(activity.toPosition.id, activity.toPosition);
      break;
    case 'ACCOUNT_ACTIVITY_POSITION_UPDATE':
      marketPlayStateNew.positionsOpen.set(activity.toPosition.id, activity.toPosition);
      break;
    case 'ACCOUNT_ACTIVITY_POSITION_CLOSE':
      marketPlayStateNew.positionsClosed.set(activity.toPosition.id, activity.toPosition);
      marketPlayStateNew.positionsOpen.delete(activity.toPosition.id);
      break;
    case 'ACCOUNT_ACTIVITY_DEPOSIT':
      //
      break;
    case 'ACCOUNT_ACTIVITY_WITHDRAWAL':
      //
      break;
    default:
      clientLogsStore.errorLog(`[IG] Unknown activity category '${activity.category}'`);
    }

    if (notifications) {
      notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.SUCCESS, activity.summary));
    }

    if (stepBy === PERFORM_STEP.BY_ACTIVITY) {
      // For the edge case of multiple activities in the same second
      ++marketPlayStep.value;
      break;
    }
  }

  // Debug data
  // if (!buildState) {
  //   console.log(
  //     `[IG] Step: igPriceDataIndex: ${igPriceDataIndexOld} => ${igPriceDataIndex.value}, ` +
  //     `candleChangesLength: ${candleChangesLength} => ${priceData.value.candleChanges.length}, ` +
  //     `marketPlayStep: ${stepOld} => ${marketPlayStep.value}`,
  //   );
  // }

  if (marketPlayStep.value === activities.length) {
    if (!buildState) {
      pauseTrades();
    }

    return false;
  }

  return true;
};

const getDate = () => {
  const date = new Date();
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};
</script>

<style></style>
