import { formatNumber, tidyNumber } from '@/utilities';
import { ORDER_TYPE, ActiveAction } from '@/types/orders';
import { SIDE } from '@/types/account';
import { InstrumentType } from '@/types/instruments';
import { ENTITY_TYPE } from './exchange';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useUserSettingsStore } from '@/stores/user/settings';

export enum POSITION_STATUS {
  OPEN = 'Open',
  CLOSED = 'Closed',
}

export type calculatePnLInternalFunction =
  ((fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType) => string);

export type PositionTypeKeys = keyof PositionType;

export class PositionType {
  public id: string;
  public symbol: string;
  public created: number;
  public updated: number;
  public price: string; // TODO: make this `entries: string[]`
  public stopLossPrice: string; // TODO: make this `stopLosses: string[]`
  public stopLossPoints: string; // TODO: make this `stopLosses: string[]`
  public profitTargetPrice: string; // TODO: make this `profitTargets: string[]`
  public profitTargetPoints: string; // TODO: make this `profitTargets: string[]`
  public quantity: string;
  public margin: string;
  public marginAsset: string;
  public side: SIDE;
  public status: POSITION_STATUS;
  public fills: FillType[] = [];
  public leverage: string;
  public liquidation: string;
  public calculatePnLInternal: null | calculatePnLInternalFunction;
  public dpFormat = 2;
  public activeAction: ActiveAction;

  // IG properties
  public pnl: string = '0'; // Should be with other properties eventually

  // Auxiliary properties for presentation
  public entityName: string = '';
  public entityType: string = '';
  public accountId: string;
  // For calculating the initial risk when entering the trade
  public initialPrice: string;
  public initialQuantity: string;
  public initialStopLossPrice: string;
  public initialStopLossPoints: string;
  public initialProfitTargetPrice: string;
  public initialProfitTargetPoints: string;

  constructor(id: string) {
    this.id = id;
    this.activeAction = new ActiveAction();
  }

  public setPrice(price: string): void {
    if (!this.initialPrice) {
      this.initialPrice = tidyNumber(price);
    }

    this.price = tidyNumber(price);
  }

  public setStopLossPrice(stopLossPrice: string): void {
    if (!this.initialStopLossPrice) {
      this.initialStopLossPrice = tidyNumber(stopLossPrice);
    }

    this.stopLossPrice = tidyNumber(stopLossPrice);
  }

  public setStopLossPoints(stopLossPoints: string): void {
    if (!this.initialProfitTargetPoints) {
      this.initialStopLossPoints = tidyNumber(stopLossPoints);
    }

    this.stopLossPoints = tidyNumber(stopLossPoints);
  }

  public setProfitTargetPrice(profitTargetPrice: string): void {
    if (!this.initialProfitTargetPrice) {
      this.initialProfitTargetPrice = tidyNumber(profitTargetPrice);
    }

    this.profitTargetPrice = tidyNumber(profitTargetPrice);
  }

  public setProfitTargetPoints(profitTargetPoints: string): void {
    if (!this.initialProfitTargetPoints) {
      this.initialProfitTargetPoints = tidyNumber(profitTargetPoints);
    }

    this.profitTargetPoints = tidyNumber(profitTargetPoints);
  }

  public setQuantity(quantity: string): void {
    if (!this.initialQuantity) {
      this.initialQuantity = quantity;
    }

    this.quantity = quantity;
  }

  public getStopLossPrice(): string {
    if (this.stopLossPrice) {
      return this.stopLossPrice;
    }

    if (!this.stopLossPoints) {
      return '';
    }

    if (this.side === SIDE.SELL) {
      return String(Number(this.price) + Number(this.stopLossPoints));
    }

    return String(Number(this.price) - Number(this.stopLossPoints));
  }

  public getProfitTargetPrice(): string {
    if (this.profitTargetPrice) {
      return this.profitTargetPrice;
    }

    if (!this.profitTargetPoints) {
      return '';
    }

    if (this.side === SIDE.SELL) {
      return String(Number(this.price) - Number(this.profitTargetPoints));
    }

    return String(Number(this.price) + Number(this.profitTargetPoints));
  }

  public ToString(): string {
    const pnl = useUserSettingsStore().getChosenAssetSymbolWithAmount(formatNumber(this.pnl));

    return `${this.side} ${this.quantity} @ ${this.price} ` +
      `(stop = ${this.stopLossPrice} (${this.stopLossPoints}), ` +
      `target = ${this.profitTargetPrice}, (${this.profitTargetPoints})` +
      `status = ${this.status}, PnL = ${pnl})`;
  }

  public addFill(fill: FillType): void {
    this.fills.push(fill);
  }

  public calculatePnL(fromPrice: string, toPrice: string, quantity: string): string {
    if (this.calculatePnLInternal === null || fromPrice === '' || toPrice === '' || quantity === '') {
      return 'NaN'; // Not implemented value
    }

    const instrument = useInstrumentsStore().getInstrument(
      this.entityName, this.entityType as ENTITY_TYPE, this.symbol,
    );

    if (instrument === null) {
      return 'NaN';
    }

    const pnl = this.calculatePnLInternal(fromPrice, toPrice, quantity, this.side, instrument);
    let currency = '';

    if (this.entityType === ENTITY_TYPE.DERIVATIVES) {
      currency = instrument.marginAsset;
    } else {
      currency = instrument.quoteAsset;
    }

    return String(useUserSettingsStore().getChosenAssetValue(this.entityName, currency, +pnl, false));
  }

  public clone(): PositionType {
    // Object.create() only does a shallow clone
    const positionClone = Object.assign(new PositionType(this.id), this as PositionType);

    positionClone.activeAction = Object.assign(new ActiveAction(), this.activeAction);
    positionClone.fills = [];

    for (const fill of this.fills) {
      positionClone.fills.push(Object.assign(new FillType(), fill));
    }

    return positionClone;
  }
}

export interface PositionTypeServer {
  id: string;
  symbol: string;
  created: number;
  updated: number;
  quantity: string;
  margin: string;
  marginAsset: string;
  side: string;
  price: string;
  stopLossPrice: string;
  stopLossPoints: string;
  profitTargetPrice: string;
  profitTargetPoints: string;
  status: string;
  leverage: string;
  liquidation: string
  fills: FillTypeServer[];
  entityType: string;
  activeAction: ActiveAction;
  lock: number;
}

export interface FillTypeServer {
  id: string;
  quantity: string;
  side: string;
  created: number;
  price: string;
  type: string;
  fee: string;
}

export class FillType {
  public quantity: string;
  public created: number;
  public price: string;
  public type: ORDER_TYPE;
  public fee: string;
  public pnl: string;
  public side: SIDE;
}

export interface PositionsState {
  entities: Record<string, { accounts: Record<string, { positions: Record<string, PositionType> }> }>
  positions: Map<string, PositionType>;
}

export interface PositionServerResp {
  entityName: string;
  accountId: string;
  position: PositionTypeServer;
}

export interface PositionsServerResp {
  entityName: string;
  accountId: string;
  positions: Record<string, PositionTypeServer>;
}
