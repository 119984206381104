import { tidyNumber } from '@/utilities';
import { SIDE } from '@/types/account';
import { InstrumentType } from '@/types/instruments';
import { ENTITY_TYPE } from './exchange';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useUserSettingsStore } from '@/stores/user/settings';

export enum ORDER_TYPE {
  LIMIT = 'Limit',
  MARKET = 'Market',
  STOP_LIMIT = 'StopLimit',
  STOP_MARKET = 'StopMarket',
  UNKNOWN = 'Unknown',
}

export enum ORDER_CATEGORY {
  NONE = '',
  LIQUIDITY = 'Liquidity',
  TRADING = 'Trading',
  INVESTMENT = 'Investment',
  PLAIN = 'Plain',
}

export enum ORDER_STATUS {
  UNFILLED = 'UnfilledOrder',
  PARTIALLY_FILLED = 'PartiallyFilledOrder',
  FILLED = 'FilledOrder',
  CANCELLED = 'CancelledOrder',
  FAILED = 'failed_order', // TODO: server uses FAILED_ORDER rather than FailedOrder /o\
  EXPIRED = 'ExpiredOrder',
  UNKNOWN = 'UnknownStatusOrder',
  INACTIVE_UNKNOWN = 'InactiveUnknownStatusOrder',
}

export enum ORDER_TIME_IN_FORCE {
  GTC = -1
}

export const orderCategories = [
  ORDER_CATEGORY.PLAIN, ORDER_CATEGORY.LIQUIDITY, ORDER_CATEGORY.TRADING, ORDER_CATEGORY.INVESTMENT,
];

export interface UpdateLeverage {
  accountMeta: AccountMeta;
  symbol: string;
  leverage: string;
}

export interface UpdateLeverageData {
  leverageData: UpdateLeverage;
  userToken: string;
}

export class AccountMeta {
  accountId: string;
  entityName: string;
  entityType: ENTITY_TYPE;

  constructor(accountId: string, entityName: string, entityType: ENTITY_TYPE) {
    this.accountId = accountId;
    this.entityName = entityName;
    this.entityType = entityType;
  }
}

export class OrderFlags {
  public timeInForce: number;
  public postOnly: boolean;
  public reduceOnly: boolean;
  public hidden: boolean;

  constructor(
    timeInForce: number = ORDER_TIME_IN_FORCE.GTC,
    postOnly: boolean = true,
    reduceOnly: boolean = false,
    hidden: boolean = false,
  ) {
    this.timeInForce = timeInForce;
    this.postOnly = postOnly;
    this.reduceOnly = reduceOnly;
    this.hidden = hidden;
  }
}

export type OrderTypeKeys = keyof OrderType;

export class ActiveAction {
  public lockTime: number = 0;
  public operation: string = '';

  public lock(operation: string): void {
    this.lockTime = Date.now();
    this.operation = operation;
  }

  public unlock(): void {
    this.lockTime = 0;
    this.operation = '';
  }

  public isLocked(): boolean {
    return this.lockTime !== 0;
  }
}

export class OrderType {
  public id: string = '';
  public entityId: string = '';
  public clientId: string = '';
  public symbol: string = '';
  public created: number = 0; // Time of creation (milliseconds timestamp)
  public updated: number = 0; // Last updated (milliseconds timestamp)
  public quantity: string = '0';
  public filledQuantity: string = '0';
  public displayQuantity: string = '0';
  public margin: string = '0';
  public marginAsset: string = '';
  public price: string = '';
  public stopLossPrice: string = '';
  public stopLossPoints: string = '';
  public profitTargetPrice: string = '';
  public profitTargetPoints: string = '';
  public leverage: string = '';
  public flags: OrderFlags = null;
  public status: ORDER_STATUS;
  public side: SIDE = SIDE.UNKNOWN;
  public type: ORDER_TYPE;
  public category: ORDER_CATEGORY;
  public activeAction: ActiveAction;
  public strategyId: string = '';
  public entityName: string;
  public entityType: ENTITY_TYPE;

  // Auxiliary properties for presentation
  public calculatePnLInternal:
    null | ((fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType) => string);

  constructor(id: string = '') {
    this.id = id;
  }

  public ToString(): string {
    return `${this.side} ${this.quantity} @ ${this.price} (stop = '${this.stopLossPrice}' (${this.stopLossPoints}), ` +
      `target = '${this.profitTargetPrice}' (${this.profitTargetPoints}), status = '${this.status}', ` +
      `id = '${this.id}')`;
  }

  public calculatePnL(fromPrice: string, toPrice: string, quantity: string): string {
    if (this.calculatePnLInternal === null || fromPrice === '' || toPrice === '' || quantity === '') {
      return 'NaN'; // Not implemented value
    }

    const instrument = useInstrumentsStore().getInstrument(this.entityName, this.entityType, this.symbol);

    if (instrument === null) {
      return 'NaN';
    }

    const pnl = this.calculatePnLInternal(fromPrice, toPrice, quantity, this.side, instrument);
    let currency = '';

    if (this.entityType === ENTITY_TYPE.DERIVATIVES) {
      currency = instrument.marginAsset;
    } else {
      currency = instrument.quoteAsset;
    }

    return String(useUserSettingsStore().getChosenAssetValue(this.entityName, currency, +pnl, false));
  }

  public clone(): OrderType {
    // Object.create() only does a shallow clone
    const orderClone = Object.assign(new OrderType(this.id), this as OrderType);

    orderClone.activeAction = Object.assign(new ActiveAction(), this.activeAction);
    orderClone.flags = Object.assign(new OrderFlags(), orderClone.flags);

    return orderClone;
  }

  public setPrice(price: string): void {
    this.price = tidyNumber(price);
  }

  public setStopLossPrice(stopLossPrice: string): void {
    this.stopLossPrice = tidyNumber(stopLossPrice);
  }

  public setStopLossPoints(stopLossPoints: string): void {
    this.stopLossPoints = tidyNumber(stopLossPoints);
  }

  public setProfitTargetPrice(profitTargetPrice: string): void {
    this.profitTargetPrice = tidyNumber(profitTargetPrice);
  }

  public setProfitTargetPoints(profitTargetPoints: string): void {
    this.profitTargetPoints = tidyNumber(profitTargetPoints);
  }

  public getStopLossPrice(): string {
    if (this.stopLossPrice) {
      return this.stopLossPrice;
    }

    if (!this.stopLossPoints) {
      return '';
    }

    if (this.side === SIDE.SELL) {
      return String(Number(this.price) + Number(this.stopLossPoints));
    }

    return String(Number(this.price) - Number(this.stopLossPoints));
  }

  public getProfitTargetPrice(): string {
    if (this.profitTargetPrice) {
      return this.profitTargetPrice;
    }

    if (!this.profitTargetPoints) {
      return '';
    }

    if (this.side === SIDE.SELL) {
      return String(Number(this.price) - Number(this.profitTargetPoints));
    }

    return String(Number(this.price) + Number(this.profitTargetPoints));
  }
}

interface OrderFlagsServer {
  timeInForce: number
  postOnly: boolean
  reduceOnly: boolean
  hidden: boolean
}

export interface OrdersState {
  entities: Record<string, { accounts: Record<string, { orders: Record<string, OrderType> }> }>;
  orders: Map<string, OrderType>;
}

export interface OrdersServerResp {
  entityName: string;
  accountId: string;
  orders: Record<string, OrderTypeServer>;
}

export interface OrderServerResp {
  entityName: string;
  accountId: string;
  order: OrderTypeServer;
}

export interface OrderTypeServer {
  id: string;
  entityId: string;
  clientId: string;
  symbol: string;
  created: number;
  updated: number;
  quantity: string;
  filledQuantity: string;
  displayQuantity: string;
  margin: string;
  marginAsset: string;
  price: string;
  stopLossPrice: string;
  stopLossPoints: string;
  profitTargetPrice: string;
  profitTargetPoints: string;
  leverage: string;
  flags: OrderFlagsServer;
  status: string;
  side: string;
  type: string;
  entityType: string;
  category: string;
  activeAction: ActiveAction;
  strategyId: string;
}
