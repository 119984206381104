<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title"> {{ props.title }} - {{ positionsView?.length }}</h3>
      <div class="panel__header__button panel__header__button--last">
        <router-link v-if="Object.keys($route.query).length > 0" class="button" :to="{ path: $route.fullPath }">
          Clear Filters
        </router-link>
      </div>
      <i
        :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }"
        class="panel__header__icon" @click="collasped = !collasped"
      />
    </div>

    <div v-if="!collasped" class="panel__body vtable">
      <div class="vtable-header hand" style="padding-left: 4px;" @click="updateTableData($event)">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--8" name="entityName">
          <select v-model="entityNameFilter" name="entityNameFilter" class="vtable-header__item__input">
            <option value="">EX. NAME</option>
            <option value="" disabled>--</option>
            <option v-for="entityName in orderedEntityNames" :key="entityName">{{ entityName }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-37 vtable__size--10">
          <div class="vtable-header__input-wrap">
            <input
              v-model="symbolFilterInputFieldOnly" class="vtable-header__item__input-wrap__input"
              type="text" placeholder="SYMBOL NAME" autocomplete="off" @input="debouncedSymbolSearch($event)"
            />
            <i class="ti-arrow-down sort-icon" />
          </div>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--12" name="price" title="Execution Price">
          Exec. Price <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="liquidation">
          Liq. Price <i class="ti-arrow-down sort-icon" />
        </div>

        <div class="vtable-header__item vtable__size--m-hide vtable__size--13" name="lastPrice">
          Price: LP (MP) <i class="ti-arrow-down sort-icon" />
        </div>
        <div
          class="vtable-header__item vtable__size--m-hide vtable__size--13"
          name="distanceLP" title="Distance: LP% (MP%)"
        >
          DIST. LP% (MP%) <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--6" name="quantity" title="Quantity">
          Qty. <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--5" name="leverage" title="Leverage">
          Lev. <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--7">
          <select v-model="marginFilter" class="vtable-header__item__input" name="marginFilter">
            <option value="">MARGIN</option>
            <option value="" disabled>--</option>
            <option v-for="asset in assets" :key="asset">{{ asset }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--12" name="created">Entered</div>
        <div
          v-if="props.entityType !== ENTITY_TYPE.SPOT"
          class="vtable-header__item vtable__size--m-30 vtable__size--6"
          name="pNL"
        >
          P&L ({{ choosenAssetSymbol }})
        </div>
        <div class="vtable-header__item vtable__size--m-8 vtable__size--d-hide" />
      </div>
      <DynamicScroller :items="positionsView" :min-item-size="36" class="panel__body__table vtable">
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item" :active="active" :size-dependencies="[item.showExpandedView]" :data-index="index"
          >
            <div
              class="vtable__row"
              :class="item.showExpandedView ?
                'vtable__row--'+item.position.side+ ' vtable__row--overview-active' :
                'vtable__row--'+item.position.side"
              @click="selectPosition($event, item.position.id)"
            >
              <div class="vtable__row__item vtable__size--m-25 vtable__size--8">
                {{ item.position.entityName }} &nbsp;
                <span
                  v-if="item.position.entityType === ENTITY_TYPE.DERIVATIVES"
                  :title="item.position.entityType"
                >
                  (D)
                </span>
                <span v-else :title="item.position.entityType">(S)</span>
              </div>
              <div class="vtable__row__item vtable__size--m-37 vtable__size--10">
                <router-link
                  :title="item.position.symbol"
                  :to="{
                    path: `/exchanges/${item.position.entityName}/${item.position.entityType}/` +
                      `${encodeURIComponent(item.position.symbol)}/${item.position.accountId}`,
                    query: { 'selectedPosition': item.position.id, }
                  }"
                >
                  {{ item.consistentSymbol }}
                </router-link>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--12">
                {{ formatNumber(item.position.price) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ formatNumber(item.position.liquidation) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--13">
                {{ formatNumber(item.lastPrice) }}
                <span v-if="parseFloat(item.markPrice)">&nbsp;({{ formatNumber(item.markPrice) }})</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--13">
                {{ roundNumber(item.distanceLP, 0) }}%
                <span v-if="item.distanceMP">&nbsp;({{ roundNumber(item.distanceMP, 0) }}%)</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--6">
                {{ formatNumber(item.position.quantity) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--5">
                {{ item.leverage }}x
                <div v-if="item.crossMargin" title="Cross leverage!">*</div>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--7">
                {{ formatNumber(item.position.margin) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--12">
                {{ convertUnixTimestampToDate(item.position.created) }}
              </div>
              <div
                v-if="props.entityType !== ENTITY_TYPE.SPOT"
                class="vtable__row__item vtable__size--m-30 vtable__size--6"
                :class="pNLBg(item)"
              >
                {{ useUserSettingsStore().getChosenAssetSymbolWithAmount(formatNumber(item.chosenAssetProfit)) }}
              </div>

              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--d-hide vtable__size--m-8">
                <button class="vtable__row__button" @click="toggleItemView(item)">
                  <i :class="!item.showExpandedView ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
              <div v-if="item.showExpandedView" class="vtable__row__overview">
                <div class="vtable__m-overview">
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Distance: LP% (MP%)</label>
                    <div>
                      {{ roundNumber(item.distanceLP, 0) }}%
                      <span v-if="item.distanceMP">&nbsp;({{ roundNumber(item.distanceMP, 0) }}%)</span>
                    </div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Execution Price</label>
                    <div>{{ formatNumber(item.position.price) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Liquidation Price</label>
                    <div>{{ formatNumber(item.position.liquidation) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Price: LP (MP)</label>
                    <div>
                      {{ formatNumber(item.lastPrice) }}
                      <span v-if="parseFloat(item.markPrice)"> ({{ formatNumber(item.markPrice) }})</span>
                    </div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Quantity</label>
                    <div>{{ formatNumber(item.position.quantity) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Leverage</label>
                    <div>{{ item.leverage }}x</div>
                    <div v-if="item.crossMargin" title="Cross leverage!">*</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Margin</label>
                    <div>{{ formatNumber(item.position.margin) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Entered</label>
                    <div>{{ convertUnixTimestampToDate(item.position.created) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePricesStore } from '@/stores/exchanges/prices';
import { usePositionsStore } from '@/stores/exchanges/positions';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useQueryStringStore } from '@/stores/queryString';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useRoute } from 'vue-router';
import { formatNumber, getDistance, roundNumber } from '@/utilities';
import { ENTITY_TYPE } from '@/types/exchange';
import { PositionType, PositionTypeKeys } from '@/types/positions';

class PositionRowView {
  public id: string; // For the virtual scroller (position ID)
  public position: PositionType;
  public lastPrice: string;
  public markPrice: string;
  public distanceLP: number;
  public distanceMP: number;
  public consistentSymbol: string;
  public leverage: string;
  public crossMargin: boolean = false;
  public chosenAssetProfit: string;
  public showExpandedView: boolean = false;

  constructor(position: PositionType) {
    this.id = position.id;
    this.position = position;
  }
}

// Store
const userSettingsStore = useUserSettingsStore();
const accountManagementStore = useAccountManagementStore();
const instrumentsStore = useInstrumentsStore();
const pricesStore = usePricesStore();
const positionsStore = usePositionsStore();
const queryStringStore = useQueryStringStore();

const route = useRoute();

// Computed
const instruments = computed(() => instrumentsStore.instruments);
const prices = computed(() => pricesStore.prices);
const orderedEntityNames = computed(() => accountManagementStore.getOrderedEntityNames());
const positions = computed(() => {
  const entityName = String(route.params.entityName || '');
  const entityType = String(route.params.entityType || '');
  const accountId = String(route.params.accountId || '');

  return positionsStore.getPositions(entityName, entityType, accountId);
});
const choosenAssetSymbol = computed(() => userSettingsStore.getChosenAssetSymbol);

// Parent properties
const props = withDefaults(defineProps<{
  title: string,
  entityType: string, // Adjust the type as needed for WalletType
}>(), {
  title: '',
  entityType: '',
});

// Variables
const sortByTH = ref<HTMLElement>(null);
const orderBy = ref('desc');
const collasped = ref(false);
const marginFilter = ref(''); // TODO Watch this to update URL params
const entityNameFilter = ref(''); // TODO Watch this to update URL params
const entityTypeFilter = ref('');
const sideFilter = ref('');
const symbolFilter = ref('');
const symbolFilterInputFieldOnly = ref('');
const timeout = ref<number | null>(null);
const assets = ref<string[]>([]);
const sortBy = ref<PositionTypeKeys>('created');
const positionsView = ref<PositionRowView[]>([]);

const emit = defineEmits(['selectedPositions']);

let positionsDataCacheTime = 0;
let positionsDataInterval = 0;
const tableRefreshRateMs = 3000;
let selectedPositions: Record<string, PositionType> = {};

const expandedItems: Record<string, boolean> = {};

// Watchers

watch(entityNameFilter, (val: string) => {
  if (val !== route.query.entityName as string) {
    queryStringStore.update({ 'entityName': val || null });
  }
});

watch(entityTypeFilter, (val: string) => {
  if (val !== route.query.entityType as string) {
    queryStringStore.update({ 'entityType': val || null });
  }
});

watch(() => route.query.entityName ? String(route.query.entityName) : '', (val: string) => {
  entityNameFilter.value = val || '';
  computePositionsData();
}, { immediate: false });

watch(() => route.query.entityType ? String(route.query.entityType) : '', (val: string) => {
  entityTypeFilter.value = val || '';
  computePositionsData();
}, { immediate: false });

watch(() => route.query.symbol ? String(route.query.symbol) : '', (val: string) => {
  if (symbolFilter.value != val) {
    symbolFilter.value = val || '';
    computePositionsData();
  }
}, { immediate: false });

watch(() => route.query.side ? String(route.query.side) : '', (val: string) => {
  sideFilter.value = val || '';
  computePositionsData();
}, { immediate: false });

watch(() => positionsStore.positions.size, () => {
  computePositionsData();
}, { immediate: false });

// Vue Lifecycle Functions
onMounted(() => {
  entityNameFilter.value = route.query.entityName as string || '' ;
  entityTypeFilter.value = route.query.entityType as string || '' ;
  sideFilter.value = route.query.side as string || '' ;
  symbolFilter.value = route.query.symbol as string || '' ;
  symbolFilterInputFieldOnly.value = route.query.symbol as string || '' ;

  computePositionsData();
  positionsDataInterval = window.setInterval(() => computePositionsData(false), tableRefreshRateMs);
});

onUnmounted(() => {
  clearInterval(positionsDataInterval);
});

// Functions
const debouncedSymbolSearch = (event: Event) => {
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = window.setTimeout(() => {
    const target = event.target as HTMLInputElement;
    if (target.value !== route.query.symbol as string) {
      queryStringStore.update({ 'symbol': target.value || '' });
    }
  }, 500);
};

const updateTableData = (e: Event) => {
  let target = e.target as HTMLElement;

  if (target.classList.contains('vtable-header__input-wrap__input')) {
    return;
  }
  if (target.classList.contains('sort-icon')) {
    target = target.parentNode as HTMLElement;
  }
  if (target.classList.contains('vtable-header__input-wrap')) {
    target = target.parentNode as HTMLElement;
  }

  const name = target.getAttribute('name');

  if (!name || name === 'marginFilter') {
    return;
  }
  if (sortByTH.value) {
    sortByTH.value.classList.remove('asc');
    sortByTH.value.classList.add('desc');
  }

  if (name === sortBy.value) {
    // Update orderBy
    const lastOrderBy = orderBy.value;
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';

    if (sortByTH.value) {
      sortByTH.value.classList.remove(lastOrderBy);
      sortByTH.value.classList.add(orderBy.value);
    }
  } else {
    // Update sortBy
    sortBy.value = name as PositionTypeKeys;

    if (sortByTH.value) {
      sortByTH.value.classList.remove('desc');
      sortByTH.value.classList.remove('asc');
    }

    sortByTH.value = target;
    orderBy.value = 'asc';
    sortByTH.value.classList.add('asc');
  }
};

const convertUnixTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hours = date.getHours(),
    minutes = `0${date.getMinutes()}`,
    seconds = `0${date.getSeconds()}`,
    formattedDate = `${day}/${month}/${year} ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

  return formattedDate;
};

const pNLBg = (positionView: PositionRowView): string => {
  if (+positionView.chosenAssetProfit == 0) {
    return 'greyBg0';
  }

  return +positionView.chosenAssetProfit > 0 ? 'greenBg' : 'redBg';
};

const computePositionsData = (hardRefresh = true): void => {
  if (!hardRefresh && new Date().getTime() - positionsDataCacheTime < tableRefreshRateMs) {
    return;
  }

  const newPositionsView: PositionRowView[] = [];

  if (!positions.value) {
    positionsView.value = newPositionsView;
    return;
  }

  if (!prices.value) {
    Object.values(positions.value).forEach((position: PositionType) => {
      newPositionsView.push(new PositionRowView(position));
    });

    positionsView.value = newPositionsView;
    return;
  }

  const entityNamesMap: Record<string, boolean> = {};
  const assetsMap: Record<string, boolean> = {};

  // Combine prices and positions
  for (const positionId in positions.value) {
    const position = positions.value[positionId],
      entityName = position.entityName,
      entityType = position.entityType,
      symbol = position.symbol;

    entityNamesMap[entityName] = true;
    assetsMap[position.marginAsset] = true;

    // TODO: fairly hacky, should not hardcode this
    if (entityType === ENTITY_TYPE.SPOT) {
      const d = new Date();
      const pD = d.getDate() - 7;
      const releaseTs = position.created * 1000;

      d.setDate(pD);

      if (releaseTs < +d) {
        continue;
      }
    }

    if (marginFilter.value !== '') {
      if (position.marginAsset !== marginFilter.value) {
        continue;
      }
    }

    if (entityNameFilter.value !== '') {
      if (entityName !== entityNameFilter.value) {
        continue;
      }
    }
    if (entityTypeFilter.value !== '') {
      if (entityType !== entityTypeFilter.value) {
        continue;
      }
    }

    if (sideFilter.value !== '') {
      if (position.side !== sideFilter.value) {
        continue;
      }
    }

    const positionView = new PositionRowView(position);

    // TODO: check if entityName in $params?
    if (
      entityName in prices.value
        && entityType in prices.value[entityName]
        && symbol in prices.value[entityName][entityType]
    ) {
      // TODO: should clone positionData? Otherwise, the actual position object will be updated
      positionView.lastPrice = prices.value[entityName][entityType][symbol].lastPrice;
      positionView.markPrice = prices.value[entityName][entityType][symbol].markPrice;
      positionView.distanceLP = getDistance(position.price, positionView.lastPrice);
      positionView.distanceMP = getDistance(position.price, positionView.markPrice);
    }

    const instrument = instruments.value?.[entityName]?.[entityType]?.[symbol];

    if (instrument) {
      positionView.consistentSymbol = instrument.consistentSymbol;
    } else {
      // console.log(
      //   `UNKNOWN: state.instruments[${entityName}][${entityType}][${symbol}]`,
      //   this.instruments?.[entityName]?.[entityType],
      // );
      positionView.consistentSymbol = position.symbol;
    }

    if (symbolFilter.value !== '') {
      if (positionView.consistentSymbol.toLowerCase().indexOf(symbolFilter.value.toLowerCase()) === -1) {
        continue;
      }
    }

    if (position.leverage === '0') { // This means the leverage is with the instrument (derivatives only, e.g. Bitmex)
      if (instrument) {
        positionView.leverage = instrument.leverage;
        positionView.crossMargin = instrument.crossMargin;
      } else {
        positionView.leverage = '0';
      }
    } else {
      positionView.leverage = position.leverage;
    }

    positionView.chosenAssetProfit = position.calculatePnL(
      position.price, positionView.lastPrice, position.quantity,
    );

    const key = `${positionView.position.accountId}+${positionView.position.id}`;

    positionView.showExpandedView = expandedItems[key] || false;

    newPositionsView.push(positionView);
  }

  assets.value = Object.keys(assetsMap);
  assets.value.sort((a, b) => a.localeCompare(b));

  // Sort by selected column
  if (orderBy.value === 'asc') {
    newPositionsView.sort(
      (a,b) => (
        a.position[sortBy.value] > b.position[sortBy.value]) ?
        1 : ((b.position[sortBy.value] > a.position[sortBy.value]) ? -1 : 0),
    );
  } else {
    newPositionsView.sort(
      (a,b) => (
        a.position[sortBy.value] < b.position[sortBy.value]) ?
        1 : ((b.position[sortBy.value] < a.position[sortBy.value]) ? -1 : 0),
    );
  }

  positionsView.value = newPositionsView;

  const newSelectedPositions: Record<string, PositionType> = {};

  newPositionsView.forEach(positionView => {
    // Still only filter out positions that are relevant to this pair
    if (positionView.position.entityName !== route.params.entityName ||
        positionView.position.entityType !== route.params.entityType ||
        positionView.position.symbol !== route.params.symbol) {
      return;
    }

    newSelectedPositions[positionView.position.id] = positionView.position;
  });

  let changed = false;

  for (const orderId in newSelectedPositions) {
    if (!(orderId in selectedPositions) || newSelectedPositions[orderId] !== selectedPositions[orderId]) {
      changed = true;
      break;
    }
  }

  if (!changed) {
    for (const orderId in selectedPositions) {
      if (!(orderId in newSelectedPositions)) {
        changed = true;
        break;
      }
    }
  }

  if (changed) {
    selectedPositions = newSelectedPositions;
    emit('selectedPositions', selectedPositions);
  }

  positionsDataCacheTime = new Date().getTime();
};

const selectPosition = (event: MouseEvent, positionId: string) => {
  if ((event.target as HTMLElement).tagName === 'A') {
    // It means a link was clicked, so ignore selecting the order here (it will be done in the link href instead).
    // This is required because otherwise, the click event may override the nested anchor element. It will also
    // have two browser history entries.
    return;
  }

  const selectedPosition = route.query.selectedPosition as string || '';

  if (!selectedPosition || selectedPosition != positionId) {
    queryStringStore.update({
      'selectedPosition': positionId,
      'selectedOrder': '',
    });
  } else {
    queryStringStore.update({ 'selectedPosition': '' });
  }
};

const toggleItemView = (positionView: PositionRowView): void => {
  positionView.showExpandedView = !positionView.showExpandedView;

  const key = `${positionView.position.accountId}+${positionView.position.id}`;

  if (positionView.showExpandedView) {
    expandedItems[key] = true;
  } else {
    delete expandedItems[key];
  }
};
</script>

<style lang="scss" scoped></style>
