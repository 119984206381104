import { defineStore } from 'pinia';
import { SequencesStateType, SequencesServerResponse } from '@/types/arbitrage';

export const useArbitrageStore = defineStore('arbitrage', {
  state: (): SequencesStateType => ({
    sequences: {
      spotSameEntity: {},
    },
  }),
  actions: {
    setSequences(body: SequencesServerResponse) {
      this.sequences = body.sequences;
    },
  },
});
