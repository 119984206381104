import { defineStore } from 'pinia';
import { parsePriceData } from '@/stores/exchanges/pricedata';
import {
  TradingviewSymbolsServerResp, TradingviewState, SymbolType, TradingviewSymbolDataServerResp,
  TradingviewSymbolDataFatFingerServerResp,
} from '@/types/tradingview';

export const useTradingviewStore = defineStore('tradingview', {
  state: (): TradingviewState => ({
    symbols: new Map(),
    symbolData: null,
  }),
  actions: {
    setSymbols({ symbols, page }: TradingviewSymbolsServerResp) {
      if (page === 0) {
        this.symbols = new Map();
      }

      for (const symbol of symbols) {
        const newSymbol = new SymbolType(symbol.symbol, symbol.entityName, symbol.type);
        newSymbol.selected = true;
        this.symbols.set(`${newSymbol.entityName}:${newSymbol.symbol}`, newSymbol);
      }
    },
    setSymbolData({ priceData }: TradingviewSymbolDataServerResp) {
      this.symbolData = parsePriceData(priceData);
    },
    setSymbolDataFatFinger(resp: TradingviewSymbolDataFatFingerServerResp) {
      if (!this.symbols.has(resp.symbol)) {
        return;
      }

      const symbol = this.symbols.get(resp.symbol);

      symbol.fatFingerAnlaysis = true;

      if (resp.error) {
        symbol.fatFingerErrorInfo = resp.error;
      } else {
        symbol.fatFingerInfo = resp.fatFingers;
      }
    },
  },
});
