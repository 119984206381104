<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }" @click="collasped = !collasped">
      <p v-if="title" class="panel__header__title">{{ title }}</p>

      <p v-else class="panel__header__title">
        <span v-for="(key, i) in wallet.aggregationKeys" :key="i">
          {{ Object.values(wallet.aggregationKeys[i])[0] }}
          <span v-if="wallet.aggregationKeys.length - 1 > i" class="panel__header__title__spacer"> | </span>
        </span>
      </p>

      <div class="panel__header__button panel__header__button--last" />
      <i :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }" class="panel__header__icon" />
    </div>

    <table v-if="!collasped" class="panel__body table">
      <thead class="table__header">
        <tr @click="updateTableData($event)">
          <th class="" name="asset">
            {{ wallet.tableHeading }}
          </th>
          <th v-if="assetAggr" class="" name="available">
            Available
          </th>
          <th class=" highlight" name="chosenAssetAvailable">
            Available ({{ settingsStore.getChosenAssetSymbol }})
          </th>
          <th v-if="assetAggr" class="" name="total">
            Total
          </th>
          <th class=" highlight" name="chosenAssetTotal">
            Total ({{ settingsStore.getChosenAssetSymbol }})
          </th>
        </tr>
      </thead>
      <tbody class="table__body">
        <tr v-for="(data, asset) in walletsData" :key="asset">
          <td class="">
            {{ data.asset }}
          </td>
          <td v-if="assetAggr" class="">
            {{ formatNumber(data.available) }}
          </td>
          <td class=" highlight">
            {{ formatNumber(data.chosenAssetAvailable) }}
          </td>
          <td v-if="assetAggr" class="">
            {{ formatNumber(data.total) }}
          </td>
          <td class=" highlight">
            {{ formatNumber(data.chosenAssetTotal) }}
          </td>
        </tr>
      </tbody>
      <tfoot class="table__footer">
        <tr>
          <td colspan="2" />
          <td>
            {{ formatNumber(String(props.wallet.chosenAssetAvailable)) }}
          </td>
          <td colspan="1" />
          <td>
            {{ formatNumber(String(props.wallet.chosenAssetTotal)) }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup lang="ts">
import { formatNumber } from '@/utilities';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useUserSettingsStore } from '@/stores/user/settings';
import { ref, computed } from 'vue';
import { WalletType, AssetBalanceType, AssetBalanceKeys } from '@/types/wallets';

// Store
const settingsStore = useUserSettingsStore();
const accountManagementStore = useAccountManagementStore();

// Parent Properties
const props = withDefaults(defineProps<{
  wallet: WalletType, // Adjust the type as needed for WalletType
  minBalance: number,
  minAvailableBalance: number,
  assetAggr: boolean,
  title: string
}>(), {
  wallet: () => new WalletType('', '', {}, '', ''),
  minBalance: 0,
  minAvailableBalance: 0,
  assetAggr: false,
  title: '',
});

// Computed variables
const walletsData = computed(() => {
  const data: AssetBalanceType[] = [];

  for (const asset in props.wallet.balances) {
    const balance = props.wallet.balances[asset];
    const chosenAssetAvailable = parseFloat(balance.chosenAssetAvailable);
    const total = parseFloat(balance.total);
    const chosenAssetTotal = parseFloat(balance.chosenAssetTotal);

    if (
      chosenAssetTotal < props.minBalance ||
      chosenAssetAvailable < (props.minAvailableBalance || 0)
    ) {
      continue;
    }

    const available = parseFloat(balance.available);

    const assetName = props.wallet.tableHeading === 'Accounts'
      ? accountManagementStore.getAccount(props.wallet.entityName, asset)?.name
      : asset;

    const availableRounded = String(settingsStore.roundValueForAsset(asset, available));
    const totalRounded = String(settingsStore.roundValueForAsset(asset, total));

    const assetBalanceType = new AssetBalanceType(availableRounded, totalRounded);
    assetBalanceType.asset = assetName;
    assetBalanceType.chosenAssetAvailable = String(settingsStore.roundValueForChosenAsset(chosenAssetAvailable));
    assetBalanceType.chosenAssetTotal = String(settingsStore.roundValueForChosenAsset(chosenAssetTotal));

    data.push(assetBalanceType);
  }

  // Sort by selected column
  if (orderBy.value === 'asc') {
    data.sort((a,b) => (a[sortBy.value] > b[sortBy.value]) ? 1 : ((b[sortBy.value] > a[sortBy.value]) ? -1 : 0));
  } else {
    data.sort((a,b) => (a[sortBy.value] < b[sortBy.value]) ? 1 : ((b[sortBy.value] < a[sortBy.value]) ? -1 : 0));
  }

  return data;
});

// Variables
const sortBy = ref<AssetBalanceKeys>('asset');
const orderBy = ref('asc');
const collasped = ref(false);

// Functions
const updateTableData = (e: Event) => {
  const target = e.target as HTMLElement;
  const name = target.getAttribute('name') as AssetBalanceKeys;

  if (name === sortBy.value) {
    // Update orderBy
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';
  } else {
    // Update sortBy
    sortBy.value = name;
  }
};
</script>

<style lang="scss" scoped>

</style>
