export class PriceType {
  public symbol: string;
  public bid: string;
  public ask: string;
  public lastPrice: string;
  public markPrice: string;
  public baseVolume: string;
  public quoteVolume: string;
  public entityType: string;

  constructor(
    symbol: string,
    bid: string,
    ask: string,
    lastPrice: string,
    markPrice: string,
    baseVolume: string,
    quoteVolume: string,
    entityType: string,
  ) {
    this.symbol = symbol;
    this.bid = bid;
    this.ask = ask;
    this.lastPrice = lastPrice;
    this.markPrice = markPrice;
    this.baseVolume = baseVolume;
    this.quoteVolume = quoteVolume;
    this.entityType = entityType;
  }
}

export interface PricesState {
  prices: Record<string, Record<string, Record<string, PriceType>>>
}

export interface PriceTypeServer {
  symbol: string;
  bid: string;
  ask: string;
  lastPrice: string;
  markPrice: string;
  baseVolume: string;
  quoteVolume: string;
  entityType: string;
}

export interface PricesServerResp {
  entityName: string;
  entityType: string;
  prices: Record<string, PriceTypeServer>
}
