<template>
  <div>
    <div v-if="localNotifications" id="notificationElement" class="notifications" @click="toggleDrawer()">
      <div class="notifications__inner">
        <template v-for="[i, nw] in localNotifications" :key="i">
          <div
            v-if="nw"
            :class="nw.displayClass + ' notifications__notification__item-- ' + nw.notification.type"
            class="notifications__notification"
          >
            <p class="text-current">
              <template v-if="nw.notification.entityName">[{{ nw.notification.entityName }}]</template>
              {{ nw.notification.message }}
            </p>
            <p class="text-current" style="font-size: .75em; margin: -8px 8px 3px 8px; font-style: italic;">
              {{ new Date(nw.notification.timestamp).toISOString() }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { NotificationType, NotificationWrapper } from '@/types/user';
import { useNotificationsStore } from '@/stores/user/notifications';

// Stores
const notificationsStore = useNotificationsStore();

const NOTIFICATION_SHOW_TIME = 3000; // 3 seconds

// Computed properties
const notifications = computed(() => notificationsStore.getNotifications);

// Reactive state
const localNotifications = ref(new Map<number, NotificationWrapper>());
const localNotificationsMap = ref(new Map<NotificationType, boolean>());
const hideTimer: Record<number, ReturnType<typeof setTimeout>> = {};
let lastId = 0;

// Watcher for notifications.length
watch(() => notifications.value.length, () => {
  const maxAge = Date.now() - NOTIFICATION_SHOW_TIME;

  while (lastId < notifications.value.length) {
    const notification = notifications.value[lastId];

    if (notification.timestamp >= maxAge && !localNotificationsMap.value.has(notification)) {
      hideTimer[lastId] = addNotification(notification, lastId);
    }

    ++lastId;
  }
});

// Methods
function toggleDrawer() {
  notificationsStore.toggleDrawerOpen();
}

function addNotification(notification: NotificationType, id: number): ReturnType<typeof setTimeout> {
  const notificationWrapper = new NotificationWrapper(notification, 'show');
  localNotifications.value.set(id, notificationWrapper);
  localNotificationsMap.value.set(notification, true);

  return setTimeout(() => hideNotifications(id), NOTIFICATION_SHOW_TIME);
}

function hideNotifications(id: number): void {
  clearTimeout(hideTimer[id]);

  const nw = localNotifications.value.get(id);
  if (nw) {
    nw.displayClass = 'hide'; // For the removal animation
    setTimeout(() => {
      localNotifications.value.delete(id);
      localNotificationsMap.value.delete(nw.notification);
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
@keyframes show {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  40% {
    transform: translateX(-5%);
    opacity: 1;
  }
  50% {
    transform: translateX(0%);
  }
}

@keyframes hide {
  0% {
    transform: translateX(-5%)
  }
  80% {
    transform: translateX(200%)
  }
  100% {
    height: 0;
    opacity: 0;
  }
}


.notifications {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 99999;
  max-height: 100%;
  min-width: 320px;
  flex-direction: column;
  pointer-events: none;
  overflow-y: scroll;
  border: 1px solid transparent;

  .hidden {
    opacity: 0;
    transform: translateY(-100%);
    height: 0;
  }

  &__notification {
    width: 300px;
    padding: 0;
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
    background-color: white;
    color: black;
    font-size: 12px;

    p {
      margin: 6px;
    }
    &.show {
      pointer-events: all;
      animation: show .4s;
      animation-fill-mode: forwards;
    }
    &.hide {
      animation: hide .4s;
      animation-fill-mode: forwards;
    }
  }
}
</style>
