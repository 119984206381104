import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Auth } from '@/firebaseAuth';
import { useUserStore } from '@/stores/user/user';

import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import LoginPage from '@/components/login/LoginPage.vue';
import HomePage from '@/components/home/HomePage.vue';

// User
import LogsViewer from '@/components/user/logs/LogsViewer.vue';
import LogViewer from '@/components/user/logs/LogViewer.vue';
import NotificationsPage from '@/components/user/notifications/NotificationsPage.vue';
import SettingsPage from '@/components/user/settings/SettingsPage.vue';
import AccountManagement from '@/components/user/settings/system/AccountManagement.vue';
import ExchangeManagement from '@/components/user/settings/exchanges/ExchangeManagement.vue';
import MiscManagement from '@/components/user/settings/system/MiscManagement.vue';

// Entities
import OverviewPage from '@/components/exchanges/overview/OverviewPage.vue';
import OrdersPage from '@/components/exchanges/orders/OrdersPage.vue';
import PositionsPage from '@/components/exchanges/positions/PositionsPage.vue';
import StrategiesPage from '@/components/exchanges/strategies/StrategiesPage.vue';
import PortfolioPage from '@/components/exchanges/portfolio/PortfolioPage.vue';
import InstrumentsPage from '@/components/exchanges/instruments/InstrumentsPage.vue';
import ArbitrageAnalysis from '@/components/analysis/arbitrage/ArbitrageAnalysis.vue';
import OrderflowAnalysis from '@/components/analysis/orderflow/OrderflowAnalysis.vue';
import PairCoverageAnalysis from '@/components/analysis/paircoverage/PairCoverage.vue';
import PairLiquidityAnalysis from '@/components/analysis/pairliquidity/PairLiquidity.vue';
import TradingViewAnalysis from '@/components/analysis/tradingview/TradingViewAnalysis.vue';
import FundingAnalysis from '@/components/analysis/fundinganalysis/FundingAnalysis.vue';
import IgTradesAnalysis from '@/components/analysis/igtradesanalysis/IGTradesAnalysis.vue';
import DslView from '@/components/analysis/priceaction/DslView.vue';
import TestView from '@/components/analysis/priceaction/TestView.vue';
import MarketsAnalysis from '@/components/analysis/MarketsAnalysis.vue';

// TODO is there a better way to do this?
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'dashboardLayout',
    component: DashboardLayout,
    meta: { requiresAuth: !import.meta.env.VITE_APP_OFFLINE },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomePage,
        meta: { title: 'Home' },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: NotificationsPage,
        meta: { title: 'Notifications' },
      },
      {
        path: 'logs',
        name: 'logs',
        component: LogsViewer,
        meta: { title: 'Logs' },
      },
      {
        path: 'logs/:logFileName',
        name: 'log',
        component: LogViewer,
        meta: { title: 'Log' },
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingsPage,
        meta: { title: 'Settings' },
        children: [
          {
            path: 'account-management',
            name: 'account-management',
            component: AccountManagement,
            meta: { title: 'Account Management' },
          },
          {
            path: 'exchange-management',
            name: 'exchange-management',
            component: ExchangeManagement,
            meta: { title: 'Entity Management' },
          },
          {
            path: 'misc-management',
            name: 'misc-management',
            component: MiscManagement,
            meta: { title: 'Misc Management' },
          },
        ],
      },
      {
        path: 'exchanges',
        name: 'exchanges',
        component: OverviewPage,
        meta: { title: 'Entities Overview' },
        children: [
          {
            path: 'instruments',
            name: 'Instruments',
            component: InstrumentsPage,
            meta: { title: 'Instruments' },
          },
          {
            path: 'orders',
            name: 'Orders',
            component: OrdersPage,
            meta: { title: 'Orders' },
          },
          {
            path: 'positions',
            name: 'Positions',
            component: PositionsPage,
            meta: { title: 'Positions' },
          },
          {
            path: 'order_strategies',
            name: 'Order Strategies',
            component: StrategiesPage,
            meta: { title: 'Order Strategies' },
          },
          {
            path: 'portfolio',
            name: 'portfolio',
            component: PortfolioPage,
            meta: { title: 'Portfolio' },
          },
        ],
      },
      {
        path: 'analysis',
        name: 'MarketsAnalysis',
        component: MarketsAnalysis,
        meta: { title: 'Markets Analysis' },
        children: [
          {
            path: 'arbitrage',
            name: 'ArbitrageAnalysis',
            component: ArbitrageAnalysis,
            meta: { title: 'Arbitrage Analysis' },
          },
          {
            path: 'orderflow',
            name: 'OrderflowAnalysis',
            component: OrderflowAnalysis,
            meta: { title: 'Orderflow Analysis' },
          },
          {
            path: 'paircoverage',
            name: 'PairCoverage',
            component: PairCoverageAnalysis,
            meta: { title: 'Pair Coverage Analysis' },
          },
          {
            path: 'pairliquidity',
            name: 'PairLiquidity',
            component: PairLiquidityAnalysis,
            meta: { title: 'Pair Liquidity Analysis' },
          },
          {
            path: 'priceaction',
            name: 'PriceActionAnalysisDSL',
            component: DslView,
            meta: { title: 'Price Action Analysis: DSL View' },
          },
          {
            path: 'priceaction/test',
            name: 'PriceActionAnalysisTest',
            component: TestView,
            meta: { title: 'Price Action Analysis: Test View' },
          },
          {
            path: 'tradingview',
            name: 'TradingViewAnalysis',
            component: TradingViewAnalysis,
            meta: { title: 'TradingView Analysis' },
          },
          {
            path: 'funding',
            name: 'FundingAnalysis',
            component: FundingAnalysis,
            meta: { title: 'Funding Analysis' },
          },
          {
            path: 'ig_trades',
            name: 'IgTradesAnalysis',
            component: IgTradesAnalysis,
            meta: { title: 'IG Trades Analysis' },
          },
        ],
      },
      {
        path: 'exchanges/:entityName/:entityType/:symbol/:accountId?',
        name: 'symbol',
        component: () => import('@/components/exchanges/symbol/SymbolPage.vue'),
        meta: { title: 'Symbol' },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { title: 'Login' },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// Needed to reroute to login if user not logged in
router.beforeEach(async (to, _, next) => {
  await Auth.waitUntilAuthReady();
  const userStore = useUserStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isLoggedIn = userStore.isLoggedIn;

  if (requiresAuth && !isLoggedIn) {
    next({ name: 'login' });
  } else if (to.name === 'login' && isLoggedIn) {
    next({ name: 'home' });
  } else {
    next();
  }
});

const DEFAULT_TITLE = 'Trading Client';
router.afterEach((to) => {
  document.title = to.meta.title ? String(to.meta.title)  : DEFAULT_TITLE;
});


// // Keep query string
// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length
// }
// router.beforeEach((to, from, next) => {
//    if(!hasQueryParams(to) && hasQueryParams(from)){
//     next({name: to.name, query: from.query});
//   } else {
//     next()
//   }
// });

export default router;
