<template>
  <div class="grid">
    <div class="col col-remaining">
      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            Query Logs
          </h3>
        </div>

        <div class="panel__body vtable">
          <div class="vtable__row vtable__row--selection">
            <div class="vtable__row__item">
              <div>
                <label for="search">Search:&nbsp;</label>
                <input v-model="searchTerm" type="text" class="order-form-input" placeholder="Search term..." />
              </div>
              <div>
                <label for="search">Result limit:&nbsp;</label>
                <input v-model="resultLimit" type="text" class="order-form-input" />
              </div>
              <br />
              <button class="btn-tw" @click="search(true)">Search (count)</button>
              <button class="btn-tw" @click="search(false)">Search (full<span v-if="offset">, load more</span>)</button>
              <button class="btn-tw" @click="clear()">Clear results</button>
              <button class="btn-tw" @click="deleteResults()">Delete all results</button>
              <div v-if="requestProcessing" class="mx-auto text-center my-2">
                <img class="spin-animation" :src="spinningCircle" />
                Processing...
              </div>
            </div>
          </div>
        </div>
        <div v-if="logSearchResults" class="panel__body vtable">
          <pre>Result count: {{ logSearchResults.count || offset }}</pre>
          <div class="vtable__row vtable__row--selection hand">
            <div v-for="(result, i) in logSearchResults.results" :key="i" class="vtable__row__item">
              {{ result.message }}
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            Client Log File
          </h3>
        </div>

        <div class="panel__body vtable">
          <div class="vtable__row vtable__row--selection hand" @click="redirectLogFile('client')">
            <div class="vtable__row__item">
              <pre>Client logs</pre>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            Server Log Files ({{ sortedLogsFileNames.length }})
          </h3>
        </div>

        <div class="panel__body vtable">
          <div
            v-for="(fileName, index) in sortedLogsFileNames" :key="index"
            class="vtable__row vtable__row--selection hand" @click="redirectLogFile(fileName)"
          >
            <div class="vtable__row__item">
              <pre>{{ fileName }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { convertLogSearchResultsServer, useServerLogsStore } from '@/stores/user/serverLogs';
import { useWebSocketStore } from '@/stores/user/ws';
import { LogSearch, LogSearchResults, LogSearchResultsServer, LogsWrapper } from '@/types/serverLogs';
import spinningCircle from '@/assets/spinning-circle.svg';

// Stores
const logsStore = useServerLogsStore();
const wsStore = useWebSocketStore();
const router = useRouter();
const route = useRoute();

const logsFileNames = computed(() => logsStore.fileNames);
const sortedLogsFileNames = computed(() => {
  return [...logsFileNames.value].sort((a, b) => (a > b ? -1 : 1));
});

const searchTerm = ref('');
const resultLimit = ref(10);
const logSearchResults = ref<LogSearchResults>(null);
const offset = ref(0);
const requestProcessing = ref(false);

watch(searchTerm, () => {
  offset.value = 0;
  logSearchResults.value = null;
});

onMounted(() => {
  const logsWrapper = new LogsWrapper('get_logs_file_names');
  wsStore.sendWithRequestStatus(logsWrapper);
});

const redirectLogFile = (id: string) => {
  void router.push({ path: `/logs/${id}`, query: route.query });
};

const search = (countOnly: boolean) => {
  requestProcessing.value = true;

  logsStore.search(new LogSearch(searchTerm.value, countOnly, offset.value, resultLimit.value)).then(
    (jsonResult) => {
      const results = convertLogSearchResultsServer(JSON.parse(jsonResult) as LogSearchResultsServer);

      if (logSearchResults.value !== null) {
        logSearchResults.value.results.push(...results.results);
        logSearchResults.value.count = logSearchResults.value.results.length;
      } else {
        logSearchResults.value = results;
      }

      offset.value = logSearchResults.value.results.length;
      requestProcessing.value = false;
    },
  ).catch(() => {
    requestProcessing.value = false;
  });
};

const clear = () => {
  logSearchResults.value = null;
  offset.value = 0;
};

const deleteResults = () => {
  requestProcessing.value = true;

  logsStore.delete(new LogSearch(searchTerm.value, false, 0)).then(
    () => {
      // Could display the deletion count from the HTTP result, if desired
      clear();
    },
  ).catch(() => {
    requestProcessing.value = false;
  });
};
</script>

<style lang="scss" scoped>

</style>
