<template>
  <div class="wrapper-tw">
    <div class="w-full" :class="accountType">
      <TopNavbar />

      <div>
        <router-view id="main" />
      </div>

      <div v-if="showSidebarReopen" class="sidebar-reopen-tw">
        <i class="ti-arrow-circle-right table-icon-tw" @click="toggleSidebarOpen" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useSidebarStore } from '@/stores/sidebar';
import { useUserStore } from '@/stores/user/user';
import { useRoute } from 'vue-router';
import TopNavbar from '@/layout/dashboard/TopNavbar.vue';
import { toggleSidebarOpen } from '@/stores/shared';

// Store
const userSettingsStore = useUserSettingsStore();
const sidebarStore = useSidebarStore();
const userStore = useUserStore();
const route = useRoute();

// Computed
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
const theme = computed(() => userSettingsStore.getTheme);
const accountType = computed(() => userStore.getAccountType);
const pathIncludesLogs = computed(() => route.fullPath.includes('logs'));
const showSidebarReopen = computed(() => pathIncludesLogs.value && !sidebarOpen.value);

watch(theme, (newVal) => {
  const html = document.querySelector('html');
  if (html) html.dataset.theme = newVal;
}, { immediate: true });
</script>

<style lang="scss">
</style>
