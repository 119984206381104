<template>
  <div class="grid grid--padded">
    <div v-if="Object.keys(userSettingsStore.hardcodedSymbolPrices).length > 0" class="col col-6 panel">
      <header class="panel__header">
        <h3 class="panel__header__title">Hard-coded Assets</h3>
      </header>
      <div class="panel__body panel__body--padded">
        <table class="table table--bordered table--large-font">
          <tr v-for="(price, symbol) in userSettingsStore.hardcodedSymbolPrices" :key="symbol">
            <td>{{ symbol }}</td>
            <td>{{ price.lastPrice }}</td>
            <td class="table__row__controls">
              <button @click="removeSymbol(symbol)">
                <i class="ti-trash" />
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="col col-6 panel">
      <header class="panel__header">
        <h3 class="panel__header__title">Update hard-coded symbol prices</h3>
        <p v-if="Object.keys(priceLookupCacheNotFound).length > 0">
          Current unconverted assets: {{ Object.keys(priceLookupCacheNotFound).join(', ') }}
        </p>
      </header>
      <div class="panel__body panel__body--padded form">
        <div class="form__group">
          <label for="symbolName">Add symbol name:</label>
          <input
            id="symbolName" v-model="symbolName" type="text" autocomplete="off"
            placeholder="Name (e.g. GOOG-USD)"
          />
        </div>
        <div class="form__group">
          <label for="symbolPrice">Add symbol price:</label>
          <input
            id="symbolPrice" v-model="symbolPrice" type="text" autocomplete="off"
            placeholder="Price (e.g. 200)"
          />
        </div>
        <div class="form__buttons">
          <button
            class="form__buttons__button"
            @click="setSymbolPrice()"
          >
            Set symbol price
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useUserSettingsStore, priceLookupCacheNotFound, priceLookupCache } from '@/stores/user/settings';
import { ENTITY_TYPE } from '@/types/exchange';
import { PriceType } from '@/types/prices';

const userSettingsStore = useUserSettingsStore();

const symbolName = ref('');
const symbolPrice = ref('');

const setSymbolPrice = () => {
  userSettingsStore.hardcodedSymbolPrices[symbolName.value] = new PriceType(
    symbolName.value, symbolPrice.value, symbolPrice.value, symbolPrice.value, '', '', '', ENTITY_TYPE.SPOT,
  );
  const baseAsset = symbolName.value.split('-')[0];

  delete priceLookupCacheNotFound.value[baseAsset];

  symbolName.value = '';
  symbolPrice.value = '';
};

const removeSymbol = (symbol: string) => {
  delete userSettingsStore.hardcodedSymbolPrices[symbol];

  const baseAsset = symbol.split('-')[0];

  delete priceLookupCache[baseAsset];
};

</script>
