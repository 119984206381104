export class MonitoringTypeItem {
  entityName: string;
  wsType: string;
  lastUpdateTime: string;
  secondsAgo: number;

  constructor(entityName: string, wsType: string, lastUpdateTime: string, secondsAgo: number) {
    this.entityName = entityName;
    this.wsType = wsType;
    this.lastUpdateTime = lastUpdateTime;
    this.secondsAgo = secondsAgo;
  }
}

export class MonitoringType {
  error: boolean;
  items: MonitoringTypeItem[];

  constructor(error: boolean, items: MonitoringTypeItem[]) {
    this.error = error;
    this.items = items;
  }
}
