import { formatDateTime } from '@/utilities';
import { LOG_SEVERITY } from '@/types/serverLogs';
import * as stackTraceParser from 'stacktrace-parser';


Error.stackTraceLimit = 20; // Longer stack traces are required

export class ClientLogsState {
  public logs: ClientLogType[];
  public logThrottler: Record<string, number>;
}

export class ClientLogType {
  public severity: LOG_SEVERITY;
  public timestamp: number;
  public message: string;
  public file: string;
  public tempLine: number; // This is the line number of the minified source code
  public line: number = 0; // This is the line number of the raw source code to debug things
  public offset: number;
  public key: string; // file + temp line + offset
  // Could also add other fields, like: entityName, entityType, accountId, category, etc

  constructor(message: string, severity: LOG_SEVERITY, stackOffset = 0) {
    this.message = message;
    this.severity = severity;
    this.timestamp = new Date().getTime();

    const rawStack = new Error().stack;

    const stack = stackTraceParser.parse(rawStack);
    let stackIndex = 0;

    while (stackIndex < stack.length) {
      const fileName = stack[stackIndex].file;

      // Skip over the logging locations and anything in node_modules (like pinia) in the call stack
      if (fileName.indexOf('clientLogs.ts') === -1 && fileName.indexOf('/node_modules/') === -1) {
        if (stackOffset <= 0) {
          break;
        }

        --stackOffset;
      }

      ++stackIndex;
    }

    const stackframe = stack[stackIndex];
    let fileName = stackframe.file;

    if (fileName.indexOf('?t=') !== -1) {
      fileName = fileName.split('?t=')[0];
    }

    this.file = fileName;
    this.tempLine = stackframe.lineNumber;
    this.offset = stackframe.column;
    this.key = `${this.file}:${this.tempLine}:${this.offset}`;
  }

  public toString(): string {
    const fileParts = this.file.split('/');

    return `${formatDateTime(this.timestamp, true)} ` +
      `[${this.severity.padEnd(7, ' ')}]` +
      `[${fileParts[fileParts.length - 2]}/${fileParts[fileParts.length - 1]}:${this.line}]` +
      (this.message[0] === '[' ? '' : ' ') +
      this.message;
  }
}

export class InfoLog extends ClientLogType {
  constructor(message: string) {
    super(message, LOG_SEVERITY.INFO);
  }
}

export class NoticeLog extends ClientLogType {
  constructor(message: string) {
    super(message, LOG_SEVERITY.NOTICE);
  }
}

export class WarningLog extends ClientLogType {
  constructor(message: string) {
    super(message, LOG_SEVERITY.WARNING);
  }
}

export class ErrorLog extends ClientLogType {
  constructor(message: string) {
    super(message, LOG_SEVERITY.ERROR);
  }
}

export class FilesCacheType {
  public minifiedSource: Record<string, string> = {}; // filename => minified source code
  public rawSource: Record<string, string> = {}; // filename => actual source code
  public pendingLogsByFile: Record<string, number[]> = {}; // filename => indexes into pendingLogs
  public pendingLogs: ClientLogType[] = []; // filename => pending logs (waiting on any file fetching)
  public mapping: Record<string, number> = {}; // filename:number => actual line number
  public currentlyFetching: Record<string, boolean> = {}; // Log files currently being fetched
}
