import { OrderType } from '@/types/orders';

export enum PRICE_TYPE {
  Unknown = 0, // E.g. chart click event (the chart does not know what is being set)
  Entry = 1,
  StopLoss = 2,
  ProfitTarget = 3,
}

export class SelectPrice {
  public price: number;
  public index: string;
  public priceType: PRICE_TYPE;

  constructor(price: number, index: string, priceType: PRICE_TYPE) {
    this.price = price;
    this.index = index;
    this.priceType = priceType;
  }
}

export type Event = {
  action: string;
  value?: number;
  selectPrice: SelectPrice;
  selectField: PRICE_TYPE;
  updateLinePrice: SelectPrice;
  renderPreOrders: OrderType[];
  preOrders: OrderType[];
  lastPrice: string;
  resetForm: null;
  setOrdersForm: OrderType;
  removeOrder: OrderType;
};

export enum VALID_INPUT {
  VALID = 0,
  INVALID = 1,
  NEUTRAL = 2
}

export interface SidebarState {
  sidebarOpen: boolean
}

export enum REQUEST_STATUS {
  PENDING = 'Pending',
  SUCCESSFUL = 'Successful',
  UNSUCCESSFUL = 'Unsuccessful'
}

export interface RequestState {
  requestStatus: Record<string, REQUEST_STATUS>
}

export interface RequestStatusServerResp {
  statusId: string
  statusCode: number
  message: string
}

export interface RespError {
  message: string
  error: string
}

export interface RequestDataI {
  method: string
  headers: Record<string, string>
  body?: string
}

export interface ErrorServerResp {
  error?: string;
}
